define('ui/mixins/filter-k8s-namespace', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Mixin.create({
    k8s: _ember['default'].inject.service(),
    filterableContent: _ember['default'].computed.alias('model'),

    init: function init() {
      this._super();
    },

    filtered: (function () {
      return (this.get('filterableContent') || []).filterBy('metadata.namespace', this.get('k8s.namespace.id'));
    }).property('filterableContent.@each.metadata', 'k8s.namespace.id')
  });
});