define('ui/utils/parse-version', ['exports'], function (exports) {
  var _slicedToArray = (function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i['return']) _i['return'](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError('Invalid attempt to destructure non-iterable instance'); } }; })();

  exports.parse = parse;
  exports.compare = compare;
  exports.minorVersion = minorVersion;

  function parse(str) {
    str = str + '';

    // Trim off leading 'v'
    if (str.substr(0, 1).toLowerCase() === 'v') {
      str = str.substr(1);
    }

    var parts = str.split(/[.-]/);
    return parts;
  }

  function isNumeric(str) {
    return typeof str === 'string' && str.match(/^[0-9]*$/);
  }

  function comparePart(in1, in2) {
    in1 = (in1 + "").toLowerCase();
    in2 = (in2 + "").toLowerCase();

    if (isNumeric(in1) && isNumeric(in2)) {
      var num1 = parseInt(in1, 10);
      var num2 = parseInt(in2, 10);

      if (!isNaN(num1) && !isNaN(num2)) {
        return num1 - num2;
      }
    }

    return in1.localeCompare(in2);
  }

  function compare(in1, in2) {
    if (!in1) {
      return 1;
    }

    if (!in2) {
      return -1;
    }

    var p1 = parse(in1);
    var p2 = parse(in2);

    var minLen = Math.min(p1.length, p2.length);
    for (var i = 0; i < minLen; i++) {
      var res = comparePart(p1[i], p2[i]);
      if (res !== 0) {
        return res;
      }
    }

    return p1.length - p2.length;
  }

  function minorVersion(str) {
    var _parse = /*, patch, pre*/parse(str);

    var _parse2 = _slicedToArray(_parse, 2);

    var major = _parse2[0];
    var minor = _parse2[1];

    if (!minor) {
      return 'v' + major + '.0';
    }

    return 'v' + major + '.' + minor;
  }
});