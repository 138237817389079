define('ui/components/input-command/component', ['exports', 'ember', 'npm:shell-quote'], function (exports, _ember, _npmShellQuote) {
  exports['default'] = _ember['default'].TextField.extend({
    type: 'text',

    init: function init() {
      this._super.apply(this, arguments);

      var initial = this.get('initialValue') || '';
      if (_ember['default'].isArray(initial)) {
        this.set('value', _npmShellQuote['default'].quote(initial));
      } else {
        this.set('value', initial);
      }
    },

    valueChanged: (function () {
      var out = _npmShellQuote['default'].parse(this.get('value') || '').map(function (piece) {
        if (typeof piece === 'object' && piece) {
          if (piece.pattern) {
            return piece.pattern;
          } else if (piece.op) {
            return piece.op;
          } else {
            return '';
          }
        }

        return piece;
      });

      if (out.length) {
        this.sendAction('changed', out);
      } else {
        this.sendAction('changed', null);
      }
    }).observes('value')
  });
});