define("ui/components/host-pod/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "revision": "Ember@2.9.1",
          "loc": {
            "source": null,
            "start": {
              "line": 8,
              "column": 4
            },
            "end": {
              "line": 12,
              "column": 4
            }
          },
          "moduleName": "ui/components/host-pod/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element9 = dom.childAt(fragment, [1]);
          var morphs = new Array(2);
          morphs[0] = dom.createAttrMorph(element9, 'class');
          morphs[1] = dom.createMorphAt(element9, 1, 1);
          return morphs;
        },
        statements: [["attribute", "class", ["concat", ["pod-message ", ["subexpr", "if", [["get", "model.isError", ["loc", [null, [9, 35], [9, 48]]], 0, 0, 0, 0], "text-danger", "text-muted"], [], ["loc", [null, [9, 30], [9, 77]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["content", "model.transitioningMessage", ["loc", [null, [10, 8], [10, 38]]], 0, 0, 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child1 = (function () {
      return {
        meta: {
          "revision": "Ember@2.9.1",
          "loc": {
            "source": null,
            "start": {
              "line": 15,
              "column": 0
            },
            "end": {
              "line": 19,
              "column": 0
            }
          },
          "moduleName": "ui/components/host-pod/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "machine-info text-center force-wrap");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element8 = dom.childAt(fragment, [1, 1]);
          var morphs = new Array(2);
          morphs[0] = dom.createAttrMorph(element8, 'class');
          morphs[1] = dom.createMorphAt(element8, 0, 0);
          return morphs;
        },
        statements: [["attribute", "class", ["concat", [["subexpr", "if", [["get", "isError", ["loc", [null, [17, 22], [17, 29]]], 0, 0, 0, 0], "text-danger", "text-muted"], [], ["loc", [null, [17, 17], [17, 58]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["content", "model.transitioningMessage", ["loc", [null, [17, 60], [17, 90]]], 0, 0, 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child2 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@2.9.1",
            "loc": {
              "source": null,
              "start": {
                "line": 22,
                "column": 6
              },
              "end": {
                "line": 24,
                "column": 6
              }
            },
            "moduleName": "ui/components/host-pod/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "pod-info-item");
            var el2 = dom.createElement("i");
            dom.setAttribute(el2, "class", "icon icon-link");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode(" ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 2, 2);
            return morphs;
          },
          statements: [["inline", "format-ip", [["get", "model.displayIp", ["loc", [null, [23, 78], [23, 93]]], 0, 0, 0, 0]], [], ["loc", [null, [23, 66], [23, 95]]], 0, 0]],
          locals: [],
          templates: []
        };
      })();
      var child1 = (function () {
        return {
          meta: {
            "revision": "Ember@2.9.1",
            "loc": {
              "source": null,
              "start": {
                "line": 26,
                "column": 6
              },
              "end": {
                "line": 28,
                "column": 6
              }
            },
            "moduleName": "ui/components/host-pod/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "pod-info-item");
            var el2 = dom.createElement("i");
            dom.setAttribute(el2, "class", "icon icon-docker");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode(" ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 2, 2);
            return morphs;
          },
          statements: [["content", "model.dockerBlurb", ["loc", [null, [27, 68], [27, 89]]], 0, 0, 0, 0]],
          locals: [],
          templates: []
        };
      })();
      var child2 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "revision": "Ember@2.9.1",
              "loc": {
                "source": null,
                "start": {
                  "line": 32,
                  "column": 89
                },
                "end": {
                  "line": 32,
                  "column": 139
                }
              },
              "moduleName": "ui/components/host-pod/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode(" (");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode(") ");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              return morphs;
            },
            statements: [["content", "model.kernelBlurb", ["loc", [null, [32, 116], [32, 137]]], 0, 0, 0, 0]],
            locals: [],
            templates: []
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.9.1",
            "loc": {
              "source": null,
              "start": {
                "line": 31,
                "column": 6
              },
              "end": {
                "line": 33,
                "column": 6
              }
            },
            "moduleName": "ui/components/host-pod/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "pod-info-item clip");
            var el2 = dom.createElement("i");
            dom.setAttribute(el2, "class", "icon icon-linux");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode(" ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element3 = dom.childAt(fragment, [1]);
            var morphs = new Array(2);
            morphs[0] = dom.createMorphAt(element3, 2, 2);
            morphs[1] = dom.createMorphAt(element3, 3, 3);
            return morphs;
          },
          statements: [["content", "model.osBlurb", ["loc", [null, [32, 72], [32, 89]]], 0, 0, 0, 0], ["block", "if", [["get", "model.kernelBlurb", ["loc", [null, [32, 95], [32, 112]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [32, 89], [32, 146]]]]],
          locals: [],
          templates: [child0]
        };
      })();
      var child3 = (function () {
        return {
          meta: {
            "revision": "Ember@2.9.1",
            "loc": {
              "source": null,
              "start": {
                "line": 36,
                "column": 6
              },
              "end": {
                "line": 38,
                "column": 6
              }
            },
            "moduleName": "ui/components/host-pod/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "pod-info-item");
            var el2 = dom.createElement("i");
            dom.setAttribute(el2, "class", "icon icon-cpu");
            dom.setAttribute(el2, "style", "position: relative; top: -1px;");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode(" ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 2, 2);
            return morphs;
          },
          statements: [["content", "model.cpuBlurb", ["loc", [null, [37, 104], [37, 122]]], 0, 0, 0, 0]],
          locals: [],
          templates: []
        };
      })();
      var child4 = (function () {
        return {
          meta: {
            "revision": "Ember@2.9.1",
            "loc": {
              "source": null,
              "start": {
                "line": 39,
                "column": 6
              },
              "end": {
                "line": 41,
                "column": 6
              }
            },
            "moduleName": "ui/components/host-pod/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "pod-info-item");
            var el2 = dom.createElement("i");
            dom.setAttribute(el2, "class", "icon icon-memory");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode(" ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 2, 2);
            return morphs;
          },
          statements: [["content", "model.memoryBlurb", ["loc", [null, [40, 68], [40, 89]]], 0, 0, 0, 0]],
          locals: [],
          templates: []
        };
      })();
      var child5 = (function () {
        return {
          meta: {
            "revision": "Ember@2.9.1",
            "loc": {
              "source": null,
              "start": {
                "line": 42,
                "column": 6
              },
              "end": {
                "line": 44,
                "column": 6
              }
            },
            "moduleName": "ui/components/host-pod/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "pod-info-item");
            var el2 = dom.createElement("i");
            dom.setAttribute(el2, "class", "icon icon-hdd");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode(" ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 2, 2);
            return morphs;
          },
          statements: [["content", "model.diskBlurb", ["loc", [null, [43, 65], [43, 84]]], 0, 0, 0, 0]],
          locals: [],
          templates: []
        };
      })();
      var child6 = (function () {
        return {
          meta: {
            "revision": "Ember@2.9.1",
            "loc": {
              "source": null,
              "start": {
                "line": 47,
                "column": 6
              },
              "end": {
                "line": 49,
                "column": 6
              }
            },
            "moduleName": "ui/components/host-pod/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "pod-info-item");
            var el2 = dom.createElement("i");
            dom.setAttribute(el2, "class", "icon icon-cloud");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode(" ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 2, 2);
            return morphs;
          },
          statements: [["content", "model.driver", ["loc", [null, [48, 67], [48, 83]]], 0, 0, 0, 0]],
          locals: [],
          templates: []
        };
      })();
      var child7 = (function () {
        var child0 = (function () {
          var child0 = (function () {
            return {
              meta: {
                "revision": "Ember@2.9.1",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 56,
                    "column": 110
                  },
                  "end": {
                    "line": 56,
                    "column": 145
                  }
                },
                "moduleName": "ui/components/host-pod/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("=");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [["content", "label.value", ["loc", [null, [56, 130], [56, 145]]], 0, 0, 0, 0]],
              locals: [],
              templates: []
            };
          })();
          return {
            meta: {
              "revision": "Ember@2.9.1",
              "loc": {
                "source": null,
                "start": {
                  "line": 55,
                  "column": 8
                },
                "end": {
                  "line": 57,
                  "column": 8
                }
              },
              "moduleName": "ui/components/host-pod/template.hbs"
            },
            isEmpty: false,
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("          ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("span");
              dom.setAttribute(el1, "class", "label label-default clip");
              dom.setAttribute(el1, "style", "display: inline-block; max-width: 100%;");
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element2 = dom.childAt(fragment, [1]);
              var morphs = new Array(2);
              morphs[0] = dom.createMorphAt(element2, 0, 0);
              morphs[1] = dom.createMorphAt(element2, 1, 1);
              return morphs;
            },
            statements: [["content", "label.key", ["loc", [null, [56, 97], [56, 110]]], 0, 0, 0, 0], ["block", "if", [["get", "label.value", ["loc", [null, [56, 116], [56, 127]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [56, 110], [56, 152]]]]],
            locals: ["label"],
            templates: [child0]
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.9.1",
            "loc": {
              "source": null,
              "start": {
                "line": 53,
                "column": 4
              },
              "end": {
                "line": 59,
                "column": 4
              }
            },
            "moduleName": "ui/components/host-pod/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "");
            dom.setAttribute(el1, "style", "white-space: normal; padding-top: 5px;");
            var el2 = dom.createTextNode("\n");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("      ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
            return morphs;
          },
          statements: [["block", "each", [["get", "strictUserLabelArray", ["loc", [null, [55, 16], [55, 36]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [55, 8], [57, 17]]]]],
          locals: [],
          templates: [child0]
        };
      })();
      var child8 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "revision": "Ember@2.9.1",
              "loc": {
                "source": null,
                "start": {
                  "line": 64,
                  "column": 6
                },
                "end": {
                  "line": 66,
                  "column": 6
                }
              },
              "moduleName": "ui/components/host-pod/template.hbs"
            },
            isEmpty: false,
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              return morphs;
            },
            statements: [["inline", "container-dot", [], ["model", ["subexpr", "@mut", [["get", "item", ["loc", [null, [65, 30], [65, 34]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [65, 8], [65, 36]]], 0, 0]],
            locals: ["item"],
            templates: []
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.9.1",
            "loc": {
              "source": null,
              "start": {
                "line": 63,
                "column": 4
              },
              "end": {
                "line": 67,
                "column": 4
              }
            },
            "moduleName": "ui/components/host-pod/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "each", [["get", "arrangedInstances", ["loc", [null, [64, 14], [64, 31]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [64, 6], [66, 15]]]]],
          locals: [],
          templates: [child0]
        };
      })();
      var child9 = (function () {
        var child0 = (function () {
          var child0 = (function () {
            var child0 = (function () {
              var child0 = (function () {
                return {
                  meta: {
                    "revision": "Ember@2.9.1",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 74,
                        "column": 18
                      },
                      "end": {
                        "line": 77,
                        "column": 18
                      }
                    },
                    "moduleName": "ui/components/host-pod/template.hbs"
                  },
                  isEmpty: false,
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                    ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n                    ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(2);
                    morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                    morphs[1] = dom.createMorphAt(fragment, 3, 3, contextualElement);
                    return morphs;
                  },
                  statements: [["inline", "t", ["hostPod.machineInfo.groupedInstances.namespace"], [], ["loc", [null, [75, 20], [75, 74]]], 0, 0], ["content", "group.name", ["loc", [null, [76, 20], [76, 34]]], 0, 0, 0, 0]],
                  locals: [],
                  templates: []
                };
              })();
              var child1 = (function () {
                var child0 = (function () {
                  return {
                    meta: {
                      "revision": "Ember@2.9.1",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 79,
                          "column": 20
                        },
                        "end": {
                          "line": 79,
                          "column": 63
                        }
                      },
                      "moduleName": "ui/components/host-pod/template.hbs"
                    },
                    isEmpty: false,
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createComment("");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var morphs = new Array(1);
                      morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                      dom.insertBoundary(fragment, 0);
                      dom.insertBoundary(fragment, null);
                      return morphs;
                    },
                    statements: [["content", "group.name", ["loc", [null, [79, 49], [79, 63]]], 0, 0, 0, 0]],
                    locals: [],
                    templates: []
                  };
                })();
                return {
                  meta: {
                    "revision": "Ember@2.9.1",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 77,
                        "column": 18
                      },
                      "end": {
                        "line": 80,
                        "column": 18
                      }
                    },
                    "moduleName": "ui/components/host-pod/template.hbs"
                  },
                  isEmpty: false,
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                    ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n                    ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(2);
                    morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                    morphs[1] = dom.createMorphAt(fragment, 3, 3, contextualElement);
                    return morphs;
                  },
                  statements: [["inline", "t", ["hostPod.machineInfo.groupedInstances.stack"], [], ["loc", [null, [78, 20], [78, 70]]], 0, 0], ["block", "link-to", ["stack", ["get", "group.id", ["loc", [null, [79, 39], [79, 47]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [79, 20], [79, 75]]]]],
                  locals: [],
                  templates: [child0]
                };
              })();
              return {
                meta: {
                  "revision": "Ember@2.9.1",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 73,
                      "column": 16
                    },
                    "end": {
                      "line": 81,
                      "column": 16
                    }
                  },
                  "moduleName": "ui/components/host-pod/template.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [["block", "if", [["get", "group.kubernetes", ["loc", [null, [74, 24], [74, 40]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [74, 18], [80, 25]]]]],
                locals: [],
                templates: [child0, child1]
              };
            })();
            var child1 = (function () {
              return {
                meta: {
                  "revision": "Ember@2.9.1",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 81,
                      "column": 16
                    },
                    "end": {
                      "line": 83,
                      "column": 16
                    }
                  },
                  "moduleName": "ui/components/host-pod/template.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                  ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                  return morphs;
                },
                statements: [["inline", "t", ["hostPod.machineInfo.groupedInstances.standalone"], [], ["loc", [null, [82, 18], [82, 73]]], 0, 0]],
                locals: [],
                templates: []
              };
            })();
            var child2 = (function () {
              return {
                meta: {
                  "revision": "Ember@2.9.1",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 87,
                      "column": 14
                    },
                    "end": {
                      "line": 89,
                      "column": 14
                    }
                  },
                  "moduleName": "ui/components/host-pod/template.hbs"
                },
                isEmpty: false,
                arity: 1,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                  return morphs;
                },
                statements: [["inline", "container-subpod", [], ["model", ["subexpr", "@mut", [["get", "item.main", ["loc", [null, [88, 41], [88, 50]]], 0, 0, 0, 0]], [], [], 0, 0], "stripProject", true, "children", ["subexpr", "@mut", [["get", "item.children", ["loc", [null, [88, 78], [88, 91]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [88, 16], [88, 93]]], 0, 0]],
                locals: ["item"],
                templates: []
              };
            })();
            var child3 = (function () {
              var child0 = (function () {
                return {
                  meta: {
                    "revision": "Ember@2.9.1",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 91,
                        "column": 16
                      },
                      "end": {
                        "line": 93,
                        "column": 16
                      }
                    },
                    "moduleName": "ui/components/host-pod/template.hbs"
                  },
                  isEmpty: false,
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                  ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                    return morphs;
                  },
                  statements: [["inline", "add-subpod", [], ["action", "newContainer", "label", "hostsPage.addContainer", "groupHasChildren", ["subexpr", "@mut", [["get", "group.hasChildren", ["loc", [null, [92, 101], [92, 118]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [92, 18], [92, 120]]], 0, 0]],
                  locals: [],
                  templates: []
                };
              })();
              return {
                meta: {
                  "revision": "Ember@2.9.1",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 90,
                      "column": 14
                    },
                    "end": {
                      "line": 94,
                      "column": 14
                    }
                  },
                  "moduleName": "ui/components/host-pod/template.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [["block", "if", [["get", "showAdd", ["loc", [null, [91, 22], [91, 29]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [91, 16], [93, 23]]]]],
                locals: [],
                templates: [child0]
              };
            })();
            return {
              meta: {
                "revision": "Ember@2.9.1",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 69,
                    "column": 8
                  },
                  "end": {
                    "line": 97,
                    "column": 8
                  }
                },
                "moduleName": "ui/components/host-pod/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("          ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1, "class", "container-subpod-info");
                var el2 = dom.createTextNode("\n            ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("div");
                dom.setAttribute(el2, "class", "subpod-header");
                var el3 = dom.createTextNode("\n              ");
                dom.appendChild(el2, el3);
                var el3 = dom.createElement("h6");
                dom.setAttribute(el3, "class", "clip");
                var el4 = dom.createComment("");
                dom.appendChild(el3, el4);
                var el4 = dom.createTextNode("              ");
                dom.appendChild(el3, el4);
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n            ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n            ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("div");
                dom.setAttribute(el2, "class", "subpod-inner clearfix");
                var el3 = dom.createTextNode("\n");
                dom.appendChild(el2, el3);
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("            ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n          ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element0 = dom.childAt(fragment, [1]);
                var element1 = dom.childAt(element0, [3]);
                var morphs = new Array(3);
                morphs[0] = dom.createMorphAt(dom.childAt(element0, [1, 1]), 0, 0);
                morphs[1] = dom.createMorphAt(element1, 1, 1);
                morphs[2] = dom.createMorphAt(element1, 2, 2);
                return morphs;
              },
              statements: [["block", "if", [["get", "group.name", ["loc", [null, [73, 23], [73, 33]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [73, 16], [83, 23]]]], ["block", "each", [["get", "group.instances", ["loc", [null, [87, 22], [87, 37]]], 0, 0, 0, 0]], [], 2, null, ["loc", [null, [87, 14], [89, 23]]]], ["block", "unless", [["get", "group.name", ["loc", [null, [90, 24], [90, 34]]], 0, 0, 0, 0]], [], 3, null, ["loc", [null, [90, 14], [94, 25]]]]],
              locals: [],
              templates: [child0, child1, child2, child3]
            };
          })();
          return {
            meta: {
              "revision": "Ember@2.9.1",
              "loc": {
                "source": null,
                "start": {
                  "line": 68,
                  "column": 6
                },
                "end": {
                  "line": 98,
                  "column": 6
                }
              },
              "moduleName": "ui/components/host-pod/template.hbs"
            },
            isEmpty: false,
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["block", "if", [["subexpr", "or", [["get", "showAdd", ["loc", [null, [69, 18], [69, 25]]], 0, 0, 0, 0], ["get", "group.instances.length", ["loc", [null, [69, 26], [69, 48]]], 0, 0, 0, 0]], [], ["loc", [null, [69, 14], [69, 49]]], 0, 0]], [], 0, null, ["loc", [null, [69, 8], [97, 15]]]]],
            locals: ["group"],
            templates: [child0]
          };
        })();
        var child1 = (function () {
          var child0 = (function () {
            return {
              meta: {
                "revision": "Ember@2.9.1",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 99,
                    "column": 8
                  },
                  "end": {
                    "line": 101,
                    "column": 8
                  }
                },
                "moduleName": "ui/components/host-pod/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("          ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                return morphs;
              },
              statements: [["inline", "add-subpod", [], ["action", "newContainer", "label", "hostsPage.addContainer", "groupHasChildren", ["subexpr", "@mut", [["get", "group.hasChildren", ["loc", [null, [100, 93], [100, 110]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [100, 10], [100, 112]]], 0, 0]],
              locals: [],
              templates: []
            };
          })();
          return {
            meta: {
              "revision": "Ember@2.9.1",
              "loc": {
                "source": null,
                "start": {
                  "line": 98,
                  "column": 6
                },
                "end": {
                  "line": 102,
                  "column": 6
                }
              },
              "moduleName": "ui/components/host-pod/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["block", "if", [["get", "showAdd", ["loc", [null, [99, 14], [99, 21]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [99, 8], [101, 15]]]]],
            locals: [],
            templates: [child0]
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.9.1",
            "loc": {
              "source": null,
              "start": {
                "line": 67,
                "column": 4
              },
              "end": {
                "line": 103,
                "column": 4
              }
            },
            "moduleName": "ui/components/host-pod/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "each", [["get", "groupedInstances", ["loc", [null, [68, 14], [68, 30]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [68, 6], [102, 15]]]]],
          locals: [],
          templates: [child0, child1]
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.9.1",
          "loc": {
            "source": null,
            "start": {
              "line": 19,
              "column": 0
            },
            "end": {
              "line": 105,
              "column": 0
            }
          },
          "moduleName": "ui/components/host-pod/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "pod-info");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "pod-info-container");
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "pod-info-container");
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "pod-info-container");
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "pod-info-container");
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "pod-info r-mb10");
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "style", "white-space: normal");
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element4 = dom.childAt(fragment, [1]);
          var element5 = dom.childAt(element4, [1]);
          var element6 = dom.childAt(element4, [5]);
          var element7 = dom.childAt(fragment, [5]);
          var morphs = new Array(10);
          morphs[0] = dom.createMorphAt(element5, 1, 1);
          morphs[1] = dom.createMorphAt(element5, 3, 3);
          morphs[2] = dom.createMorphAt(dom.childAt(element4, [3]), 1, 1);
          morphs[3] = dom.createMorphAt(element6, 1, 1);
          morphs[4] = dom.createMorphAt(element6, 2, 2);
          morphs[5] = dom.createMorphAt(element6, 3, 3);
          morphs[6] = dom.createMorphAt(dom.childAt(element4, [7]), 1, 1);
          morphs[7] = dom.createMorphAt(dom.childAt(fragment, [3]), 1, 1);
          morphs[8] = dom.createAttrMorph(element7, 'class');
          morphs[9] = dom.createMorphAt(element7, 1, 1);
          return morphs;
        },
        statements: [["block", "if", [["get", "model.displayIp", ["loc", [null, [22, 12], [22, 27]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [22, 6], [24, 13]]]], ["block", "if", [["get", "model.dockerBlurb", ["loc", [null, [26, 12], [26, 29]]], 0, 0, 0, 0]], [], 1, null, ["loc", [null, [26, 6], [28, 13]]]], ["block", "if", [["get", "model.osBlurb", ["loc", [null, [31, 12], [31, 25]]], 0, 0, 0, 0]], [], 2, null, ["loc", [null, [31, 6], [33, 13]]]], ["block", "if", [["get", "model.cpuBlurb", ["loc", [null, [36, 12], [36, 26]]], 0, 0, 0, 0]], [], 3, null, ["loc", [null, [36, 6], [38, 13]]]], ["block", "if", [["get", "model.memoryBlurb", ["loc", [null, [39, 12], [39, 29]]], 0, 0, 0, 0]], [], 4, null, ["loc", [null, [39, 6], [41, 13]]]], ["block", "if", [["get", "model.diskBlurb", ["loc", [null, [42, 12], [42, 27]]], 0, 0, 0, 0]], [], 5, null, ["loc", [null, [42, 6], [44, 13]]]], ["block", "if", [["get", "model.driver", ["loc", [null, [47, 12], [47, 24]]], 0, 0, 0, 0]], [], 6, null, ["loc", [null, [47, 6], [49, 13]]]], ["block", "if", [["get", "strictUserLabelArray.length", ["loc", [null, [53, 10], [53, 37]]], 0, 0, 0, 0]], [], 7, null, ["loc", [null, [53, 4], [59, 11]]]], ["attribute", "class", ["concat", ["clearfix ", ["subexpr", "if", [["subexpr", "eq", [["get", "mode", ["loc", [null, [62, 32], [62, 36]]], 0, 0, 0, 0], "dot"], [], ["loc", [null, [62, 28], [62, 43]]], 0, 0], "r-p5"], [], ["loc", [null, [62, 23], [62, 52]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["block", "if", [["subexpr", "eq", [["get", "mode", ["loc", [null, [63, 14], [63, 18]]], 0, 0, 0, 0], "dot"], [], ["loc", [null, [63, 10], [63, 25]]], 0, 0]], [], 8, 9, ["loc", [null, [63, 4], [103, 11]]]]],
        locals: [],
        templates: [child0, child1, child2, child3, child4, child5, child6, child7, child8, child9]
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.9.1",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 106,
            "column": 0
          }
        },
        "moduleName": "ui/components/host-pod/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "pod-header clearfix");
        var el2 = dom.createTextNode("\n\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        var el3 = dom.createElement("span");
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "pull-right");
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n  ");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "pod-name");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("a");
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("  ");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element10 = dom.childAt(fragment, [0]);
        var element11 = dom.childAt(element10, [1]);
        var element12 = dom.childAt(fragment, [2]);
        var element13 = dom.childAt(element12, [1]);
        var morphs = new Array(7);
        morphs[0] = dom.createAttrMorph(element11, 'class');
        morphs[1] = dom.createMorphAt(dom.childAt(element11, [0]), 0, 0);
        morphs[2] = dom.createMorphAt(dom.childAt(element10, [3]), 0, 0);
        morphs[3] = dom.createAttrMorph(element13, 'href');
        morphs[4] = dom.createMorphAt(element13, 0, 0);
        morphs[5] = dom.createMorphAt(element12, 3, 3);
        morphs[6] = dom.createMorphAt(fragment, 4, 4, contextualElement);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["attribute", "class", ["concat", ["pull-left pod-state ", ["get", "stateBackground", ["loc", [null, [3, 36], [3, 51]]], 0, 0, 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["content", "model.displayState", ["loc", [null, [3, 61], [3, 83]]], 0, 0, 0, 0], ["inline", "action-menu", [], ["model", ["subexpr", "@mut", [["get", "model", ["loc", [null, [4, 46], [4, 51]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [4, 26], [4, 53]]], 0, 0], ["attribute", "href", ["concat", [["subexpr", "href-to", ["host", ["get", "model.id", ["loc", [null, [7, 30], [7, 38]]], 0, 0, 0, 0]], [], ["loc", [null, [7, 13], [7, 40]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["content", "model.displayName", ["loc", [null, [7, 42], [7, 63]]], 0, 0, 0, 0], ["block", "if", [["subexpr", "and", [["subexpr", "not", [["get", "showOnlyMessage", ["loc", [null, [8, 20], [8, 35]]], 0, 0, 0, 0]], [], ["loc", [null, [8, 15], [8, 36]]], 0, 0], ["get", "model.showTransitioningMessage", ["loc", [null, [8, 37], [8, 67]]], 0, 0, 0, 0]], [], ["loc", [null, [8, 10], [8, 68]]], 0, 0]], [], 0, null, ["loc", [null, [8, 4], [12, 11]]]], ["block", "if", [["get", "showOnlyMessage", ["loc", [null, [15, 6], [15, 21]]], 0, 0, 0, 0]], [], 1, 2, ["loc", [null, [15, 0], [105, 7]]]]],
      locals: [],
      templates: [child0, child1, child2]
    };
  })());
});