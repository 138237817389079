define('ui/models/k8s-deployment', ['exports', 'ui/models/k8s-resource', 'ui/mixins/k8s-pod-selector'], function (exports, _uiModelsK8sResource, _uiMixinsK8sPodSelector) {

  var Deployment = _uiModelsK8sResource['default'].extend(_uiMixinsK8sPodSelector['default'], {
    displayGeneration: (function () {
      var want = this.get('metadata.generation');
      var have = this.get('status.observedGeneration');
      if (have >= want) {
        return have + '';
      } else {
        return have + ' to ' + want;
      }
    }).property('metadata.generation', 'status.observedGeneration'),

    displayReplicas: (function () {
      var want = this.get('spec.replicas') || 0;
      var have = this.get('status.replicas') || 0;
      var available = Math.max(0, have - (this.get('status.unavailableReplicas') || 0));

      if (want === have) {
        if (available === have) {
          return have + '';
        } else {
          return have + ' (' + available + ' up)';
        }
      } else {
        return have + ' of ' + want;
      }
    }).property('spec.replicas', 'status.{replicas,unavailableReplicas}')
  });

  exports['default'] = Deployment;
});