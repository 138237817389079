define('ui/k8s-tab/error/controller', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    projects: _ember['default'].inject.service(),
    k8s: _ember['default'].inject.service(),

    init: function init() {
      this.get('projects.orchestrationState.kubernetesReady');
    },

    readyChanged: (function () {
      if (this.get('projects.orchestrationState.kubernetesReady')) {
        this.set('k8s.loadingErrors', null);
        this.transitionToRoute('k8s-tab');
      }
    }).observes('projects.orchestrationState.kubernetesReady')
  });
});