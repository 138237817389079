define('ui/authenticated/route', ['exports', 'ember', 'ui/utils/constants', 'ui/mixins/subscribe', 'ui/utils/platform'], function (exports, _ember, _uiUtilsConstants, _uiMixinsSubscribe, _uiUtilsPlatform) {

  var CHECK_AUTH_TIMER = 60 * 10 * 1000;

  exports['default'] = _ember['default'].Route.extend(_uiMixinsSubscribe['default'], {
    prefs: _ember['default'].inject.service(),
    projects: _ember['default'].inject.service(),
    settings: _ember['default'].inject.service(),
    k8s: _ember['default'].inject.service(),
    access: _ember['default'].inject.service(),
    userTheme: _ember['default'].inject.service('user-theme'),
    language: _ember['default'].inject.service('user-language'),
    storeReset: _ember['default'].inject.service(),
    modalService: _ember['default'].inject.service('modal'),

    testTimer: null,

    beforeModel: function beforeModel(transition) {
      this._super.apply(this, arguments);

      if (this.get('access.enabled')) {
        if (this.get('access.isLoggedIn')) {
          this.testAuthToken();
        } else {
          transition.send('logout', transition, false);
          return _ember['default'].RSVP.reject('Not logged in');
        }
      }
    },

    testAuthToken: function testAuthToken() {
      var _this = this;

      var timer = _ember['default'].run.later(function () {
        _this.get('access').testAuth().then(function () /* res */{
          _this.testAuthToken();
        }, function () /* err */{
          _this.send('logout', null, true);
        });
      }, CHECK_AUTH_TIMER);

      this.set('testTimer', timer);
    },

    model: function model(params, transition) {
      var _this2 = this;

      // Save whether the user is admin
      var type = this.get('session.' + _uiUtilsConstants['default'].SESSION.USER_TYPE);
      var isAdmin = type === _uiUtilsConstants['default'].USER.TYPE_ADMIN || !this.get('access.enabled');
      this.set('access.admin', isAdmin);

      var promise = new _ember['default'].RSVP.Promise(function (resolve, reject) {
        var tasks = {
          userSchemas: _this2.toCb('loadUserSchemas'),
          projects: _this2.toCb('loadProjects'),
          preferences: _this2.toCb('loadPreferences'),
          settings: _this2.toCb('loadPublicSettings'),
          project: ['projects', 'preferences', _this2.toCb('selectProject', transition)],
          projectSchemas: ['project', _this2.toCb('loadProjectSchemas')],
          orchestrationState: ['projectSchemas', _this2.toCb('updateOrchestration')],
          instances: ['projectSchemas', _this2.cbFind('instance')],
          services: ['projectSchemas', _this2.cbFind('service')],
          hosts: ['projectSchemas', _this2.cbFind('host')],
          stacks: ['projectSchemas', _this2.cbFind('stack')],
          mounts: ['projectSchemas', _this2.cbFind('mount')],
          storagePools: ['projectSchemas', _this2.cbFind('storagepool')],
          volumes: ['projectSchemas', _this2.cbFind('volume')],
          snapshots: ['projectSchemas', _this2.cbFind('snapshot')],
          backups: ['projectSchemas', _this2.cbFind('backup')],
          certificate: ['projectSchemas', _this2.cbFind('certificate')],
          identities: ['userSchemas', _this2.cbFind('identity', 'userStore')]
        };

        var concur = 99;
        if (_uiUtilsPlatform.isSafari) {
          var version = (0, _uiUtilsPlatform.version)();
          if (version && version < 10) {
            // Safari for iOS9 has problems with multiple simultaneous requests
            concur = 1;
          }
        }

        async.auto(tasks, concur, function (err, res) {
          if (err) {
            reject(err);
          } else {
            resolve(res);
          }
        });
      }, 'Load all the things');

      return promise.then(function (hash) {
        if (hash.orchestrationState.kubernetesReady) {
          return _this2.loadKubernetes().then(function (k8sHash) {
            _ember['default'].merge(hash, k8sHash);
            return _ember['default'].Object.create(hash);
          });
        } else {
          return _ember['default'].Object.create(hash);
        }
      })['catch'](function (err) {
        return _this2.loadingError(err, transition, _ember['default'].Object.create({
          projects: [],
          project: null
        }));
      });
    },

    activate: function activate() {
      var app = this.controllerFor('application');

      this._super();
      if (!this.controllerFor('application').get('isPopup') && this.get('projects.current')) {
        this.connectSubscribe();
      }

      if (this.get('settings.isRancher') && !app.get('isPopup')) {
        //Show the telemetry opt-in
        var opt = this.get('settings.' + _uiUtilsConstants['default'].SETTING.TELEMETRY);
        if (this.get('access.admin') && (!opt || opt === 'prompt')) {
          _ember['default'].run.scheduleOnce('afterRender', this, function () {
            this.get('modalService').toggleModal('modal-welcome');
          });
        } else if (false && this.get('settings.isOSS') && !this.get('prefs.' + _uiUtilsConstants['default'].PREFS.FEEDBACK)) {
          //Show the feedback form
          var time = this.get('prefs.' + _uiUtilsConstants['default'].PREFS.FEEDBACK_TIME);
          if (!time) {
            time = new Date().getTime() + _uiUtilsConstants['default'].PREFS.FEEDBACK_DELAY;
            this.set('prefs.' + _uiUtilsConstants['default'].PREFS.FEEDBACK_TIME, time);
          }

          var now = new Date().getTime();
          if (now - time >= 0) {
            _ember['default'].run.scheduleOnce('afterRender', this, function () {
              this.get('modalService').toggleModal('modal-feedback');
            });
          }
        }
      }
    },

    deactivate: function deactivate() {
      this._super();
      this.disconnectSubscribe();
      _ember['default'].run.cancel(this.get('testTimer'));

      // Forget all the things
      this.get('storeReset').reset();
    },

    loadingError: function loadingError(err, transition, ret) {
      var isAuthEnabled = this.get('access.enabled');

      console.log('Loading Error:', err);
      if (err && (isAuthEnabled || [401, 403].indexOf(err.status) >= 0)) {
        this.send('logout', transition, transition.targetName !== 'authenticated.index');
        return;
      }

      this.replaceWith('settings.projects');
      return ret;
    },

    toCb: function toCb(name) {
      var _this3 = this;

      for (var _len = arguments.length, args = Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
        args[_key - 1] = arguments[_key];
      }

      return function (results, cb) {
        if (typeof results === 'function') {
          cb = results;
          results = null;
        }

        _this3[name].apply(_this3, args).then(function (res) {
          cb(null, res);
        })['catch'](function (err) {
          cb(err, null);
        });
      };
    },

    cbFind: function cbFind(type) {
      var _this4 = this;

      var store = arguments.length <= 1 || arguments[1] === undefined ? 'store' : arguments[1];

      return function (results, cb) {
        if (typeof results === 'function') {
          cb = results;
          results = null;
        }

        return _this4.get(store).find(type).then(function (res) {
          cb(null, res);
        })['catch'](function (err) {
          cb(err, null);
        });
      };
    },

    loadPreferences: function loadPreferences() {
      var _this5 = this;

      return this.get('userStore').find('userpreference', null, { url: 'userpreferences', forceReload: true }).then(function (res) {
        // Save the account ID from the response headers into session
        if (res) {
          _this5.set('session.' + _uiUtilsConstants['default'].SESSION.ACCOUNT_ID, res.xhr.headers.get(_uiUtilsConstants['default'].HEADER.ACCOUNT_ID));
        }

        _this5.get('language').initLanguage(true);
        _this5.get('userTheme').setupTheme();

        if (_this5.get('prefs.' + _uiUtilsConstants['default'].PREFS.I_HATE_SPINNERS)) {
          _ember['default'].$('BODY').addClass('i-hate-spinners');
        }

        return res;
      });
    },

    loadKubernetes: function loadKubernetes() {
      var k8s = this.get('k8s');

      return k8s.allNamespaces().then(function (all) {
        k8s.set('namespaces', all);
        return k8s.selectNamespace().then(function (ns) {
          return {
            namespaces: all,
            namespace: ns
          };
        });
      })['catch'](function () {
        return {
          namespaces: null,
          namespace: null
        };
      });
    },

    loadProjectSchemas: function loadProjectSchemas() {
      var store = this.get('store');
      store.resetType('schema');
      return store.rawRequest({ url: 'schema', dataType: 'json' }).then(function (xhr) {
        store._bulkAdd('schema', xhr.body.data);
      });
    },

    loadUserSchemas: function loadUserSchemas() {
      // @TODO Inline me into releases
      var userStore = this.get('userStore');
      return userStore.rawRequest({ url: 'schema', dataType: 'json' }).then(function (xhr) {
        userStore._bulkAdd('schema', xhr.body.data);
      });
    },

    loadProjects: function loadProjects() {
      var svc = this.get('projects');
      return svc.getAll().then(function (all) {
        svc.set('all', all);
        return all;
      });
    },

    updateOrchestration: function updateOrchestration() {
      return this.get('projects').updateOrchestrationState();
    },

    loadPublicSettings: function loadPublicSettings() {
      return this.get('userStore').find('setting', null, { url: 'setting', forceReload: true, filter: { all: 'false' } });
    },

    selectProject: function selectProject(transition) {
      var projectId = null;
      if (transition.params && transition.params['authenticated.project'] && transition.params['authenticated.project'].project_id) {
        projectId = transition.params['authenticated.project'].project_id;
      }

      // Make sure a valid project is selected
      return this.get('projects').selectDefault(projectId);
    },

    actions: {

      error: function error(err, transition) {
        // Unauthorized error, send back to login screen
        if (err.status === 401) {
          this.send('logout', transition, true);
          return false;
        } else {
          // Bubble up
          return true;
        }
      },

      showAbout: function showAbout() {
        this.controllerFor('application').set('showAbout', true);
      },

      switchProject: function switchProject(projectId) {
        var _this6 = this;

        var transition = arguments.length <= 1 || arguments[1] === undefined ? true : arguments[1];

        console.log('Switch to ' + projectId);
        this.disconnectSubscribe(function () {
          console.log('Switch is disconnected');
          _this6.send('finishSwitchProject', projectId, transition);
        });
      },

      finishSwitchProject: function finishSwitchProject(projectId, transition) {
        console.log('Switch finishing');
        this.get('storeReset').reset();
        if (transition) {
          this.intermediateTransitionTo('authenticated');
        }
        this.set('tab-session.' + _uiUtilsConstants['default'].TABSESSION.PROJECT, projectId);
        this.set('tab-session.' + _uiUtilsConstants['default'].TABSESSION.NAMESPACE, undefined);
        this.refresh();
        console.log('Switch finished');
      },

      refreshKubernetes: function refreshKubernetes() {
        var model = this.get('controller.model');
        var project = model.get('project');
        if (project && project.get('kubernetes')) {
          this.loadKubernetes(project).then(function (hash) {
            model.setProperties(hash);
          });
        }
      },

      switchNamespace: function switchNamespace(namespaceId) {
        var _this7 = this;

        var route = this.get('app.currentRouteName');

        if (route !== 'k8s-tab.namespaces' && !route.match(/^k8s-tab\.namespace\.[^.]+.index$/)) {
          route = 'k8s-tab.namespace';
        }

        // This will return a different one if the one you ask for doesn't exist
        this.get('k8s').selectNamespace(namespaceId).then(function (ns) {
          _this7.transitionTo(route, ns.get('id'));
        });
      }
    }
  });
});