define('ui/models/loadbalancerservice', ['exports', 'ui/models/service', 'ember', 'ui/utils/constants', 'ui/utils/util', 'ui/utils/parse-port'], function (exports, _uiModelsService, _ember, _uiUtilsConstants, _uiUtilsUtil, _uiUtilsParsePort) {

  var esc = _ember['default'].Handlebars.Utils.escapeExpression;

  function portToStr(spec) {
    var parts = (0, _uiUtilsParsePort.parsePortSpec)(spec);
    return parts.host + (parts.protocol === 'http' ? '' : '/' + parts.protocol);
  }

  function specToPort(spec) {
    var parts = (0, _uiUtilsParsePort.parsePortSpec)(spec);
    return parts.hostPort;
  }

  var LoadBalancerService = _uiModelsService['default'].extend({
    type: 'loadBalancerService',

    intl: _ember['default'].inject.service(),
    settings: _ember['default'].inject.service(),

    initPorts: function initPorts() {
      var _this = this;

      var rules = this.get('lbConfig.portRules') || [];
      var publish = this.get('launchConfig.ports') || [];
      publish.forEach(function (str) {
        var spec = (0, _uiUtilsParsePort.parsePortSpec)(str, 'tcp');
        if (!spec.hostPort || spec.hostIp) {
          _this.set('hasUnsupportedPorts', true);
        }

        if (spec.hostPort) {
          rules.filterBy('sourcePort', spec.hostPort).forEach(function (rule) {
            rule.set('access', 'public');
          });
        }
      });

      rules.forEach(function (rule) {
        if (!rule.get('access')) {
          rule.set('access', 'internal');
        }
      });
    },

    sslPorts: (function () {
      return ((this.get('launchConfig.labels') || {})[_uiUtilsConstants['default'].LABEL.BALANCER_SSL_PORTS] || '').split(',').map(function (str) {
        return parseInt(str, 10);
      });
    }).property('launchConfig.labels'),

    endpointsByPort: (function () {
      var sslPorts = this.get('sslPorts');

      return this._super().map(function (obj) {
        obj.ssl = sslPorts.indexOf(obj.port) >= 0;
        return obj;
      });
    }).property('endpointsMap'),

    displayPorts: (function () {
      var _this2 = this;

      var sslPorts = this.get('sslPorts');

      var internal = '';
      (this.get('launchConfig.expose') || []).forEach(function (portSpec, idx) {
        internal += '<span>' + (idx === 0 ? '' : ', ') + esc(portToStr(portSpec)) + '</span>';
      });

      var pub = '';
      var fqdn = this.get('fqdn');
      var ports = this.get('launchConfig.ports') || [];
      ports.forEach(function (portSpec, idx) {
        var portNum = specToPort(portSpec);
        var endpoints = _this2.get('endpointsMap')[portNum];
        if (endpoints) {
          var url = _uiUtilsUtil['default'].constructUrl(sslPorts.indexOf(portNum) >= 0, fqdn || endpoints[0], portNum);
          pub += '<span>' + '<a href="' + url + '" target="_blank">' + esc(portToStr(portSpec)) + '</a>' + (idx + 1 === ports.length ? '' : ', ') + '</span>';
        } else {
          pub += '<span>' + (idx === 0 ? '' : ', ') + esc(portToStr(portSpec)) + '</span>';
        }
      });

      var intl = this.get('intl');
      var portsTranslation = intl.t('generic.ports');
      var internalTranslation = intl.t('generic.internal');

      var out = (pub ? ' <label>' + portsTranslation + ': </label>' + pub : '') + (internal ? '<label>' + internalTranslation + ': </label>' + internal : '');

      return out.htmlSafe();
    }).property('launchConfig.ports.[]', 'launchConfig.expose.[]', 'endpointsMap', 'intl._locale'),

    displayDetail: (function () {
      var _this3 = this;

      var services = (this.get('lbConfig.portRules') || []).map(function (rule) {
        if (rule.get('selector')) {
          return rule.get('selector');
        } else {

          var _out = '';
          if (rule.get('service.stackId') !== _this3.get('stackId')) {
            _out += esc(rule.get('service.displayStack')) + '/';
          }

          return _out + esc(rule.get('service.displayName'));
        }
      }).uniq();

      services.sort();

      var str = '<span>' + services.join('</span><span>') + '</span>';

      var intl = this.get('intl');
      var out = '<label>' + intl.t('generic.to') + ': </label>' + str;

      return out.htmlSafe();
    }).property('consumedServicesWithNames.@each.{name,service}', 'intl._locale'),

    imageUpgradeAvailable: (function () {
      var cur = this.get('launchConfig.imageUuid').replace(/^docker:/, '');
      var available = this.get('settings.' + _uiUtilsConstants['default'].SETTING.BALANCER_IMAGE);
      return cur !== available && !!this.get('actionLinks.upgrade');
    }).property('launchConfig.imageUuid', 'settings.' + _uiUtilsConstants['default'].SETTING.BALANCER_IMAGE, 'actionLinks.upgrade')
  });

  exports['default'] = LoadBalancerService;
});