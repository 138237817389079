define('ui/k8s-tab/index/route', ['exports', 'ember', 'ui/utils/constants'], function (exports, _ember, _uiUtilsConstants) {
  exports['default'] = _ember['default'].Route.extend({
    k8s: _ember['default'].inject.service(),
    'tab-session': _ember['default'].inject.service('tab-session'),

    redirect: function redirect() {
      var all = this.get('k8s.namespaces') || [];
      var nsId = this.get('tab-session.' + _uiUtilsConstants['default'].TABSESSION.NAMESPACE);

      if (all.filterBy('id', nsId).get('length') > 0) {
        this.transitionTo('k8s-tab.namespace', nsId);
      } else if (all.get('length')) {
        this.transitionTo('k8s-tab.namespace', all.objectAt(0).get('id'));
      }
    }
  });
});