define('ui/components/machine/driver-digitalocean/component', ['exports', 'ember', 'ui/mixins/driver', 'ember-api-store/utils/fetch', 'ui/utils/util'], function (exports, _ember, _uiMixinsDriver, _emberApiStoreUtilsFetch, _uiUtilsUtil) {

  var DIGITALOCEAN_API = 'api.digitalocean.com/v2';
  var VALID_IMAGES = [
  //  'centos-6-x64',
  'centos-7-x64', 'coreos-alpha', 'coreos-beta', 'coreos-stable',
  //  'debian-7-x64',
  'debian-8-x64', 'fedora-23-x64', 'fedora-24-x64',
  //  'freebsd-10-1-x64',
  //  'freebsd-10-2-x64',
  'ubuntu-14-04-x64', 'ubuntu-16-04-x64'];

  //  'ubuntu-16-10-x64'
  exports['default'] = _ember['default'].Component.extend(_uiMixinsDriver['default'], {
    driverName: 'digitalocean',
    regionChoices: null,
    model: null,
    digitaloceanConfig: _ember['default'].computed.alias('model.digitaloceanConfig'),
    step1: true,
    sizeChoices: null,
    imageChoices: null,
    gettingData: false,

    filteredSizeChoices: _ember['default'].computed('digitaloceanConfig.region', function () {
      var region = this.get('regionChoices').findBy('slug', this.get('digitaloceanConfig.region'));
      var sizes = this.get('sizeChoices');
      var out = sizes.filter(function (size) {
        return region.sizes.indexOf(size.slug) >= 0;
      });

      return out;
    }),

    actions: {
      getData: function getData() {
        var _this = this;

        var promises = {
          regions: this.apiRequest('regions'),
          images: this.apiRequest('images', { type: 'distribution' }),
          sizes: this.apiRequest('sizes')
        };

        this.set('gettingData', true);

        _ember['default'].RSVP.hash(promises).then(function (hash) {

          var filteredRegions = hash.regions.regions.filter(function (region) {
            return region.available && region.features.indexOf('metadata') >= 0;
          }).sortBy('name');

          var filteredSizes = hash.sizes.sizes.filter(function (size) {
            return size.available;
          });

          var filteredImages = hash.images.images.filter(function (image) {
            // 64-bit only
            return !(image.name || '').match(/x32$/);
          }).map(function (image) {
            image.disabled = VALID_IMAGES.indexOf(image.slug) === -1;
            return image;
          }).sortBy('distribution', 'name');

          _this.setProperties({
            regionChoices: filteredRegions,
            sizeChoices: filteredSizes,
            imageChoices: filteredImages
          });

          _this.setProperties({
            step1: false,
            gettingData: false,
            errors: null
          });
        }, function (err) {

          var errors = _this.get('errors') || [];
          errors.push(err.statusText + ': ' + err.body.message);

          _this.setProperties({
            errors: errors,
            gettingData: false
          });
        });
      }
    },

    bootstrap: function bootstrap() {
      var config = this.get('store').createRecord({
        type: 'digitaloceanConfig',
        accessToken: '',
        size: '1gb',
        region: 'nyc3',
        image: 'ubuntu-16-04-x64'
      });

      this.set('model', this.get('store').createRecord({
        type: 'host',
        digitaloceanConfig: config
      }));
    },

    validate: function validate() {
      this._super();
      var errors = this.get('errors') || [];
      var name = this.get('model.hostname') || '';
      var accessToken = this.get('digitaloceanConfig.accessToken') || '';

      if (name.length > 200) {
        errors.push('"name" should be 1-200 characters long');
      }

      if (name.match(/[^a-z0-9-]/i)) {
        errors.push('"name" can only contain letters, numbers, and hyphen');
      }

      if (accessToken && accessToken.length !== 64) {
        errors.push("That doesn't look like a valid access token");
      }

      if (errors.get('length')) {
        this.set('errors', errors);
        return false;
      }

      return true;
    },

    apiRequest: function apiRequest(command, params) {
      var proxyEndpoint = this.get('app.proxyEndpoint');
      var url = proxyEndpoint + '/' + DIGITALOCEAN_API + '/' + command + '?per_page=100';
      url = _uiUtilsUtil['default'].addQueryParams(url, params);
      var accessToken = this.get('model.digitaloceanConfig.accessToken');

      return (0, _emberApiStoreUtilsFetch['default'])(url, {
        headers: {
          'Accept': 'application/json',
          'X-Api-Auth-Header': 'Bearer ' + accessToken
        }
      }).then(function (res) {
        return res.body;
      });
    }
  });
});