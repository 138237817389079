define('ui/components/new-catalog/component', ['exports', 'ember', 'ui/mixins/new-or-edit', 'npm:shell-quote', 'ui/utils/constants', 'ui/utils/util', 'ui/utils/parse-version'], function (exports, _ember, _uiMixinsNewOrEdit, _npmShellQuote, _uiUtilsConstants, _uiUtilsUtil, _uiUtilsParseVersion) {
  exports['default'] = _ember['default'].Component.extend(_uiMixinsNewOrEdit['default'], {
    k8s: _ember['default'].inject.service(),
    intl: _ember['default'].inject.service(),
    projects: _ember['default'].inject.service(),
    settings: _ember['default'].inject.service(),

    allTemplates: null,
    templateResource: null,
    stackResource: null,
    versionsArray: null,
    versionsLinks: null,
    actuallySave: true,
    showHeader: true,
    showPreview: true,
    showName: true,
    titleAdd: 'newCatalog.titleAdd',
    titleUpgrade: 'newCatalog.titleUpgrade',
    selectVersionAdd: 'newCatalog.selectVersionAdd',
    selectVersionUpgrade: 'newCatalog.selectVersionUpgrade',
    saveUpgrade: 'newCatalog.saveUpgrade',
    saveNew: 'newCatalog.saveNew',
    sectionClass: 'well',
    showDefaultVersionOption: false,

    classNames: ['launch-catalog'],

    primaryResource: _ember['default'].computed.alias('stackResource'),
    templateBase: _ember['default'].computed.alias('templateResource.templateBase'),
    editing: _ember['default'].computed.notEmpty('stackResource.id'),

    previewOpen: false,
    previewTab: null,
    questionsArray: null,
    selectedTemplateUrl: null,
    selectedTemplateModel: null,
    readmeContent: null,

    actions: {
      cancel: function cancel() {
        this.sendAction('cancel');
      },

      togglePreview: function togglePreview() {
        this.toggleProperty('previewOpen');
      },

      selectPreviewTab: function selectPreviewTab(tab) {
        this.set('previewTab', tab);
      },

      changeTemplate: function changeTemplate(tpl) {
        this.get('application').transitionToRoute('catalog-tab.launch', tpl.id);
      }
    },

    didReceiveAttrs: function didReceiveAttrs() {
      var _this = this;

      this._super.apply(this, arguments);
      this.set('selectedTemplateModel', null);

      _ember['default'].run.scheduleOnce('afterRender', function () {
        if (_this.get('selectedTemplateUrl') === 'default') {
          _this.templateChanged();
        } else {
          var def = _this.get('templateResource.defaultVersion');
          var links = _this.get('versionLinks');
          if (links[def]) {
            _this.set('selectedTemplateUrl', links[def]);
          } else {
            _this.set('selectedTemplateUrl', null);
          }
        }
      });
    },

    updateReadme: function updateReadme() {
      var _this2 = this;

      var model = this.get('selectedTemplateModel');
      this.set('readmeContent', null);
      if (model && model.hasLink('readme')) {
        model.followLink('readme').then(function (response) {
          _this2.set('readmeContent', response);
        });
      }
    },

    sortedVersions: (function () {
      var out = this.get('versionsArray').sort(function (a, b) {
        return (0, _uiUtilsParseVersion.compare)(a.version, b.version);
      });

      var def = this.get('templateResource.defaultVersion');
      if (this.get('showDefaultVersionOption') && def) {
        out.unshift({ version: this.get('intl').t('newCatalog.version.default', { version: def }), link: 'default' });
      }

      return out;
    }).property('versionsArray', 'templateResource.defaultVersion'),

    templateChanged: (function () {
      var _this3 = this;

      var url = this.get('selectedTemplateUrl');
      if (url) {
        this.set('loading', true);

        if (url === 'default') {
          var def = this.get('templateResource.defaultVersion');
          var links = this.get('versionLinks');
          if (def && links) {
            url = links[def];
          }
        }

        var version = this.get('settings.rancherVersion');
        if (version) {
          url = _uiUtilsUtil['default'].addQueryParam(url, 'minimumRancherVersion_lte', version);
        }

        var current = this.get('stackResource.environment');
        if (!current) {
          current = {};
          this.set('stackResource.environment', current);
        }

        this.get('store').request({
          url: url
        }).then(function (response) {
          if (response.questions) {
            response.questions.forEach(function (item) {
              // This will be the component that is rendered to edit this answer
              item.inputComponent = 'schema/input-' + item.type;

              // Only types marked supported will show the component, Ember will explode if the component doesn't exist
              item.supported = _uiUtilsConstants['default'].SUPPORTED_SCHEMA_INPUTS.indexOf(item.type) >= 0;

              if (typeof current[item.variable] !== 'undefined') {
                // If there's an existing value, use it (for upgrade)
                item.answer = current[item.variable];
              } else if (item.type === 'service' || item.type === 'certificate') {
                // Loaded async and then the component picks the default
              } else if (item.type === 'boolean') {
                  // Coerce booleans
                  item.answer = item['default'] === 'true' || item['default'] === true;
                } else {
                  // Everything else
                  item.answer = item['default'];
                }
            });
          }

          _this3.set('selectedTemplateModel', response);
          _this3.set('previewTab', Object.keys(response.get('files') || [])[0]);
          _this3.updateReadme();
          _this3.set('loading', false);
        }, function () /*error*/{});
      } else {
        this.set('selectedTemplateModel', null);
        this.updateReadme();
        this.set('readmeContent', null);
      }
    }).observes('selectedTemplateUrl', 'templateResource.defaultVersion'),

    answers: (function () {
      var out = {};
      (this.get('selectedTemplateModel.questions') || []).forEach(function (item) {
        out[item.variable] = item.answer;
      });

      return out;
    }).property('selectedTemplateModel.questions.@each.{variable,answer}'),

    answersArray: _ember['default'].computed.alias('selectedTemplateModel.questions'),

    answersString: (function () {
      return (this.get('answersArray') || []).map(function (obj) {
        if (obj.answer === null || obj.answer === undefined) {
          return obj.variable + '=';
        } else {
          return obj.variable + '=' + _npmShellQuote['default'].quote([obj.answer]);
        }
      }).join("\n");
    }).property('answersArray.@each.{variable,answer}'),

    validate: function validate() {
      var errors = [];

      if (!this.get('editing') && !this.get('stackResource.name')) {
        errors.push('Name is required');
      }

      if (this.get('selectedTemplateModel.questions')) {
        this.get('selectedTemplateModel.questions').forEach(function (item) {
          if (item.required && item.type !== 'boolean' && !item.answer) {
            errors.push(item.label + ' is required');
          }
        });
      }

      if (errors.length) {
        this.set('errors', errors.uniq());
        return false;
      }

      return true;
    },

    newExternalId: (function () {
      return _uiUtilsConstants['default'].EXTERNAL_ID.KIND_CATALOG + _uiUtilsConstants['default'].EXTERNAL_ID.KIND_SEPARATOR + this.get('selectedTemplateModel.id');
    }).property('selectedTemplateModel.id'),

    willSave: function willSave() {
      this.set('errors', null);
      var ok = this.validate();
      if (!ok) {
        // Validation failed
        return false;
      }

      var files = this.get('selectedTemplateModel.files');
      var stack = this.get('stackResource');

      if (this.get('actuallySave')) {
        stack.setProperties({
          dockerCompose: files['docker-compose.yml'],
          rancherCompose: files['rancher-compose.yml'],
          environment: this.get('answers'),
          externalId: this.get('newExternalId')
        });

        return true;
      } else {
        var versionId = null;
        if (this.get('selectedTemplateUrl') !== 'default' && this.get('selectedTemplateModel')) {
          versionId = this.get('selectedTemplateModel.id');
        }

        this.sendAction('doSave', {
          templateId: this.get('templateResource.id'),
          templateVersionId: versionId,
          answers: this.get('answers')
        });
        return false;
      }
    },

    doSave: function doSave() {
      var _this4 = this;

      var stack = this.get('stackResource');
      if (this.get('templateBase') === 'kubernetes') {
        if (this.get('k8s.supportsStacks')) {
          if (this.get('editing')) {
            return stack.doAction('upgrade', {
              templates: this.get('selectedTemplateModel.files'),
              environment: stack.get('environment'),
              externalId: this.get('newExternalId'),
              namespace: this.get('k8s.namespace.metadata.name')
            });
          } else {
            return this.get('store').createRecord({
              type: 'kubernetesStack',
              name: stack.get('name'),
              description: stack.get('description'),
              templates: this.get('selectedTemplateModel.files'),
              environment: stack.get('environment'),
              externalId: this.get('newExternalId'),
              namespace: this.get('k8s.namespace.metadata.name')
            }).save().then(function (newData) {
              return _this4.mergeResult(newData);
            });
          }
        } else {
          return this.get('k8s').catalog({
            files: this.get('selectedTemplateModel.files'),
            environment: stack.get('environment')
          });
        }
      } else if (this.get('templateBase') === 'swarm') {
        return this.get('store').createRecord({
          type: 'composeProject',
          name: stack.get('name'),
          description: stack.get('description'),
          templates: this.get('selectedTemplateModel.files'),
          externalId: this.get('newExternalId'),
          environment: stack.get('environment')
        }).save().then(function (newData) {
          return _this4.mergeResult(newData);
        });
      } else if (this.get('editing')) {
        return stack.doAction('upgrade', {
          dockerCompose: stack.get('dockerCompose'),
          rancherCompose: stack.get('rancherCompose'),
          environment: stack.get('environment'),
          externalId: this.get('newExternalId')
        });
      } else {
        return this._super.apply(this, arguments);
      }
    },

    doneSaving: function doneSaving() {
      var base = this.get('templateBase');
      var projectId = this.get('projects.current.id');
      if (base === 'kubernetes') {
        var nsId = this.get('k8s.namespace.id');
        if (this.get('k8s.supportsStacks')) {
          return this.get('router').transitionTo('k8s-tab.namespace.stacks', projectId, nsId);
        } else {
          return this.get('router').transitionTo('k8s-tab.namespace.services', projectId, nsId);
        }
      } else if (base === 'swarm') {
        return this.get('router').transitionTo('swarm-tab.projects', projectId);
      } else {
        if (this.get('stackResource.system')) {
          return this.get('router').transitionTo('stack', projectId, this.get('primaryResource.id'), { queryParams: { which: 'infra' } });
        } else {
          return this.get('router').transitionTo('stack', projectId, this.get('primaryResource.id'));
        }
      }
    }

  });
});