define('ui/components/k8s/new-or-edit-resource/component', ['exports', 'ember', 'ui/mixins/new-or-edit'], function (exports, _ember, _uiMixinsNewOrEdit) {
  exports['default'] = _ember['default'].Component.extend(_uiMixinsNewOrEdit['default'], {
    k8s: _ember['default'].inject.service(),

    label: null,
    primaryResource: null,
    editing: false,

    actions: {
      done: function done() {
        this.sendAction('done');
      },

      cancel: function cancel() {
        this.sendAction('cancel');
      }
    },

    didInsertElement: function didInsertElement() {
      this.$("textarea")[0].focus();
    },

    validate: function validate() {
      this.set('errors', []);
      return true;
    },

    doSave: function doSave() {
      if (this.get('editing')) {
        return this.get('k8s').edit(this.get('primaryResource.body'));
      } else {
        return this.get('k8s').create(this.get('primaryResource.body'));
      }
    },

    doneSaving: function doneSaving() {
      this.sendAction('done');
    }
  });
});