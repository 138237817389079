define('ui/admin-tab/processes/route', ['exports', 'ember'], function (exports, _ember) {

  var INTERVALCOUNT = 2000;

  exports['default'] = _ember['default'].Route.extend({

    queryParams: {
      showRunning: {
        refreshModel: true
      },
      resourceId: {
        refreshModel: true
      },
      resourceType: {
        refreshModel: true
      },
      processName: {
        refreshModel: true
      }
    },

    timer: null,

    redirect: function redirect() {
      this.transitionTo('admin-tab.processes.index', {
        queryParams: this.paramsFor('admin-tab.processes')
      });
    },

    model: function model(params) {
      var _this = this;

      return this.get('userStore').find('processinstance', null, this.parseParams(params)).then(function (response) {

        var resourceTypes = _this.get('userStore').all('schema').filterBy('links.collection').map(function (x) {
          return x.get('_id');
        });

        return _ember['default'].Object.create({
          processInstance: response,
          resourceTypes: resourceTypes
        });
      });
    },

    setupController: function setupController(controller, model) {

      this._super(controller, model); // restore the defaults as well

      this.scheduleTimer();
    },

    scheduleTimer: function scheduleTimer() {
      var _this2 = this;

      _ember['default'].run.cancel(this.get('timer'));
      this.set('timer', _ember['default'].run.later(function () {
        var params = _this2.paramsFor('admin-tab.processes');

        _this2.get('userStore').find('processinstance', null, _this2.parseParams(params)).then(function (response) {
          _this2.controller.get('model.processInstance').replaceWith(response);
          if (_this2.get('timer')) {
            _this2.scheduleTimer();
          }
        }, function () /*error*/{});
      }, INTERVALCOUNT));
    },

    deactivate: function deactivate() {
      _ember['default'].run.cancel(this.get('timer'));
      this.set('timer', null); // This prevents scheduleTimer from rescheduling if deactivate happened at just the wrong time.
    },

    parseParams: function parseParams(params) {

      var returnValue = {
        filter: {},
        limit: 100,
        sortBy: 'id',
        sortOrder: 'desc',
        depaginate: false,
        forceReload: true
      };

      if (params) {
        _.forEach(params, function (item, key) {

          if (item) {
            if (key === 'showRunning') {
              returnValue.filter.endTime_null = true;
            } else {
              returnValue.filter[key] = item;
            }
          } else {
            delete returnValue.filter[key];
          }
        });
      }
      return returnValue;
    }
  });
});