define("ui/components/api-field/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "revision": "Ember@2.9.1",
          "loc": {
            "source": null,
            "start": {
              "line": 5,
              "column": 2
            },
            "end": {
              "line": 7,
              "column": 2
            }
          },
          "moduleName": "ui/components/api-field/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "input", [], ["type", "checkbox", "checked", ["subexpr", "@mut", [["get", "value", ["loc", [null, [6, 36], [6, 41]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [6, 4], [6, 43]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child1 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@2.9.1",
            "loc": {
              "source": null,
              "start": {
                "line": 8,
                "column": 4
              },
              "end": {
                "line": 10,
                "column": 4
              }
            },
            "moduleName": "ui/components/api-field/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            return morphs;
          },
          statements: [["inline", "input", [], ["type", "number", "value", ["subexpr", "@mut", [["get", "value", ["loc", [null, [9, 34], [9, 39]]], 0, 0, 0, 0]], [], [], 0, 0], "classNames", "form-control", "placeholder", ["subexpr", "@mut", [["get", "placeholder", ["loc", [null, [9, 78], [9, 89]]], 0, 0, 0, 0]], [], [], 0, 0], "min", ["subexpr", "@mut", [["get", "fieldDef.min", ["loc", [null, [9, 94], [9, 106]]], 0, 0, 0, 0]], [], [], 0, 0], "max", ["subexpr", "@mut", [["get", "fieldDef.max", ["loc", [null, [9, 111], [9, 123]]], 0, 0, 0, 0]], [], [], 0, 0], "step", 1], ["loc", [null, [9, 6], [9, 132]]], 0, 0]],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.9.1",
          "loc": {
            "source": null,
            "start": {
              "line": 7,
              "column": 2
            },
            "end": {
              "line": 12,
              "column": 2
            }
          },
          "moduleName": "ui/components/api-field/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          morphs[1] = dom.createMorphAt(fragment, 2, 2, contextualElement);
          dom.insertBoundary(fragment, 0);
          return morphs;
        },
        statements: [["block", "if", [["subexpr", "or", [["subexpr", "eq", [["get", "fieldDef.type", ["loc", [null, [8, 18], [8, 31]]], 0, 0, 0, 0], "float"], [], ["loc", [null, [8, 14], [8, 40]]], 0, 0], ["subexpr", "eq", [["get", "fieldDef.type", ["loc", [null, [8, 45], [8, 58]]], 0, 0, 0, 0], "int"], [], ["loc", [null, [8, 41], [8, 65]]], 0, 0]], [], ["loc", [null, [8, 10], [8, 66]]], 0, 0]], [], 0, null, ["loc", [null, [8, 4], [10, 11]]]], ["inline", "input", [], ["type", "text", "value", ["subexpr", "@mut", [["get", "value", ["loc", [null, [11, 30], [11, 35]]], 0, 0, 0, 0]], [], [], 0, 0], "classNames", "form-control", "placeholder", ["subexpr", "@mut", [["get", "fieldDef.placeholder", ["loc", [null, [11, 74], [11, 94]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [11, 4], [11, 96]]], 0, 0]],
        locals: [],
        templates: [child0]
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.9.1",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 17,
            "column": 0
          }
        },
        "moduleName": "ui/components/api-field/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "col-md-2 form-label");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("label");
        dom.setAttribute(el2, "class", "form-control-static");
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "col-sm-12 col-md-4");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        dom.setAttribute(el2, "class", "form-control-static help-block");
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0, 1]);
        var element1 = dom.childAt(fragment, [2]);
        var morphs = new Array(5);
        morphs[0] = dom.createMorphAt(element0, 0, 0);
        morphs[1] = dom.createMorphAt(element0, 1, 1);
        morphs[2] = dom.createAttrMorph(element1, 'class');
        morphs[3] = dom.createMorphAt(element1, 1, 1);
        morphs[4] = dom.createMorphAt(dom.childAt(fragment, [4, 1]), 0, 0);
        return morphs;
      },
      statements: [["content", "fieldDef.name", ["loc", [null, [2, 37], [2, 54]]], 0, 0, 0, 0], ["inline", "if", [["get", "fieldDef.required", ["loc", [null, [2, 59], [2, 76]]], 0, 0, 0, 0], "*"], [], ["loc", [null, [2, 54], [2, 82]]], 0, 0], ["attribute", "class", ["concat", ["col-md-4 ", ["subexpr", "if", [["subexpr", "eq", [["get", "fieldDef.type", ["loc", [null, [4, 30], [4, 43]]], 0, 0, 0, 0], "boolean"], [], ["loc", [null, [4, 26], [4, 54]]], 0, 0], "form-control-static"], [], ["loc", [null, [4, 21], [4, 78]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["block", "if", [["subexpr", "eq", [["get", "fieldDef.type", ["loc", [null, [5, 12], [5, 25]]], 0, 0, 0, 0], "boolean"], [], ["loc", [null, [5, 8], [5, 36]]], 0, 0]], [], 0, 1, ["loc", [null, [5, 2], [12, 9]]]], ["content", "fieldDef.description", ["loc", [null, [15, 44], [15, 68]]], 0, 0, 0, 0]],
      locals: [],
      templates: [child0, child1]
    };
  })());
});