define('ui/mixins/cattle-transitioning-resource', ['exports', 'ember', 'ui/utils/util', 'ember-api-store/models/resource', 'ember-api-store/utils/normalize', 'ui/utils/constants'], function (exports, _ember, _uiUtilsUtil, _emberApiStoreModelsResource, _emberApiStoreUtilsNormalize, _uiUtilsConstants) {
  var _this2 = this;

  var defaultStateMap = {
    'activating': { icon: 'icon icon-tag', color: 'text-info' },
    'active': { icon: 'icon icon-circle-o', color: 'text-success' },
    'backedup': { icon: 'icon icon-backup', color: 'text-success' },
    'created': { icon: 'icon icon-tag', color: 'text-info' },
    'creating': { icon: 'icon icon-tag', color: 'text-info' },
    'deactivating': { icon: 'icon icon-adjust', color: 'text-info' },
    'degraded': { icon: 'icon icon-alert', color: 'text-warning' },
    'disconnected': { icon: 'icon icon-alert', color: 'text-warning' },
    'error': { icon: 'icon icon-alert', color: 'text-danger' },
    'inactive': { icon: 'icon icon-circle', color: 'text-danger' },
    'initializing': { icon: 'icon icon-alert', color: 'text-warning' },
    'purged': { icon: 'icon icon-purged', color: 'text-danger' },
    'purging': { icon: 'icon icon-purged', color: 'text-info' },
    'removed': { icon: 'icon icon-trash', color: 'text-danger' },
    'removing': { icon: 'icon icon-trash', color: 'text-info' },
    'requested': { icon: 'icon icon-tag', color: 'text-info' },
    'registering': { icon: 'icon icon-tag', color: 'text-info' },
    'reinitializing': { icon: 'icon icon-alert', color: 'text-warning' },
    'restoring': { icon: 'icon icon-medicalcross', color: 'text-info' },
    'running': { icon: 'icon icon-circle-o', color: 'text-success' },
    'snapshotted': { icon: 'icon icon-snapshot', color: 'text-warning' },
    'started-once': { icon: 'icon icon-dot-circlefill', color: 'text-success' },
    'starting': { icon: 'icon icon-adjust', color: 'text-info' },
    'stopped': { icon: 'icon icon-circle', color: 'text-danger' },
    'stopping': { icon: 'icon icon-adjust', color: 'text-info' },
    'unhealthy': { icon: 'icon icon-alert', color: 'text-danger' },
    'updating': { icon: 'icon icon-tag', color: 'text-info' },
    'updating-active': { icon: 'icon icon-tag', color: 'text-info' },
    'updating-healthy': { icon: 'icon icon-tag', color: 'text-info' },
    'updating-unhealthy': { icon: 'icon icon-tag', color: 'text-info' },
    'updating-reinitializing': { icon: 'icon icon-alert', color: 'text-info' },
    'updating-inactive': { icon: 'icon icon-tag', color: 'text-info' },
    'waiting': { icon: 'icon icon-tag', color: 'text-info' }
  };

  var stateColorSortMap = {
    'danger': 1,
    'warning': 2,
    'info': 3,
    'success': 4
  };
  var stateColorUnknown = 5;

  exports['default'] = _ember['default'].Mixin.create({
    endpointSvc: _ember['default'].inject.service('endpoint'), // Some machine drivers have a property called 'endpoint'
    cookies: _ember['default'].inject.service(),
    growl: _ember['default'].inject.service(),
    intl: _ember['default'].inject.service(),

    modalService: _ember['default'].inject.service('modal'),
    reservedKeys: ['waitInterval', 'waitTimeout'],

    state: null,
    transitioning: null,
    transitioningMessage: null,
    transitioningProgress: null,

    availableActions: (function () {
      /*
        Override me and return [
          {
            enabled: true/false,    // Whether it's enabled or greyed out
            detail: true/false,     // If true, this action will only be shown on detailed screens
            label: 'Delete',        // Label shown on hover or in menu
            icon: 'icon icon-trash',// Icon shown on screen
            action: 'promptDelete', // Action to call on the controller when clicked
            altAction: 'delete'     // Action to call on the controller when alt+clicked
            divider: true,          // Just this will make a divider
          },
          ...
        ]
      */
      return [];
    }).property(),

    translatedAvailableActions: _ember['default'].computed('availableActions', 'intl._locale', function () {
      var _this = this;

      // use this if you need to pass translated actions to addons
      var availableActions = this.get('availableActions');
      if (availableActions) {
        availableActions.forEach(function (action) {
          action.translatedLabel = _this.get('intl').findTranslationByKey(action.label);
        });
      }
      return availableActions;
    }),

    primaryAction: (function () {
      // The default implementation returns the first enabled item that has an icon
      // and is before the first divider.  If you want a different behavior or
      // multiple primaryActions, you can override this in a specific model.
      var all = this.get('availableActions');
      var obj;
      var seenAnAction = false;
      for (var i = 0; i < all.get('length'); i++) {
        obj = all.objectAt(i);
        if (_ember['default'].get(obj, 'divider')) {
          // Nothing was found, stop at the first divider;
          if (seenAnAction) {
            return null;
          }
        } else if (_ember['default'].get(obj, 'enabled')) {
          seenAnAction = true;
          if (_ember['default'].get(obj, 'icon') && _ember['default'].get(obj, 'action') !== 'promptDelete') {
            return obj;
          }
        }
      }

      return null;
    }).property('availableActions.@each.enabled'),

    actions: {
      promptDelete: function promptDelete() {
        this.get('modalService').toggleModal('confirm-delete', [this]);
      },

      'delete': function _delete() {
        return this['delete']();
      },

      restore: function restore() {
        return this.doAction('restore');
      },

      purge: function purge() {
        return this.doAction('purge');
      },

      goToApi: function goToApi() {
        var url = this.get('links.self'); // http://a.b.c.d/v1/things/id, a.b.c.d is where the UI is running
        var endpoint = this.get('endpointSvc.absolute'); // http://e.f.g.h/ , does not include version.  e.f.g.h is where the API actually is.
        url = url.replace(/https?:\/\/[^\/]+\/?/, endpoint);

        // For local development where API doesn't match origin, add basic auth token
        if (url.indexOf(window.location.origin) !== 0) {
          var token = this.get('cookies').get(_uiUtilsConstants['default'].COOKIE.TOKEN);
          if (token) {
            url = _uiUtilsUtil['default'].addAuthorization(url, _uiUtilsConstants['default'].USER.BASIC_BEARER, token);
          }
        }

        window.open(url, '_blank');
      }
    },

    displayName: (function () {
      return this.get('name') || '(' + this.get('id') + ')';
    }).property('name', 'id'),

    isTransitioning: _ember['default'].computed.equal('transitioning', 'yes'),
    isError: _ember['default'].computed.equal('transitioning', 'error'),
    isRemoved: _ember['default'].computed('state', function () {
      return !_uiUtilsConstants['default'].REMOVEDISH_STATES.includes(_this2.state);
    }),
    isPurged: _ember['default'].computed.equal('state', 'purged'),
    isActive: _ember['default'].computed.equal('state', 'active'),

    relevantState: (function () {
      return this.get('combinedState') || this.get('state');
    }).property('combinedState', 'state'),

    displayState: (function () {
      var state = this.get('relevantState') || '';
      return state.split(/-/).map(function (word) {
        return _uiUtilsUtil['default'].ucFirst(word);
      }).join('-');
    }).property('relevantState'),

    showTransitioningMessage: (function () {
      var trans = this.get('transitioning');
      return (trans === 'yes' || trans === 'error') && (this.get('transitioningMessage') || '').length > 0;
    }).property('transitioning', 'transitioningMessage'),

    stateIcon: (function () {
      var trans = this.get('transitioning');
      var icon = '';
      if (trans === 'yes') {
        icon = 'icon icon-spinner icon-spin';
      } else if (trans === 'error') {
        icon = 'icon icon-alert';
      } else {
        var map = this.constructor.stateMap;
        var key = (this.get('relevantState') || '').toLowerCase();
        if (map && map[key] && map[key].icon !== undefined) {
          if (typeof map[key].icon === 'function') {
            icon = map[key].icon(this);
          } else {
            icon = map[key].icon;
          }
        }

        if (!icon && defaultStateMap[key] && defaultStateMap[key].icon) {
          icon = defaultStateMap[key].icon;
        }

        if (!icon) {
          icon = this.constructor.defaultStateIcon;
        }

        if (icon.indexOf('icon ') === -1) {
          icon = 'icon ' + icon;
        }
      }

      return icon;
    }).property('relevantState', 'transitioning'),

    stateColor: (function () {
      if (this.get('isError')) {
        return 'text-danger';
      }

      var map = this.constructor.stateMap;
      var key = (this.get('relevantState') || '').toLowerCase();
      if (map && map[key] && map[key].color !== undefined) {
        if (typeof map[key].color === 'function') {
          return map[key].color(this);
        } else {
          return map[key].color;
        }
      }

      if (defaultStateMap[key] && defaultStateMap[key].color) {
        return defaultStateMap[key].color;
      }

      return this.constructor.defaultStateColor;
    }).property('relevantState', 'isError'),

    stateSort: (function () {
      var color = this.get('stateColor').replace('text-', '');
      return (stateColorSortMap[color] || stateColorUnknown) + ' ' + this.get('relevantState');
    }).property('stateColor', 'relevantState'),

    stateBackground: (function () {
      return this.get('stateColor').replace("text-", "bg-");
    }).property('stateColor'),

    trimValues: function trimValues(depth, seenObjs) {
      var _this3 = this;

      if (!depth) {
        depth = 0;
      }

      if (!seenObjs) {
        seenObjs = [];
      }
      this.eachKeys(function (val, key) {
        _ember['default'].set(_this3, key, recurse(val, depth));
      }, false);

      return this;

      function recurse(val, depth) {
        if (depth > 10) {
          console.log(val);
          return val;
        } else if (typeof val === 'string') {
          return val.trim();
        } else if (_ember['default'].isArray(val)) {
          val.beginPropertyChanges();
          val.forEach(function (v, idx) {
            var out = recurse(v, depth + 1);
            if (val.objectAt(idx) !== out) {
              val.replace(idx, 1, out);
            }
          });
          val.endPropertyChanges();
          return val;
        } else if (_emberApiStoreModelsResource['default'].detectInstance(val)) {
          // Don't include a resource we've already seen in the chain
          if (seenObjs.indexOf(val) > 0) {
            return null;
          }

          seenObjs.pushObject(val);
          return val.trimValues(depth + 1, seenObjs);
        } else if (val && typeof val === 'object') {
          Object.keys(val).forEach(function (key) {
            // Skip keys with dots in them, like container labels
            if (key.indexOf('.') === -1) {
              _ember['default'].set(val, key, recurse(val[key], depth + 1));
            }
          });
          return val;
        } else {
          return val;
        }
      }
    },

    validationErrors: function validationErrors() {
      var errors = [];
      var type = this.get('type');
      if (type) {
        type = (0, _emberApiStoreUtilsNormalize.normalizeType)(this.get('type'));
      } else {
        console.warn('No type found to validate', this);
        return [];
      }

      var schema = this.get('store').getById('schema', type);
      if (!schema) {
        console.warn('No schema found to validate', type, this);
        return [];
      }

      // Trim all the values to start so that empty strings become nulls
      this.trimValues();

      var fields = schema.resourceFields;
      var keys = Object.keys(fields);
      var field, key, val;
      var more;
      for (var i = 0; i < keys.length; i++) {
        key = keys[i];
        field = fields[key];
        val = this.get(key);
        var displayKey = _uiUtilsUtil['default'].camelToTitle(key);

        if (val === undefined) {
          val = null;
        }

        if (field.type.indexOf('[') >= 0) {
          // array, map, reference
          // @todo
        } else if (['string', 'password', 'float', 'int', 'date', 'blob', 'boolean', 'enum'].indexOf(field.type) === -1) {
            // embedded schema type
            if (val && val.validationErrors) {
              more = val.validationErrors();
              errors.pushObjects(more);
            }
          }

        // Coerce strings to numbers
        if (field.type === 'float' && typeof val === 'string') {
          val = parseFloat(val) || null; // NaN becomes null
          this.set(key, val);
        }

        if (field.type === 'int' && typeof val === 'string' && key !== 'id') // Sigh: ids are all marked int, rancher/rancher#515
          {
            val = parseInt(val, 10) || null;
            this.set(key, val);
          }

        // Empty strings on nullable fields -> null
        if (['string', 'password', 'float', 'int', 'date', 'blob', 'enum'].indexOf(field.type) >= 0) {
          if (typeof val === 'string' && !val || val === null) // empty/null strings or null numbers
            {
              if (field.nullable) {
                val = null;
                this.set(key, val);
              }
            }
        }

        var len = val ? _ember['default'].get(val, 'length') : 0;
        if (field.required && (val === null || typeof val === 'string' && len === 0 || _ember['default'].isArray(val) && len === 0)) {
          errors.push('"' + displayKey + '" is required');
          continue;
        }

        var min, max;
        var desc = field.type.indexOf('array[') === 0 ? 'item' : 'character';
        if (val !== null) {
          // String and array length:
          min = field.minLength;
          max = field.maxLength;
          if (min && max) {
            if (len < min || len > max) {
              errors.push(displayKey + ' should be ' + min + '-' + max + ' ' + desc + (min === 1 && max === 1 ? '' : 's') + ' long');
            }
          } else if (min && len < min) {
            errors.push(displayKey + ' should be at least ' + min + ' ' + desc + (min === 1 ? '' : 's') + ' long');
          } else if (max && len > max) {
            errors.push(displayKey + ' should be at most ' + max + ' ' + desc + (min === 1 ? '' : 's') + ' long');
          }

          // Number min/max
          min = field.min;
          max = field.max;
          if (val !== null && min && max) {
            if (val < min || val > max) {
              errors.push(displayKey + ' should be between ' + min + ' and ' + max);
            }
          } else if (min && val < min) {
            errors.push(displayKey + ' should be at least ' + min + ' ' + desc);
          } else if (max && val > max) {
            errors.push(displayKey + ' should be at most ' + max + ' ' + desc);
          }

          var test = [];
          if (field.validChars) {
            test.push('[^' + field.validChars + ']');
          }

          if (field.invalidChars) {
            test.push('[' + field.invalidChars + ']');
          }

          if (test.length) {
            var regex = new RegExp('(' + test.join('|') + ')', 'g');
            var match = val.match(regex);
            if (match) {
              match = match.uniq().map(function (chr) {
                if (chr === ' ') {
                  return '[space]';
                } else {
                  return chr;
                }
              });

              if (match.length === 1) {
                errors.push(displayKey + ' contains an invalid character: ' + match[0]);
              } else {
                errors.push(displayKey + ' contains ' + match.length + ' invalid characters: ' + match.join(' '));
              }
            }
          }
        }
      }

      return errors;
    },

    cloneForNew: function cloneForNew() {
      var copy = this.clone();
      delete copy.id;
      delete copy.actionLinks;
      delete copy.links;
      delete copy.uuid;
      return copy;
    },

    serializeForNew: function serializeForNew() {
      var copy = this.serialize();
      delete copy.id;
      delete copy.actionLinks;
      delete copy.links;
      delete copy.uuid;
      return copy;
    },

    // Show growls for errors on actions
    'delete': function _delete() /*arguments*/{
      var _this4 = this;

      var promise = this._super.apply(this, arguments);
      return promise['catch'](function (err) {
        _this4.get('growl').fromError('Error deleting', err);
      });
    },

    doAction: function doAction(name, data, opt) {
      var _this5 = this;

      var promise = this._super.apply(this, arguments);

      if (!opt || opt.catchGrowl !== false) {
        return promise['catch'](function (err) {
          _this5.get('growl').fromError(_uiUtilsUtil['default'].ucFirst(name) + ' Error', err);
          return _ember['default'].RSVP.reject(err);
        });
      }

      return promise;
    },

    // You really shouldn't have to use any of these.
    // Needing these is a sign that the API is bad and should feel bad.
    // Yet here they are, nonetheless.
    waitInterval: 1000,
    waitTimeout: 30000,
    _waitForTestFn: function _waitForTestFn(testFn, msg) {
      var _this6 = this;

      return new _ember['default'].RSVP.Promise(function (resolve, reject) {
        var timeout = setTimeout(function () {
          clearInterval(interval);
          clearTimeout(timeout);
          reject(_this6);
        }, _this6.get('waitTimeout'));

        var interval = setInterval(function () {
          if (testFn.apply(_this6)) {
            clearInterval(interval);
            clearTimeout(timeout);
            resolve(_this6);
          }
        }, _this6.get('waitInterval'));
      }, msg || 'Wait for it...');
    },

    waitForState: function waitForState(state) {
      return this._waitForTestFn(function () {
        return this.get('state') === state;
      }, 'Wait for state=' + state);
    },

    waitForNotTransitioning: function waitForNotTransitioning() {
      return this._waitForTestFn(function () {
        return this.get('transitioning') !== 'yes';
      }, 'Wait for not transitioning');
    },

    waitForAction: function waitForAction(name) {
      return this._waitForTestFn(function () {
        //console.log('waitForAction('+name+'):', this.hasAction(name));
        return this.hasAction(name);
      }, 'Wait for action=' + name);
    },

    waitForAndDoAction: function waitForAndDoAction(name, data) {
      var _this7 = this;

      return this.waitForAction(name).then(function () {
        return _this7.doAction(name, data);
      }, 'Wait for and do action=' + name);
    }
  });
});