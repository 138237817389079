define('ui/k8s-tab/namespace/route', ['exports', 'ember', 'ui/utils/constants'], function (exports, _ember, _uiUtilsConstants) {
  exports['default'] = _ember['default'].Route.extend({
    k8s: _ember['default'].inject.service(),
    'tab-session': _ember['default'].inject.service('tab-session'),

    model: function model(params) {
      var _this = this;

      return this.get('k8s').getNamespace(params.namespace_id).then(function (ns) {
        _this.set('tab-session.' + _uiUtilsConstants['default'].TABSESSION.NAMESPACE, ns.get('id'));
        _this.set('k8s.namespace', ns);
        return ns;
      })['catch'](function () /*err*/{
        _this.set('k8s.namespace', null);
        _this.transitionTo('k8s-tab');
      });
    }
  });
});