define('ui/services/access', ['exports', 'ember', 'ui/utils/constants'], function (exports, _ember, _uiUtilsConstants) {
  exports['default'] = _ember['default'].Service.extend({
    cookies: _ember['default'].inject.service(),
    session: _ember['default'].inject.service(),
    github: _ember['default'].inject.service(),
    shibbolethAuth: _ember['default'].inject.service(),
    store: _ember['default'].inject.service(),
    userStore: _ember['default'].inject.service('user-store'),

    token: null,
    loadedVersion: null,

    testAuth: function testAuth() {
      var _this = this;

      // make a call to api base because it is authenticated
      return this.get('userStore').rawRequest({
        url: ''
      }).then(function (xhr) {
        var loaded = _this.get('loadedVersion');
        var cur = xhr.headers.get(_uiUtilsConstants['default'].HEADER.RANCHER_VERSION);

        // Reload if the version changes
        if (loaded && cur && loaded !== cur) {
          window.location.href = window.location.href;
          return;
        }

        // Auth token still good
        return _ember['default'].RSVP.resolve('Auth Succeeded');
      }, function () /* err */{
        // Auth token expired
        return _ember['default'].RSVP.reject('Auth Failed');
      });
    },

    // The identity from the session isn't an actual identity model...
    identity: (function () {
      var obj = this.get('session.' + _uiUtilsConstants['default'].SESSION.IDENTITY) || {};
      obj.type = 'identity';
      return this.get('userStore').createRecord(obj);
    }).property('session.' + _uiUtilsConstants['default'].SESSION.IDENTITY),

    // These are set by authenticated/route
    // Is access control enabled
    enabled: null,

    // What kind of access control
    provider: null,

    // Are you an admin
    admin: null,

    detect: function detect() {
      var _this2 = this;

      if (this.get('enabled') !== null) {
        return _ember['default'].RSVP.resolve();
      }

      return this.get('userStore').rawRequest({
        url: 'token'
      }).then(function (xhr) {
        // If we get a good response back, the API supports authentication
        var token = xhr.body.data[0];

        _this2.setProperties({
          'enabled': token.security,
          'provider': (token.authProvider || '').toLowerCase(),
          'loadedVersion': xhr.headers.get(_uiUtilsConstants['default'].HEADER.RANCHER_VERSION)
        });

        _this2.set('token', token);

        if (_this2.shibbolethConfigured(token)) {
          _this2.get('shibbolethAuth').set('hasToken', token);
          _this2.get('session').set(_uiUtilsConstants['default'].SESSION.USER_TYPE, token.userType);
        } else if (!token.security) {
          _this2.clearSessionKeys();
        }

        return _ember['default'].RSVP.resolve(undefined, 'API supports authentication' + (token.security ? '' : ', but is not enabled'));
      })['catch'](function (err) {
        // Otherwise this API is too old to do auth.
        _this2.set('enabled', false);
        _this2.set('app.initError', err);
        return _ember['default'].RSVP.resolve(undefined, 'Error determining API authentication');
      });
    },

    shibbolethConfigured: function shibbolethConfigured(token) {
      var rv = false;
      if ((token.authProvider || '') === 'shibbolethconfig' && token.userIdentity) {
        rv = true;
      }
      return rv;
    },

    login: function login(code) {
      var _this3 = this;

      var session = this.get('session');

      return this.get('userStore').rawRequest({
        url: 'token',
        method: 'POST',
        data: {
          code: code,
          authProvider: this.get('provider')
        }
      }).then(function (xhr) {
        var auth = xhr.body;
        var interesting = {};
        _uiUtilsConstants['default'].TOKEN_TO_SESSION_KEYS.forEach(function (key) {
          if (typeof auth[key] !== 'undefined') {
            interesting[key] = auth[key];
          }
        });

        _this3.get('cookies').setWithOptions(_uiUtilsConstants['default'].COOKIE.TOKEN, auth['jwt'], {
          path: '/',
          secure: window.location.protocol === 'https:'
        });

        session.setProperties(interesting);
        return xhr;
      })['catch'](function (res) {
        var err = undefined;
        try {
          err = res.body;
        } catch (e) {
          err = { type: 'error', message: 'Error logging in' };
        }
        return _ember['default'].RSVP.reject(err);
      });
    },

    clearSessionKeys: function clearSessionKeys(all) {
      if (all === true) {
        this.get('session').clear();
      } else {
        var values = {};
        _uiUtilsConstants['default'].TOKEN_TO_SESSION_KEYS.forEach(function (key) {
          values[key] = undefined;
        });

        this.get('session').setProperties(values);
      }

      this.get('cookies').remove(_uiUtilsConstants['default'].COOKIE.TOKEN);
    },

    isLoggedIn: function isLoggedIn() {
      return !!this.get('cookies').get(_uiUtilsConstants['default'].COOKIE.TOKEN);
    },

    isOwner: function isOwner() {
      var schema = this.get('store').getById('schema', 'stack');
      if (schema && schema.resourceFields.system) {
        return schema.resourceFields.system.create;
      }

      return false;
    }
  });
});