define('ui/settings/projects/new/controller', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    actions: {
      done: function done() {
        this.send('refreshKubernetes');
        this.send('goToPrevious');
      },

      cancel: function cancel() {
        this.send('goToPrevious');
      }
    }
  });
});