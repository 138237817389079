define('ui/components/modal-feedback/component', ['exports', 'ember', 'ui/utils/constants', 'lacsso/components/modal-base'], function (exports, _ember, _uiUtilsConstants, _lacssoComponentsModalBase) {
  exports['default'] = _lacssoComponentsModalBase['default'].extend({
    classNames: ['lacsso', 'modal-container', 'span-6', 'offset-3'],
    settings: _ember['default'].inject.service(),
    prefs: _ember['default'].inject.service(),

    actions: {

      send: function send() {
        this.set('prefs.' + _uiUtilsConstants['default'].PREFS.FEEDBACK, 'sent');
        this.send('cancel');
      },

      cancel: function cancel() {
        var time = new Date().getTime() + _uiUtilsConstants['default'].PREFS.FEEDBACK_DELAY;
        this.set('prefs.' + _uiUtilsConstants['default'].PREFS.FEEDBACK_TIME, time);
        this.get('modalService').toggleModal();
      },

      never: function never() {
        this.set('prefs.' + _uiUtilsConstants['default'].PREFS.FEEDBACK, 'never');
        this.send('cancel');
      }
    }
  });
});