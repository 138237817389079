define('ui/application/route', ['exports', 'ember', 'ui/utils/constants'], function (exports, _ember, _uiUtilsConstants) {
  exports['default'] = _ember['default'].Route.extend({
    access: _ember['default'].inject.service(),
    cookies: _ember['default'].inject.service(),
    github: _ember['default'].inject.service(),
    language: _ember['default'].inject.service('user-language'),
    modal: _ember['default'].inject.service(),
    settings: _ember['default'].inject.service(),

    previousParams: null,
    previousRoute: null,
    loadingShown: false,
    loadingId: 0,
    hideTimer: null,
    previousLang: null,

    actions: {
      loading: function loading(transition) {
        var _this = this;

        this.incrementProperty('loadingId');
        var id = this.get('loadingId');
        _ember['default'].run.cancel(this.get('hideTimer'));

        //console.log('Loading', id);
        if (!this.get('loadingShown')) {
          this.set('loadingShown', true);
          //console.log('Loading Show', id);

          $('#loading-underlay').stop().show().fadeIn({ duration: 100, queue: false, easing: 'linear', complete: function complete() {
              $('#loading-overlay').stop().show().fadeIn({ duration: 200, queue: false, easing: 'linear' });
            } });
        }

        transition['finally'](function () {
          var self = _this;
          function hide() {
            //console.log('Loading hide', id);
            self.set('loadingShown', false);
            $('#loading-overlay').stop().fadeOut({ duration: 200, queue: false, easing: 'linear', complete: function complete() {
                $('#loading-underlay').stop().fadeOut({ duration: 100, queue: false, easing: 'linear' });
              } });
          }

          if (_this.get('loadingId') === id) {
            if (transition.isAborted) {
              //console.log('Loading aborted', id, this.get('loadingId'));
              _this.set('hideTimer', _ember['default'].run.next(hide));
            } else {
              //console.log('Loading finished', id, this.get('loadingId'));
              hide();
            }
          }
        });

        return true;
      },

      error: function error(err, transition) {
        /*if we dont abort the transition we'll call the model calls again and fail transition correctly*/
        transition.abort();

        if (err && err.status && [401, 403].indexOf(err.status) >= 0) {
          this.send('logout', transition, true);
          return;
        }

        this.controllerFor('application').set('error', err);
        this.transitionTo('failWhale');

        console.log('Application Error', err ? err.stack : undefined);
      },

      goToPrevious: function goToPrevious(def) {
        this.goToPrevious(def);
      },

      finishLogin: function finishLogin() {
        this.finishLogin();
      },

      logout: function logout(transition, timedOut, errorMsg) {
        var session = this.get('session');

        session.set(_uiUtilsConstants['default'].SESSION.ACCOUNT_ID, null);

        this.get('tab-session').clear();

        this.get('access').clearSessionKeys();

        if (transition) {
          session.set(_uiUtilsConstants['default'].SESSION.BACK_TO, window.location.href);
        }

        if (this.get('modal.modalVisible')) {
          this.get('modal').toggleModal();
        }

        var params = { queryParams: {} };

        if (timedOut) {
          params.queryParams.timedOut = true;
        }

        if (errorMsg) {
          params.queryParams.errorMsg = errorMsg;
        }

        this.transitionTo('login', params);
      },

      langToggle: function langToggle() {
        var svc = this.get('language');
        var cur = svc.getLocale();
        if (cur === 'none') {
          svc.sideLoadLanguage(this.get('previousLang') || 'en-us');
        } else {
          this.set('previousLang', cur);
          svc.sideLoadLanguage('none');
        }
      }
    },

    shortcuts: {
      'shift+l': 'langToggle'
    },

    finishLogin: function finishLogin() {
      var session = this.get('session');

      var backTo = session.get(_uiUtilsConstants['default'].SESSION.BACK_TO);
      session.set(_uiUtilsConstants['default'].SESSION.BACK_TO, undefined);

      if (backTo) {
        console.log('Going back to', backTo);
        window.location.href = backTo;
      } else {
        this.replaceWith('authenticated');
      }
    },

    model: function model(params, transition) {
      var _this2 = this;

      var github = this.get('github');
      var stateMsg = 'Authorization state did not match, please try again.';

      this.get('language').initLanguage();

      if (params.isPopup) {
        this.controllerFor('application').set('isPopup', true);
      }

      if (params.isTest) {
        if (github.stateMatches(params.state)) {
          reply(params.error_description, params.code);
        } else {
          reply(stateMsg);
        }

        transition.abort();

        return _ember['default'].RSVP.reject('isTest');
      } else if (params.code) {

        if (github.stateMatches(params.state)) {
          return this.get('access').login(params.code).then(function () {
            // Abort the orignial transition that was coming in here since
            // we'll redirect the user manually in finishLogin
            // if we dont then model hook runs twice to finish the transition itself
            transition.abort();
            // Can't call this.send() here because the initial transition isn't done yet
            _this2.finishLogin();
          })['catch'](function (err) {
            transition.abort();
            _this2.transitionTo('login', { queryParams: { errorMsg: err.message } });
          })['finally'](function () {
            _this2.controllerFor('application').setProperties({
              state: null,
              code: null
            });
          });
        } else {

          var obj = { message: stateMsg, code: 'StateMismatch' };

          this.controllerFor('application').set('error', obj);

          return _ember['default'].RSVP.reject(obj);
        }
      }

      function reply(err, code) {
        try {
          window.opener.window.onGithubTest(err, code);
          setTimeout(function () {
            window.close();
          }, 250);
          return new _ember['default'].RSVP.promise();
        } catch (e) {
          window.close();
        }
      }
    },

    updateWindowTitle: (function () {
      document.title = this.get('settings.appName');
    }).observes('settings.appName'),

    beforeModel: function beforeModel() {
      this.updateWindowTitle();

      var agent = window.navigator.userAgent.toLowerCase();

      if (agent.indexOf('msie ') >= 0 || agent.indexOf('trident/') >= 0) {
        this.replaceWith('ie');
        return;
      }

      // Find out if auth is enabled
      return this.get('access').detect();
    },

    setupController: function setupController(controller /*, model*/) {
      controller.set('code', null);
      controller.set('state', null);
      controller.set('error_description', null);
    }
  });
});