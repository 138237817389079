define('ui/hosts/new/route', ['exports', 'ember', 'ui/utils/constants', 'ui/utils/util'], function (exports, _ember, _uiUtilsConstants, _uiUtilsUtil) {
  var getOwner = _ember['default'].getOwner;

  function proxifyUrl(url, proxyBase) {
    var parsed = _uiUtilsUtil['default'].parseUrl(url);

    if (parsed.hostname.indexOf('.') === -1 || // No dot, local name like localhost
    parsed.hostname.toLowerCase().match(/\.local$/) || // your-macbook.local
    parsed.origin.toLowerCase() === window.location.origin // You are here
    ) {
        return url;
      } else {
      return proxyBase + '/' + url;
    }
  }

  exports['default'] = _ember['default'].Route.extend({
    access: _ember['default'].inject.service(),
    projects: _ember['default'].inject.service(),
    settings: _ember['default'].inject.service(),
    backTo: null,

    defaultDriver: 'custom',
    lastDriver: null,

    queryParams: {
      driver: {
        refreshModel: true
      },
      hostId: {
        refreshModel: false
      }
    },

    actions: {
      cancel: function cancel() {
        this.send('goBack');
      },

      savedHost: function savedHost() {
        this.refresh();
      },

      goBack: function goBack() {
        if (this.get('backTo') === 'waiting') {
          this.transitionTo('authenticated.project.waiting');
        } else {
          var appRoute = getOwner(this).lookup('route:application');
          var opts = this.get('previousOpts');

          appRoute.set('previousRoute', opts.name);
          appRoute.set('previousParams', opts.params);

          this.send('goToPrevious', 'hosts');
        }
      }
    },

    activate: function activate() {
      var appRoute = getOwner(this).lookup('route:application');
      this.set('previousOpts', { name: appRoute.get('previousRoute'), params: appRoute.get('previousParams') });
    },

    resetController: function resetController(controller, isExisting /*, transition*/) {
      if (isExisting) {
        controller.set('hostId', null);
        controller.set('backTo', null);
      }
    },

    machineDrivers: null,

    // Loads all the machine drivers and selects the active ones with a corresponding schema into machineDrivers
    beforeModel: function beforeModel() /*transition*/{
      var _this = this;

      this._super.apply(this, arguments);

      var us = this.get('userStore');
      var drivers = [];

      return us.find('machinedriver', null, { forceReload: true }).then(function (possible) {
        var promises = [];

        possible.filterBy('state', 'active').forEach(function (driver) {
          var schemaName = driver.get('name') + 'Config';
          promises.push(us.find('schema', schemaName).then(function () {
            drivers.push(driver);
          })['catch'](function () {
            return _ember['default'].RSVP.resolve();
          }));
        });

        return _ember['default'].RSVP.all(promises);
      }).then(function () {
        _this.set('machineDrivers', drivers);
      });
    },

    model: function model(params) {
      var _this2 = this;

      this.set('backTo', params.backTo);

      var promises = {
        reloadHost: this.get('userStore').find('schema', 'host', { forceReload: true }),
        loadCustomUi: this.loadCustomUi(),
        schemas: this.get('userStore').find('schema'),
        typeDocumentations: this.get('userStore').findAll('typedocumentation')
      };

      if (params.hostId) {
        promises.clonedModel = this.getHost(params.hostId);
      }

      if (this.get('access.admin')) {
        (function () {
          var settings = _this2.get('settings');
          promises.apiHostSet = settings.load(_uiUtilsConstants['default'].SETTING.API_HOST).then(function () {
            return !!settings.get(_uiUtilsConstants['default'].SETTING.API_HOST);
          });
        })();
      } else {
        promises.apiHostSet = _ember['default'].RSVP.resolve(true);
      }

      return _ember['default'].RSVP.hash(promises).then(function (hash) {
        hash.availableDrivers = _this2.get('machineDrivers');
        if (_this2.get('projects.current.isWindows')) {
          hash.availableDrivers = [];
        }

        var defaultDriver = _this2.get('defaultDriver');
        var targetDriver = params.driver || _this2.get('lastDriver') || defaultDriver;

        if (['custom', 'other'].indexOf(targetDriver) === -1 && hash.availableDrivers.filterBy('name', targetDriver).length === 0) {
          targetDriver = defaultDriver;
        }

        if (params.driver !== targetDriver) {
          _this2.transitionTo('hosts.new', { queryParams: { driver: targetDriver } });
        } else {
          return _ember['default'].Object.create(hash);
        }
      });
    },

    // Loads the custom UI CSS/JS for drivers that have a uiUrl,
    loadCustomUi: function loadCustomUi() {
      var _this3 = this;

      return new _ember['default'].RSVP.Promise(function (resolve, reject) {
        var completed = 0,
            expected = 0;
        var timer = null;

        function loaded() {
          completed++;
          if (completed === expected) {
            resolve();
            clearTimeout(timer);
          }
        }

        function errored(name) {
          clearTimeout(timer);
          reject({ type: 'error', message: 'Error loading custom driver UI: ' + name });
        }

        // machineDrivers already contains only the active ones with a schema
        _this3.get('machineDrivers').forEach(function (driver) {
          var id = 'driver-ui-js-' + driver.name;
          if (driver.uiUrl && $('#' + id).length === 0) {
            expected++;
            var script = document.createElement('script');
            script.onload = function () {
              loaded(driver.name);
            };
            script.onerror = function () {
              errored(driver.name);
            };
            script.src = proxifyUrl(driver.uiUrl, _this3.get('app.proxyEndpoint'));
            script.id = id;
            document.getElementsByTagName('BODY')[0].appendChild(script);

            expected++;
            var link = document.createElement('link');
            link.rel = 'stylesheet';
            link.id = id;
            link.href = proxifyUrl(driver.uiUrl.replace(/\.js$/, '.css'), _this3.get('app.proxyEndpoint'));
            link.onload = function () {
              loaded(driver.name);
            };
            link.onerror = function () {
              errored(driver.name);
            };
            document.getElementsByTagName('HEAD')[0].appendChild(link);
          }
        });

        if (expected === 0) {
          resolve();
        } else {
          timer = setTimeout(function () {
            reject({ type: 'error', message: 'Timeout loading custom machine drivers' });
          }, 10000);
        }
      });
    },

    getHost: function getHost(hostId) {
      var store = this.get('store');
      return store.find('host', hostId).then(function (host) {

        var hostOut = host.cloneForNew();
        var src = host[host.driver + 'Config'];
        if (src) {
          src.type = host.driver + 'Config';
          var config = store.createRecord(src);
          hostOut.set(host.driver + 'Config', config);
        }
        return hostOut;
      })['catch'](function () {
        return _ember['default'].RSVP.reject({ type: 'error', message: 'Failed to retrieve cloned model' });
      });
    }
  });
});