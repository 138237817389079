define('ui/components/settings/host-registration/component', ['exports', 'ember', 'ui/utils/constants'], function (exports, _ember, _uiUtilsConstants) {

  function isPublic(name) {
    if ((name || '').trim().replace(/^https?:\/\//, '').match(/^(localhost|192\.168\.|172\.1[6789]\.|172\.2[0123456789]\.|172\.3[01]\.|10\.)/)) {
      return false;
    }

    return true;
  }

  exports['default'] = _ember['default'].Component.extend({
    endpoint: _ember['default'].inject.service(),
    settings: _ember['default'].inject.service(),

    customRadio: null,
    customValue: '',
    thisPage: null,

    actions: {
      save: function save(btnCb) {
        var _this = this;

        var value = this.get('activeValue');

        if (!value) {
          this.set('errors', ['Please provide a URL']);
          btnCb();
          return;
        }

        this.set('errors', null);

        this.get('settings').set(_uiUtilsConstants['default'].SETTING.API_HOST, value);
        this.get('settings').one('settingsPromisesResolved', function () {
          btnCb(true);
          _this.sendAction('saved');
        });
      }
    },

    didReceiveAttrs: function didReceiveAttrs() {
      var initial = this.get('initialValue');
      var endpoint = this.get('endpoint.origin');
      var thisPage = window.location.origin;

      var value = undefined,
          radio = undefined;

      if (initial) {
        if (initial === thisPage) {
          value = initial === endpoint ? '' : endpoint;
          radio = 'no';
        } else {
          value = initial;
          radio = 'yes';
        }
      } else {
        if (endpoint === thisPage) {
          value = '';
          radio = 'no';
        } else {
          value = endpoint;
          radio = 'yes';
        }
      }

      this.setProperties({
        thisPage: thisPage,
        customValue: value,
        customRadio: radio
      });
    },

    looksPublic: (function () {
      return isPublic(this.get('activeValue'));
    }).property('activeValue'),

    activeValue: (function () {
      if (this.get('customRadio') === 'yes') {
        return this.get('customValue').trim();
      } else {
        return this.get('thisPage');
      }
    }).property('customRadio', 'customValue', 'thisPage'),

    customValueDidChange: (function () {
      var val = (this.get('customValue') || '').trim();
      var idx = val.indexOf('/', 8); // 8 is enough for "https://"
      if (idx !== -1) {
        // Trim paths off of the URL
        this.set('customValue', val.substr(0, idx));
        return; // We'll be back...
      }

      if (val) {
        this.set('customRadio', 'yes');
      }
    }).observes('customValue')

  });
});