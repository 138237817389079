define('ui/components/k8s/service-ports/component', ['exports', 'ember', 'ui/mixins/sortable'], function (exports, _ember, _uiMixinsSortable) {
  exports['default'] = _ember['default'].Component.extend(_uiMixinsSortable['default'], {
    model: null,
    sortBy: 'port',
    sorts: {
      name: ['name'],
      port: ['port'],
      targetPort: ['targetPort', 'port'],
      protocol: ['protocol', 'port']
    }
  });
});