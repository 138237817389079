define('ui/components/host-pod/component', ['exports', 'ember', 'ui/mixins/manage-labels', 'ui/mixins/grouped-instances'], function (exports, _ember, _uiMixinsManageLabels, _uiMixinsGroupedInstances) {
  exports['default'] = _ember['default'].Component.extend(_uiMixinsManageLabels['default'], _uiMixinsGroupedInstances['default'], {
    model: null,
    mode: null,
    show: null,

    classNames: ['pod', 'host'],

    init: function init() {
      this._super.apply(this, arguments);

      this.initLabels(this.get('model.labels'));
    },

    actions: {
      newContainer: function newContainer() {
        this.sendAction('newContainer', this.get('model.id'));
      }
    },

    shouldUpdateLabels: (function () {
      this.initLabels(this.get('model.labels'));
    }).observes('model.labels'),

    filteredInstances: (function () {
      var out = this.get('model.instances') || [];
      //out = out.filterBy('isRemoved', false);

      if (this.get('show') === 'standard') {
        out = out.filterBy('isSystem', false);
      }

      return out;
    }).property('model.instances.@each.labels', 'show'),

    arrangedInstances: (function () {
      return this.get('filteredInstances').sortBy('name', 'id');
    }).property('filteredInstances.@each.{name,id}'),

    isActive: _ember['default'].computed.equal('model.state', 'active'),
    isProvisioning: _ember['default'].computed.equal('model.state', 'provisioning'),
    isError: _ember['default'].computed.equal('model.state', 'error'),
    showAdd: _ember['default'].computed.alias('isActive'),
    showOnlyMessage: _ember['default'].computed.or('isProvisioning', 'isError'),

    stateBackground: (function () {
      return this.get('model.stateColor').replace("text-", "bg-");
    }).property('model.stateColor')

  });
});