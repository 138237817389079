define("ui/catalog-tab/index/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "revision": "Ember@2.9.1",
          "loc": {
            "source": null,
            "start": {
              "line": 6,
              "column": 6
            },
            "end": {
              "line": 8,
              "column": 6
            }
          },
          "moduleName": "ui/catalog-tab/index/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("i");
          dom.setAttribute(el1, "class", "icon icon-spinner icon-spin");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() {
          return [];
        },
        statements: [],
        locals: [],
        templates: []
      };
    })();
    var child1 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@2.9.1",
            "loc": {
              "source": null,
              "start": {
                "line": 9,
                "column": 8
              },
              "end": {
                "line": 11,
                "column": 8
              }
            },
            "moduleName": "ui/catalog-tab/index/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("i");
            dom.setAttribute(el1, "class", "icon icon-spinner icon-alert");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() {
            return [];
          },
          statements: [],
          locals: [],
          templates: []
        };
      })();
      var child1 = (function () {
        return {
          meta: {
            "revision": "Ember@2.9.1",
            "loc": {
              "source": null,
              "start": {
                "line": 11,
                "column": 8
              },
              "end": {
                "line": 13,
                "column": 8
              }
            },
            "moduleName": "ui/catalog-tab/index/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("i");
            dom.setAttribute(el1, "class", "icon icon-refresh");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() {
            return [];
          },
          statements: [],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.9.1",
          "loc": {
            "source": null,
            "start": {
              "line": 8,
              "column": 6
            },
            "end": {
              "line": 14,
              "column": 6
            }
          },
          "moduleName": "ui/catalog-tab/index/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "if", [["subexpr", "eq", [["get", "updating", ["loc", [null, [9, 18], [9, 26]]], 0, 0, 0, 0], "error"], [], ["loc", [null, [9, 14], [9, 35]]], 0, 0]], [], 0, 1, ["loc", [null, [9, 8], [13, 15]]]]],
        locals: [],
        templates: [child0, child1]
      };
    })();
    var child2 = (function () {
      var child0 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "revision": "Ember@2.9.1",
              "loc": {
                "source": null,
                "start": {
                  "line": 23,
                  "column": 72
                },
                "end": {
                  "line": 23,
                  "column": 131
                }
              },
              "moduleName": "ui/catalog-tab/index/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["content", "opt", ["loc", [null, [23, 124], [23, 131]]], 0, 0, 0, 0]],
            locals: [],
            templates: []
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.9.1",
            "loc": {
              "source": null,
              "start": {
                "line": 22,
                "column": 8
              },
              "end": {
                "line": 25,
                "column": 8
              }
            },
            "moduleName": "ui/catalog-tab/index/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("li");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("li");
            dom.setAttribute(el1, "class", "divider");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element5 = dom.childAt(fragment, [1]);
            var morphs = new Array(2);
            morphs[0] = dom.createAttrMorph(element5, 'class');
            morphs[1] = dom.createMorphAt(element5, 0, 0);
            return morphs;
          },
          statements: [["attribute", "class", ["concat", ["text-capitalize ", ["subexpr", "if", [["subexpr", "eq", [["get", "category", ["loc", [null, [23, 46], [23, 54]]], 0, 0, 0, 0], ["get", "opt", ["loc", [null, [23, 55], [23, 58]]], 0, 0, 0, 0]], [], ["loc", [null, [23, 42], [23, 59]]], 0, 0], "active"], [], ["loc", [null, [23, 37], [23, 70]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["block", "link-to", [["get", "parentRoute", ["loc", [null, [23, 83], [23, 94]]], 0, 0, 0, 0], ["subexpr", "query-params", [], ["category", ["get", "opt", ["loc", [null, [23, 118], [23, 121]]], 0, 0, 0, 0]], ["loc", [null, [23, 95], [23, 122]]], 0, 0]], [], 0, null, ["loc", [null, [23, 72], [23, 143]]]]],
          locals: [],
          templates: [child0]
        };
      })();
      var child1 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "revision": "Ember@2.9.1",
              "loc": {
                "source": null,
                "start": {
                  "line": 27,
                  "column": 12
                },
                "end": {
                  "line": 29,
                  "column": 12
                }
              },
              "moduleName": "ui/catalog-tab/index/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("              ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              return morphs;
            },
            statements: [["content", "opt", ["loc", [null, [28, 14], [28, 21]]], 0, 0, 0, 0]],
            locals: [],
            templates: []
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.9.1",
            "loc": {
              "source": null,
              "start": {
                "line": 25,
                "column": 8
              },
              "end": {
                "line": 31,
                "column": 8
              }
            },
            "moduleName": "ui/catalog-tab/index/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("li");
            var el2 = dom.createTextNode("\n");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("          ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element4 = dom.childAt(fragment, [1]);
            var morphs = new Array(2);
            morphs[0] = dom.createAttrMorph(element4, 'class');
            morphs[1] = dom.createMorphAt(element4, 1, 1);
            return morphs;
          },
          statements: [["attribute", "class", ["concat", [["subexpr", "if", [["subexpr", "eq", [["get", "category", ["loc", [null, [26, 30], [26, 38]]], 0, 0, 0, 0], ["get", "opt", ["loc", [null, [26, 39], [26, 42]]], 0, 0, 0, 0]], [], ["loc", [null, [26, 26], [26, 43]]], 0, 0], "active"], [], ["loc", [null, [26, 21], [26, 54]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["block", "link-to", [["get", "parentRoute", ["loc", [null, [27, 23], [27, 34]]], 0, 0, 0, 0], ["subexpr", "query-params", [], ["category", ["get", "opt", ["loc", [null, [27, 58], [27, 61]]], 0, 0, 0, 0]], ["loc", [null, [27, 35], [27, 62]]], 0, 0]], [], 0, null, ["loc", [null, [27, 12], [29, 24]]]]],
          locals: [],
          templates: [child0]
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.9.1",
          "loc": {
            "source": null,
            "start": {
              "line": 21,
              "column": 6
            },
            "end": {
              "line": 32,
              "column": 6
            }
          },
          "moduleName": "ui/catalog-tab/index/template.hbs"
        },
        isEmpty: false,
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "if", [["subexpr", "eq", [["get", "opt", ["loc", [null, [22, 18], [22, 21]]], 0, 0, 0, 0], "all"], [], ["loc", [null, [22, 14], [22, 28]]], 0, 0]], [], 0, 1, ["loc", [null, [22, 8], [31, 15]]]]],
        locals: ["opt"],
        templates: [child0, child1]
      };
    })();
    var child3 = (function () {
      var child0 = (function () {
        var child0 = (function () {
          var child0 = (function () {
            var child0 = (function () {
              return {
                meta: {
                  "revision": "Ember@2.9.1",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 47,
                      "column": 10
                    },
                    "end": {
                      "line": 49,
                      "column": 10
                    }
                  },
                  "moduleName": "ui/catalog-tab/index/template.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("            ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("button");
                  dom.setAttribute(el1, "type", "button");
                  dom.setAttribute(el1, "class", "btn btn-sm btn-default btn-disabled");
                  var el2 = dom.createComment("");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var element3 = dom.childAt(fragment, [1]);
                  var morphs = new Array(2);
                  morphs[0] = dom.createElementMorph(element3);
                  morphs[1] = dom.createMorphAt(element3, 0, 0);
                  return morphs;
                },
                statements: [["element", "action", ["launch", ["get", "catalogItem.id", ["loc", [null, [48, 96], [48, 110]]], 0, 0, 0, 0], true], ["allowedKeys", "meta ctrl"], ["loc", [null, [48, 78], [48, 141]]], 0, 0], ["inline", "t", ["catalogPage.index.action.alreadyDeployed"], [], ["loc", [null, [48, 142], [48, 190]]], 0, 0]],
                locals: [],
                templates: []
              };
            })();
            var child1 = (function () {
              var child0 = (function () {
                return {
                  meta: {
                    "revision": "Ember@2.9.1",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 49,
                        "column": 10
                      },
                      "end": {
                        "line": 51,
                        "column": 10
                      }
                    },
                    "moduleName": "ui/catalog-tab/index/template.hbs"
                  },
                  isEmpty: false,
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("            ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("button");
                    dom.setAttribute(el1, "class", "btn btn-sm btn-primary");
                    var el2 = dom.createComment("");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var element2 = dom.childAt(fragment, [1]);
                    var morphs = new Array(2);
                    morphs[0] = dom.createElementMorph(element2);
                    morphs[1] = dom.createMorphAt(element2, 0, 0);
                    return morphs;
                  },
                  statements: [["element", "action", ["launch", ["get", "catalogItem.id", ["loc", [null, [50, 69], [50, 83]]], 0, 0, 0, 0]], [], ["loc", [null, [50, 51], [50, 85]]], 0, 0], ["inline", "t", ["catalogPage.index.action.launch"], [], ["loc", [null, [50, 86], [50, 125]]], 0, 0]],
                  locals: [],
                  templates: []
                };
              })();
              var child1 = (function () {
                return {
                  meta: {
                    "revision": "Ember@2.9.1",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 51,
                        "column": 10
                      },
                      "end": {
                        "line": 53,
                        "column": 10
                      }
                    },
                    "moduleName": "ui/catalog-tab/index/template.hbs"
                  },
                  isEmpty: false,
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("            ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("button");
                    dom.setAttribute(el1, "type", "button");
                    dom.setAttribute(el1, "class", "btn btn-sm btn-default btn-disabled");
                    var el2 = dom.createComment("");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n          ");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var element1 = dom.childAt(fragment, [1]);
                    var morphs = new Array(2);
                    morphs[0] = dom.createElementMorph(element1);
                    morphs[1] = dom.createMorphAt(element1, 0, 0);
                    return morphs;
                  },
                  statements: [["element", "action", ["launch", ["get", "catalogItem.id", ["loc", [null, [52, 96], [52, 110]]], 0, 0, 0, 0], true], ["allowedKeys", "meta ctrl"], ["loc", [null, [52, 78], [52, 141]]], 0, 0], ["inline", "t", ["catalogPage.index.action.notCompatible"], [], ["loc", [null, [52, 142], [52, 188]]], 0, 0]],
                  locals: [],
                  templates: []
                };
              })();
              return {
                meta: {
                  "revision": "Ember@2.9.1",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 49,
                      "column": 10
                    },
                    "end": {
                      "line": 53,
                      "column": 10
                    }
                  },
                  "moduleName": "ui/catalog-tab/index/template.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [["block", "if", [["get", "catalogItem.supported", ["loc", [null, [49, 20], [49, 41]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [49, 10], [53, 10]]]]],
                locals: [],
                templates: [child0, child1]
              };
            })();
            return {
              meta: {
                "revision": "Ember@2.9.1",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 46,
                    "column": 8
                  },
                  "end": {
                    "line": 54,
                    "column": 8
                  }
                },
                "moduleName": "ui/catalog-tab/index/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [["block", "if", [["get", "catalogItem.exists", ["loc", [null, [47, 16], [47, 34]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [47, 10], [53, 17]]]]],
              locals: [],
              templates: [child0, child1]
            };
          })();
          var child1 = (function () {
            return {
              meta: {
                "revision": "Ember@2.9.1",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 54,
                    "column": 8
                  },
                  "end": {
                    "line": 56,
                    "column": 8
                  }
                },
                "moduleName": "ui/catalog-tab/index/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("          ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("button");
                dom.setAttribute(el1, "type", "button");
                dom.setAttribute(el1, "class", "btn btn-sm btn-primary");
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element0 = dom.childAt(fragment, [1]);
                var morphs = new Array(2);
                morphs[0] = dom.createElementMorph(element0);
                morphs[1] = dom.createMorphAt(element0, 0, 0);
                return morphs;
              },
              statements: [["element", "action", ["launch", ["get", "catalogItem.id", ["loc", [null, [55, 81], [55, 95]]], 0, 0, 0, 0]], [], ["loc", [null, [55, 63], [55, 97]]], 0, 0], ["inline", "t", ["catalogPage.index.action.launch"], [], ["loc", [null, [55, 98], [55, 137]]], 0, 0]],
              locals: [],
              templates: []
            };
          })();
          return {
            meta: {
              "revision": "Ember@2.9.1",
              "loc": {
                "source": null,
                "start": {
                  "line": 45,
                  "column": 6
                },
                "end": {
                  "line": 57,
                  "column": 6
                }
              },
              "moduleName": "ui/catalog-tab/index/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["block", "if", [["subexpr", "eq", [["get", "catalogItem.templateBase", ["loc", [null, [46, 18], [46, 42]]], 0, 0, 0, 0], "infra"], [], ["loc", [null, [46, 14], [46, 51]]], 0, 0]], [], 0, 1, ["loc", [null, [46, 8], [56, 15]]]]],
            locals: [],
            templates: [child0, child1]
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.9.1",
            "loc": {
              "source": null,
              "start": {
                "line": 44,
                "column": 4
              },
              "end": {
                "line": 58,
                "column": 4
              }
            },
            "moduleName": "ui/catalog-tab/index/template.hbs"
          },
          isEmpty: false,
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "if", [["subexpr", "eq", [["get", "section", ["loc", [null, [45, 16], [45, 23]]], 0, 0, 0, 0], "footer"], [], ["loc", [null, [45, 12], [45, 33]]], 0, 0]], [], 0, null, ["loc", [null, [45, 6], [57, 13]]]]],
          locals: ["section"],
          templates: [child0]
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.9.1",
          "loc": {
            "source": null,
            "start": {
              "line": 43,
              "column": 2
            },
            "end": {
              "line": 59,
              "column": 2
            }
          },
          "moduleName": "ui/catalog-tab/index/template.hbs"
        },
        isEmpty: false,
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "catalog-box", [], ["model", ["subexpr", "@mut", [["get", "catalogItem", ["loc", [null, [44, 25], [44, 36]]], 0, 0, 0, 0]], [], [], 0, 0], "showSource", ["subexpr", "@mut", [["get", "showCatalogDropdown", ["loc", [null, [44, 48], [44, 67]]], 0, 0, 0, 0]], [], [], 0, 0]], 0, null, ["loc", [null, [44, 4], [58, 20]]]]],
        locals: ["catalogItem"],
        templates: [child0]
      };
    })();
    var child4 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@2.9.1",
            "loc": {
              "source": null,
              "start": {
                "line": 61,
                "column": 6
              },
              "end": {
                "line": 63,
                "column": 6
              }
            },
            "moduleName": "ui/catalog-tab/index/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            return morphs;
          },
          statements: [["inline", "t", ["catalogPage.index.noData.plural"], [], ["loc", [null, [62, 8], [62, 47]]], 0, 0]],
          locals: [],
          templates: []
        };
      })();
      var child1 = (function () {
        return {
          meta: {
            "revision": "Ember@2.9.1",
            "loc": {
              "source": null,
              "start": {
                "line": 63,
                "column": 6
              },
              "end": {
                "line": 65,
                "column": 6
              }
            },
            "moduleName": "ui/catalog-tab/index/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            return morphs;
          },
          statements: [["inline", "t", ["catalogPage.index.noData.singular"], [], ["loc", [null, [64, 8], [64, 49]]], 0, 0]],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.9.1",
          "loc": {
            "source": null,
            "start": {
              "line": 59,
              "column": 2
            },
            "end": {
              "line": 67,
              "column": 2
            }
          },
          "moduleName": "ui/catalog-tab/index/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "text-muted r-mt20");
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
          return morphs;
        },
        statements: [["block", "if", [["get", "showCatalogDropdown", ["loc", [null, [61, 12], [61, 31]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [61, 6], [65, 13]]]]],
        locals: [],
        templates: [child0, child1]
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.9.1",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 69,
            "column": 0
          }
        },
        "moduleName": "ui/catalog-tab/index/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("section");
        dom.setAttribute(el1, "class", "header clearfix");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h1");
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode(" ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("span");
        dom.setAttribute(el3, "class", "text-capitalize");
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "pull-right");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("button");
        dom.setAttribute(el3, "type", "button");
        dom.setAttribute(el3, "class", "btn btn-default btn-sm");
        var el4 = dom.createTextNode("\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "btn-group pull-right r-ml10");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("button");
        dom.setAttribute(el3, "type", "button");
        dom.setAttribute(el3, "class", "btn btn-default btn-sm dropdown-toggle");
        dom.setAttribute(el3, "data-toggle", "dropdown");
        dom.setAttribute(el3, "aria-haspopup", "true");
        dom.setAttribute(el3, "aria-expanded", "false");
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode(" ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("span");
        dom.setAttribute(el4, "class", "text-capitalize");
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode(" ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("i");
        dom.setAttribute(el4, "class", "icon icon-chevron-down");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("ul");
        dom.setAttribute(el3, "class", "dropdown-menu dropdown-menu-right");
        var el4 = dom.createTextNode("\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "btn-group searchable pull-right");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("i");
        dom.setAttribute(el3, "class", "icon icon-search");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("section");
        dom.setAttribute(el1, "class", "r-plr15 clearfix");
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element6 = dom.childAt(fragment, [0]);
        var element7 = dom.childAt(element6, [1]);
        var element8 = dom.childAt(element6, [3, 1]);
        var element9 = dom.childAt(element6, [5]);
        var element10 = dom.childAt(element9, [1]);
        var morphs = new Array(10);
        morphs[0] = dom.createMorphAt(element7, 0, 0);
        morphs[1] = dom.createMorphAt(dom.childAt(element7, [2]), 0, 0);
        morphs[2] = dom.createAttrMorph(element8, 'disabled');
        morphs[3] = dom.createElementMorph(element8);
        morphs[4] = dom.createMorphAt(element8, 1, 1);
        morphs[5] = dom.createMorphAt(element10, 0, 0);
        morphs[6] = dom.createMorphAt(dom.childAt(element10, [2]), 0, 0);
        morphs[7] = dom.createMorphAt(dom.childAt(element9, [3]), 1, 1);
        morphs[8] = dom.createMorphAt(dom.childAt(element6, [7]), 3, 3);
        morphs[9] = dom.createMorphAt(dom.childAt(fragment, [2]), 1, 1);
        return morphs;
      },
      statements: [["inline", "t", ["catalogPage.index.header"], [], ["loc", [null, [2, 6], [2, 38]]], 0, 0], ["content", "catalogId", ["loc", [null, [2, 69], [2, 82]]], 0, 0, 0, 0], ["attribute", "disabled", ["subexpr", "eq", [["get", "updating", ["loc", [null, [5, 71], [5, 79]]], 0, 0, 0, 0], "yes"], [], ["loc", [null, [null, null], [5, 87]]], 0, 0], 0, 0, 0, 0], ["element", "action", ["update"], [], ["loc", [null, [5, 88], [5, 107]]], 0, 0], ["block", "if", [["subexpr", "eq", [["get", "updating", ["loc", [null, [6, 16], [6, 24]]], 0, 0, 0, 0], "yes"], [], ["loc", [null, [6, 12], [6, 31]]], 0, 0]], [], 0, 1, ["loc", [null, [6, 6], [14, 13]]]], ["inline", "t", ["catalogPage.index.category"], [], ["loc", [null, [19, 139], [19, 173]]], 0, 0], ["content", "category", ["loc", [null, [19, 204], [19, 216]]], 0, 0, 0, 0], ["block", "each", [["get", "categories", ["loc", [null, [21, 14], [21, 24]]], 0, 0, 0, 0]], [], 2, null, ["loc", [null, [21, 6], [32, 15]]]], ["inline", "input", [], ["placeholder", ["subexpr", "t", ["catalogPage.index.search.placeholder"], [], ["loc", [null, [38, 24], [38, 66]]], 0, 0], "class", "form-control input-sm", "value", ["subexpr", "@mut", [["get", "search", ["loc", [null, [38, 103], [38, 109]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [38, 4], [38, 111]]], 0, 0], ["block", "each", [["get", "arrangedContent", ["loc", [null, [43, 10], [43, 25]]], 0, 0, 0, 0]], [], 3, 4, ["loc", [null, [43, 2], [67, 11]]]]],
      locals: [],
      templates: [child0, child1, child2, child3, child4]
    };
  })());
});