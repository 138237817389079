define('ui/admin-tab/processes/index/controller', ['exports', 'ember', 'ui/mixins/sortable'], function (exports, _ember, _uiMixinsSortable) {
  exports['default'] = _ember['default'].Controller.extend(_uiMixinsSortable['default'], {
    processesController: _ember['default'].inject.controller('admin-tab.processes'),
    parentQueryParams: _ember['default'].computed.alias('processesController.queryParams'),
    showRunning: _ember['default'].computed.alias('processesController.showRunning'),
    resourceId: _ember['default'].computed.alias('processesController.resourceId'),
    resourceType: _ember['default'].computed.alias('processesController.resourceType'),
    processName: _ember['default'].computed.alias('processesController.processName'),
    // these are here so we can execute the search with the search button
    // so we dont have to do this.refresh in the route. I used this.refresh
    // in the route for something else and it caused other issues so i decided
    // against using it.
    ownResourceId: null,
    ownResourceType: null,
    ownProcessName: null,
    resourceTypeReadable: null,

    sortableContent: _ember['default'].computed.alias('model.processInstance'),
    sortBy: 'startTime',
    descending: true,

    sorts: {
      id: ['id'],
      processName: ['processName', 'id'],
      resource: ['resourceType', 'resourceId', 'id'],
      startTime: ['startTime', 'id'],
      endTime: ['endTime', 'id'],
      runTime: ['runTime', 'id']
    },

    actions: {
      showRunningProcesses: function showRunningProcesses() {
        this.toggleProperty('showRunning');
      },
      updateType: function updateType(type) {
        this.set('resourceTypeReadable', type);
        this.set('ownResourceType', type);
      },
      submit: function submit() {
        this.setProperties({
          resourceId: this.get('ownResourceId'),
          resourceType: this.get('ownResourceType'),
          processName: this.get('ownProcessName')
        });
      },
      reset: function reset() {
        this.setProperties({
          resourceId: null,
          ownResourceId: null,
          resourceType: null,
          resourceTypeReadable: null,
          ownResourceType: null,
          processName: null,
          ownProcessName: null
        });
        _ember['default'].$('#resource-type').val('');
      }
    },

    parseParams: _ember['default'].on('init', function () {
      var _this = this;

      // This parses the parent query strings since the input values are not bound
      // directly to the querystrings. See this.refresh comment at top of file for
      // more information.
      _.forEach(this.get('parentQueryParams'), function (param) {

        var paramVal = _this.get(param);

        if (param !== 'showRunning') {

          if (paramVal) {

            switch (param) {
              case 'resourceId':
                _this.set('ownResourceId', paramVal);
                break;
              case 'resourceType':
                _this.set('ownResourceType', paramVal);

                _ember['default'].run.later(function () {

                  _ember['default'].$('#resource-type').val(_.find(_this.get('model.resourceTypes'), function (item) {
                    return item === _this.get(param);
                  }));
                });
                break;
              case 'processName':
                _this.set('ownProcessName', paramVal);
                break;
              default:
                break;
            }
          }
        }
      });
    }),

    disableId: _ember['default'].computed('ownResourceType', function () {
      if (this.get('ownResourceType')) {
        return false;
      } else {
        return true;
      }
    })
  });
});