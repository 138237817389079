define('ui/k8s-tab/controller', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    projects: _ember['default'].inject.service(),

    actions: {
      kubernetesReady: function kubernetesReady() {
        var _this = this;

        this.send('refreshKubernetes');
        this.get('projects').updateOrchestrationState().then(function () {
          _this.transitionToRoute('k8s-tab.index');
        });
      }
    }
  });
});