define("ui/k8s-tab/namespace/pods/pod/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "revision": "Ember@2.9.1",
          "loc": {
            "source": null,
            "start": {
              "line": 2,
              "column": 6
            },
            "end": {
              "line": 2,
              "column": 90
            }
          },
          "moduleName": "ui/k8s-tab/namespace/pods/pod/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["inline", "t", ["k8sTab.namespace.pods.pod.header.linkTo"], [], ["loc", [null, [2, 43], [2, 90]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child1 = (function () {
      return {
        meta: {
          "revision": "Ember@2.9.1",
          "loc": {
            "source": null,
            "start": {
              "line": 19,
              "column": 0
            },
            "end": {
              "line": 33,
              "column": 0
            }
          },
          "moduleName": "ui/k8s-tab/namespace/pods/pod/template.hbs"
        },
        isEmpty: false,
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("ul");
          dom.setAttribute(el1, "class", "nav nav-tabs nav-tabs-well");
          dom.setAttribute(el1, "role", "tablist");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("li");
          dom.setAttribute(el2, "role", "tab");
          dom.setAttribute(el2, "class", "tab");
          dom.setAttribute(el2, "data-section", "containers");
          var el3 = dom.createElement("a");
          dom.setAttribute(el3, "href", "#");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("li");
          dom.setAttribute(el2, "role", "tab");
          dom.setAttribute(el2, "class", "tab");
          dom.setAttribute(el2, "data-section", "labels");
          var el3 = dom.createElement("a");
          dom.setAttribute(el3, "href", "#");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "horizontal-form well nav-well");
          dom.setAttribute(el1, "role", "tabpanel");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "section container-fluid");
          dom.setAttribute(el2, "data-section", "containers");
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "section container-fluid");
          dom.setAttribute(el2, "data-section", "labels");
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1]);
          var element1 = dom.childAt(element0, [1]);
          var element2 = dom.childAt(element0, [3]);
          var element3 = dom.childAt(fragment, [3]);
          var morphs = new Array(6);
          morphs[0] = dom.createElementMorph(element1);
          morphs[1] = dom.createMorphAt(dom.childAt(element1, [0]), 0, 0);
          morphs[2] = dom.createElementMorph(element2);
          morphs[3] = dom.createMorphAt(dom.childAt(element2, [0]), 0, 0);
          morphs[4] = dom.createMorphAt(dom.childAt(element3, [1]), 1, 1);
          morphs[5] = dom.createMorphAt(dom.childAt(element3, [3]), 1, 1);
          return morphs;
        },
        statements: [["element", "action", ["selectTab", "containers"], ["target", "component"], ["loc", [null, [21, 57], [21, 111]]], 0, 0], ["inline", "t", ["k8sTab.namespace.pods.pod.navTabs.containers"], [], ["loc", [null, [21, 124], [21, 176]]], 0, 0], ["element", "action", ["selectTab", "labels"], ["target", ["get", "component", ["loc", [null, [22, 90], [22, 99]]], 0, 0, 0, 0]], ["loc", [null, [22, 53], [22, 101]]], 0, 0], ["inline", "t", ["k8sTab.navTabs.labels"], [], ["loc", [null, [22, 114], [22, 143]]], 0, 0], ["inline", "k8s/container-section", [], ["model", ["subexpr", "@mut", [["get", "model.displayContainers", ["loc", [null, [27, 36], [27, 59]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [27, 6], [27, 61]]], 0, 0], ["inline", "labels-section", [], ["model", ["subexpr", "@mut", [["get", "model", ["loc", [null, [30, 29], [30, 34]]], 0, 0, 0, 0]], [], [], 0, 0], "labelSource", ["subexpr", "@mut", [["get", "model.metadata.labels", ["loc", [null, [30, 47], [30, 68]]], 0, 0, 0, 0]], [], [], 0, 0], "showKind", false, "sortBy", "key"], ["loc", [null, [30, 6], [30, 98]]], 0, 0]],
        locals: ["component"],
        templates: []
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.9.1",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 34,
            "column": 0
          }
        },
        "moduleName": "ui/k8s-tab/namespace/pods/pod/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("section");
        dom.setAttribute(el1, "class", "header clearfix");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h1");
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode(" ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "pull-right");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("section");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "container-multi-stat");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "container-flex bordered");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4, "class", "col-flex");
        var el5 = dom.createElement("label");
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode(" ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4, "class", "col-flex");
        var el5 = dom.createElement("label");
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode(" ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4, "class", "col-flex");
        var el5 = dom.createElement("label");
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode(" ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4, "class", "col-flex");
        var el5 = dom.createElement("label");
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode(" ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element4 = dom.childAt(fragment, [0]);
        var element5 = dom.childAt(element4, [1]);
        var element6 = dom.childAt(fragment, [2, 1, 1]);
        var element7 = dom.childAt(element6, [1]);
        var element8 = dom.childAt(element6, [3]);
        var element9 = dom.childAt(element6, [5]);
        var element10 = dom.childAt(element6, [7]);
        var morphs = new Array(12);
        morphs[0] = dom.createMorphAt(element5, 0, 0);
        morphs[1] = dom.createMorphAt(element5, 2, 2);
        morphs[2] = dom.createMorphAt(dom.childAt(element4, [3]), 1, 1);
        morphs[3] = dom.createMorphAt(dom.childAt(element7, [0]), 0, 0);
        morphs[4] = dom.createMorphAt(element7, 2, 2);
        morphs[5] = dom.createMorphAt(dom.childAt(element8, [0]), 0, 0);
        morphs[6] = dom.createMorphAt(element8, 2, 2);
        morphs[7] = dom.createMorphAt(dom.childAt(element9, [0]), 0, 0);
        morphs[8] = dom.createMorphAt(element9, 2, 2);
        morphs[9] = dom.createMorphAt(dom.childAt(element10, [0]), 0, 0);
        morphs[10] = dom.createMorphAt(element10, 2, 2);
        morphs[11] = dom.createMorphAt(fragment, 4, 4, contextualElement);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["block", "link-to", ["k8s-tab.namespace.pods"], [], 0, null, ["loc", [null, [2, 6], [2, 102]]]], ["content", "model.displayName", ["loc", [null, [2, 103], [2, 124]]], 0, 0, 0, 0], ["inline", "action-menu", [], ["model", ["subexpr", "@mut", [["get", "model", ["loc", [null, [4, 24], [4, 29]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [4, 4], [4, 31]]], 0, 0], ["inline", "t", ["k8sTab.multistat.header.phase"], [], ["loc", [null, [11, 35], [11, 72]]], 0, 0], ["content", "model.status.phase", ["loc", [null, [11, 81], [11, 103]]], 0, 0, 0, 0], ["inline", "t", ["k8sTab.multistat.header.startTime"], [], ["loc", [null, [12, 35], [12, 76]]], 0, 0], ["inline", "date-from-now", [["get", "model.status.startTime", ["loc", [null, [12, 101], [12, 123]]], 0, 0, 0, 0]], [], ["loc", [null, [12, 85], [12, 125]]], 0, 0], ["inline", "t", ["k8sTab.multistat.header.host"], [], ["loc", [null, [13, 35], [13, 71]]], 0, 0], ["content", "model.status.hostIP", ["loc", [null, [13, 80], [13, 103]]], 0, 0, 0, 0], ["inline", "t", ["k8sTab.multistat.header.pod"], [], ["loc", [null, [14, 35], [14, 70]]], 0, 0], ["content", "model.status.podIP", ["loc", [null, [14, 79], [14, 101]]], 0, 0, 0, 0], ["block", "select-tab", [], ["initialTab", "containers"], 1, null, ["loc", [null, [19, 0], [33, 15]]]]],
      locals: [],
      templates: [child0, child1]
    };
  })());
});