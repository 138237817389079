define('ui/components/page-footer/component', ['exports', 'ember', 'ui/utils/util', 'ui/utils/constants'], function (exports, _ember, _uiUtilsUtil, _uiUtilsConstants) {
  exports['default'] = _ember['default'].Component.extend({
    tagName: 'footer',
    className: 'clearfix',

    settings: _ember['default'].inject.service(),
    githubBase: _uiUtilsConstants['default'].EXT_REFERENCES.GITHUB,
    forumBase: _uiUtilsConstants['default'].EXT_REFERENCES.FORUM,

    projectId: _ember['default'].computed.alias('tab-session.' + _uiUtilsConstants['default'].TABSESSION.PROJECT),

    modalService: _ember['default'].inject.service('modal'),
    actions: {
      showAbout: function showAbout() {
        this.get('modalService').toggleModal('modal-about', {
          closeWithOutsideClick: true
        });
      },

      composeDownload: function composeDownload(os) {
        var url = this.get('settings').get(_uiUtilsConstants['default'].SETTING.COMPOSE_URL[os.toUpperCase()]);
        if (url) {
          _uiUtilsUtil['default'].download(url);
        }
      },

      cliDownload: function cliDownload(os) {
        var url = this.get('settings').get(_uiUtilsConstants['default'].SETTING.CLI_URL[os.toUpperCase()]);
        if (url) {
          _uiUtilsUtil['default'].download(url);
        }
      }
    }
  });
});