define('ui/k8s-tab/namespace/index/route', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    k8s: _ember['default'].inject.service(),
    redirect: function redirect() {
      if (this.get('k8s.supportsStacks')) {
        this.transitionTo('k8s-tab.namespace.stacks');
      } else {
        this.transitionTo('k8s-tab.namespace.services');
      }
    }
  });
});