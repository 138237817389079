define('ui/k8s-tab/apply/route', ['exports', 'ember'], function (exports, _ember) {

  var TEMPLATES = {
    namespace: 'apiVersion: v1\nkind: Namespace\nmetadata:\n  name: ""\nspec:\n  finalizers:\n',

    service: 'apiVersion: v1\nkind: Service\nmetadata:\n  name:\n  namespace: "%NAMESPACE%"\n  labels:\nspec:\n  ports:\n  - name: ""\n    port:\n    protocol: TCP\n    targetPort:\n  selector:\n',

    deployment: 'apiVersion: extensions/v1beta1\nkind: Deployment\nmetadata:\n  name: ""\nspec:\n  replicas: 2\n  template:\n    metadata:\n      labels:\n    spec:\n      containers:\n      - name: ""\n        image: ""\n        ports:\n',

    replicaset: 'apiVersion: extensions/v1beta1\nkind: ReplicaSet\nmetadata:\n  labels:\n  name: ""\n  namespace: "%NAMESPACE%"\nspec:\n  replicas: 2\n  selector:\n  template:\n    metadata:\n      labels:\n    spec:\n      restartPolicy: Always\n      containers:\n      - image: ""\n        imagePullPolicy: Always\n        name: ""\n',

    replicationcontroller: 'apiVersion: v1\nkind: ReplicationController\nmetadata:\n  labels:\n  name: ""\n  namespace: "%NAMESPACE%"\nspec:\n  replicas: 2\n  selector:\n  template:\n    metadata:\n      labels:\n    spec:\n      restartPolicy: Always\n      containers:\n      - image: ""\n        imagePullPolicy: Always\n        name: ""\n',

    pod: 'apiVersion: v1\nkind: Pod\nmetadata:\n  name:\n  namespace: "%NAMESPACE%"\n  labels:\nspec:\n  type:\n  ports:\n  selector:\n'

  };

  exports['default'] = _ember['default'].Route.extend({
    k8s: _ember['default'].inject.service(),

    model: function model(params) {
      var k8s = this.get('k8s');
      var ns = k8s.get('namespace.metadata.name') || '';
      var kind = (params.kind || '').toLowerCase();

      var fn;
      switch (kind) {
        case 'namespace':
          fn = k8s.getNamespace;
          break;
        case 'deployment':
          fn = k8s.getDeployment;
          break;
        case 'service':
          fn = k8s.getService;
          break;
        case 'replicaset':
          fn = k8s.getReplicaSet;
          break;
        case 'replicationcontroller':
          fn = k8s.getRC;
          break;
        case 'pod':
          fn = k8s.getPod;
          break;
        case 'deployment':
          fn = k8s.getDeployment;
          break;
        default:
          return _ember['default'].RSVP.reject('Unknown Kind');
      }

      if (params.name) {
        return this.get('k8s').getYaml(kind, params.name, ns).then(function (yaml) {
          return _ember['default'].Object.create({
            body: yaml,
            editing: true,
            label: 'k8sTab.types.' + kind
          });
        });
      } else {
        return _ember['default'].Object.create({
          body: TEMPLATES[kind].replace('%NAMESPACE%', ns),
          editing: false,
          label: 'k8sTab.types.' + kind
        });
      }
    },

    resetController: function resetController(controller, isExiting /*, transition*/) {
      if (isExiting) {
        controller.setProperties({
          name: null,
          kind: null
        });
      }
    }
  });
});