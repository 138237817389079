define('ui/components/edit-host/component', ['exports', 'ember', 'ui/mixins/manage-labels', 'ui/mixins/new-or-edit', 'lacsso/components/modal-base'], function (exports, _ember, _uiMixinsManageLabels, _uiMixinsNewOrEdit, _lacssoComponentsModalBase) {
  exports['default'] = _lacssoComponentsModalBase['default'].extend(_uiMixinsNewOrEdit['default'], _uiMixinsManageLabels['default'], {
    classNames: ['lacsso', 'modal-container', 'large-modal'],
    originalModel: _ember['default'].computed.alias('modalService.modalOpts'),
    model: null,
    editing: true,

    init: function init() {
      this._super.apply(this, arguments);
      this.set('model', this.get('originalModel').clone());
    },

    actions: {
      setLabels: function setLabels(labels) {
        var out = {};
        labels.forEach(function (row) {
          out[row.key] = row.value;
        });

        this.set('model.labels', out);
      }
    },

    doneSaving: function doneSaving() {
      this.send('cancel');
    }
  });
});