define('ui/authenticated/project/api/hooks/route', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    webhookStore: _ember['default'].inject.service(),

    model: function model() {
      var _this = this;

      return this.get('webhookStore').findAll('schema', { url: 'schemas' }).then(function (schemas) {
        var receiver = schemas.findBy('id', 'receiver').resourceFields;
        receiver.name.required = true;
        receiver.scaleServiceConfig.required = true;
        schemas.findBy('id', 'scaleservice').resourceFields.serviceId.required = true;

        return _ember['default'].RSVP.hash({
          receivers: _this.get('webhookStore').findAll('receiver', { forceReload: true })
        });
      });
    }
  });
});