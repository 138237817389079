define('ui/storagepools/route', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    model: function model() {
      var store = this.get('store');
      return _ember['default'].RSVP.hash({
        pools: store.findAll('storagepool'),
        mounts: store.findAll('mounts')
      }).then(function (hash) {
        return hash.pools.filter(function (pool) {
          return !!pool.get('driverName');
        });
      });
    }
  });
});