define("ui/utils/azure-choices", ["exports"], function (exports) {
  var regions = [{
    "name": "eastasia",
    "displayName": "East Asia"
  }, {
    "name": "southeastasia",
    "displayName": "Southeast Asia"
  }, {
    "name": "centralus",
    "displayName": "Central US"
  }, {
    "name": "eastus",
    "displayName": "East US"
  }, {
    "name": "eastus2",
    "displayName": "East US 2"
  }, {
    "name": "westus",
    "displayName": "West US"
  }, {
    "name": "northcentralus",
    "displayName": "North Central US"
  }, {
    "name": "southcentralus",
    "displayName": "South Central US"
  }, {
    "name": "northeurope",
    "displayName": "North Europe"
  }, {
    "name": "westeurope",
    "displayName": "West Europe"
  }, {
    "name": "japanwest",
    "displayName": "Japan West"
  }, {
    "name": "japaneast",
    "displayName": "Japan East"
  }, {
    "name": "brazilsouth",
    "displayName": "Brazil South"
  }, {
    "name": "australiaeast",
    "displayName": "Australia East"
  }, {
    "name": "australiasoutheast",
    "displayName": "Australia Southeast"
  }, {
    "name": "southindia",
    "displayName": "South India"
  }, {
    "name": "centralindia",
    "displayName": "Central India"
  }, {
    "name": "westindia",
    "displayName": "West India"
  }, {
    "name": "canadacentral",
    "displayName": "Canada Central"
  }, {
    "name": "canadaeast",
    "displayName": "Canada East"
  }, {
    "name": "westcentralus",
    "displayName": "West Central US"
  }, {
    "name": "westus2",
    "displayName": "West US 2"
  }];

  exports.regions = regions;
  var sizes = [{
    value: 'Standard_A0'
  }, {
    value: 'Standard_A1'
  }, {
    value: 'Standard_A2'
  }, {
    value: 'Standard_A3'
  }, {
    value: 'Standard_A4'
  }, {
    value: 'Standard_A5'
  }, {
    value: 'Standard_A6'
  }, {
    value: 'Standard_A7'
  }, {
    value: 'Standard_A8'
  }, {
    value: 'Standard_A9'
  }, {
    value: 'Standard_A10'
  }, {
    value: 'Standard_A11'
  }, {
    value: 'Standard_D1'
  }, {
    value: 'Standard_D2'
  }, {
    value: 'Standard_D3'
  }, {
    value: 'Standard_D4'
  }, {
    value: 'Standard_D11'
  }, {
    value: 'Standard_D12'
  }, {
    value: 'Standard_D13'
  }, {
    value: 'Standard_D14'
  }, {
    value: 'Standard_D1_v2'
  }, {
    value: 'Standard_D2_v2'
  }, {
    value: 'Standard_D3_v2'
  }, {
    value: 'Standard_D4_v2'
  }, {
    value: 'Standard_D5_v2'
  }, {
    value: 'Standard_D11_v2'
  }, {
    value: 'Standard_D12_v2'
  }, {
    value: 'Standard_D13_v2'
  }, {
    value: 'Standard_D14_v2'
  }, {
    value: 'Standard_DS1'
  }, {
    value: 'Standard_DS2'
  }, {
    value: 'Standard_DS3'
  }, {
    value: 'Standard_DS12'
  }, {
    value: 'Standard_DS13'
  }, {
    value: 'Standard_DS14'
  }, {
    value: 'Standard_G1'
  }, {
    value: 'Standard_G2'
  }, {
    value: 'Standard_G3'
  }, {
    value: 'Standard_G4'
  }, {
    value: 'Standard_G5'
  }, {
    value: 'Standard_GS1'
  }, {
    value: 'Standard_GS2'
  }, {
    value: 'Standard_GS3'
  }, {
    value: 'Standard_GS4'
  }, {
    value: 'Standard_GS5'
  }, {
    value: 'ExtraSmall'
  }, {
    value: 'Small'
  }, {
    value: 'Medium'
  }, {
    value: 'Large'
  }, {
    value: 'ExtraLarge'
  }];

  exports.sizes = sizes;
  var storageTypes = [{
    name: 'Standard LRS',
    value: 'Standard_LRS'
  }, {
    name: 'Standard ZRS',
    value: 'Standard_ZRS'
  }, {
    name: 'Standard GRS',
    value: 'Standard_GRS'
  }, {
    name: 'Standard RAGRS',
    value: 'Standard_RAGRS'
  }, {
    name: 'Premium LRS',
    value: 'Premium_LRS'
  }];
  exports.storageTypes = storageTypes;
});