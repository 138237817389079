define('ui/k8s-tab/error/route', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    k8s: _ember['default'].inject.service(),

    actions: {
      reload: function reload() {
        window.location.href = window.location.href;
      }
    },

    model: function model() {
      var errors = this.get('k8s.loadingErrors');
      if (errors && _ember['default'].isArray(errors) && errors.get('length')) {
        return errors;
      } else {
        this.transitionTo('k8s-tab');
      }
    }
  });
});