define('ui/models/portrule', ['exports', 'ember', 'ember-api-store/models/resource', 'ember-api-store/utils/denormalize'], function (exports, _ember, _emberApiStoreModelsResource, _emberApiStoreUtilsDenormalize) {

  function setTlsPort() {
    if (this.get('targetPort')) {
      return;
    }

    var proto = this.get('protocol').toLowerCase();
    var src = parseInt(this.get('sourcePort'), 10);
    var tgt = null;

    if (proto === 'http' && src === 80 || proto === 'https' && src === 443) {
      tgt = 80;
    } else if (proto === 'sni' && src === 443) {
      tgt = 443;
    }

    if (tgt) {
      this.set('targetPort', tgt);
    }
  }

  var PortRule = _emberApiStoreModelsResource['default'].extend({
    type: 'portRule',
    reservedKeys: ['access', 'isSelector'],

    service: (0, _emberApiStoreUtilsDenormalize.denormalizeId)('serviceId'),

    needsCertificate: (function () {
      return ['tls', 'https'].includes(this.get('protocol'));
    }).property('protocol'),

    canHostname: (function () {
      return ['http', 'https', 'sni'].includes(this.get('protocol'));
    }).property('protocol'),

    canPath: (function () {
      return ['http', 'https'].includes(this.get('protocol'));
    }).property('protocol'),

    canSticky: _ember['default'].computed.alias('canPath'),

    ipProtocol: (function () {
      if (this.get('protocol') === 'udp') {
        return 'udp';
      } else {
        return 'tcp';
      }
    }).property('protocol'),

    autoSetPort: (function () {
      _ember['default'].run.later(this, setTlsPort, 500);
    }).observes('protocol', 'sourcePort')
  });

  exports['default'] = PortRule;
});