define('ui/models/k8s-replicationcontroller', ['exports', 'ui/models/k8s-resource', 'ui/mixins/k8s-pod-selector'], function (exports, _uiModelsK8sResource, _uiMixinsK8sPodSelector) {

  var RC = _uiModelsK8sResource['default'].extend(_uiMixinsK8sPodSelector['default'], {
    displayReplicas: (function () {
      var want = this.get('spec.replicas');
      var have = this.get('status.replicas');
      if (want === have) {
        return have + '';
      } else {
        return have + ' of ' + want;
      }
    }).property('spec.replicas', 'status.replicas')
  });

  exports['default'] = RC;
});