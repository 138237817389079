define("ui/components/page-header-environment/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      var child0 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "revision": "Ember@2.9.1",
              "loc": {
                "source": null,
                "start": {
                  "line": 14,
                  "column": 10
                },
                "end": {
                  "line": 16,
                  "column": 10
                }
              },
              "moduleName": "ui/components/page-header-environment/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              return morphs;
            },
            statements: [["inline", "t", ["nav.environment.your"], [], ["loc", [null, [15, 12], [15, 40]]], 0, 0]],
            locals: [],
            templates: []
          };
        })();
        var child1 = (function () {
          return {
            meta: {
              "revision": "Ember@2.9.1",
              "loc": {
                "source": null,
                "start": {
                  "line": 16,
                  "column": 10
                },
                "end": {
                  "line": 18,
                  "column": 10
                }
              },
              "moduleName": "ui/components/page-header-environment/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              return morphs;
            },
            statements: [["inline", "t", ["nav.environment.all"], [], ["loc", [null, [17, 12], [17, 39]]], 0, 0]],
            locals: [],
            templates: []
          };
        })();
        var child2 = (function () {
          return {
            meta: {
              "revision": "Ember@2.9.1",
              "loc": {
                "source": null,
                "start": {
                  "line": 20,
                  "column": 8
                },
                "end": {
                  "line": 28,
                  "column": 8
                }
              },
              "moduleName": "ui/components/page-header-environment/template.hbs"
            },
            isEmpty: false,
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("          ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("li");
              var el2 = dom.createTextNode("\n            ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("a");
              dom.setAttribute(el2, "href", "#");
              dom.setAttribute(el2, "class", "clip");
              var el3 = dom.createTextNode("\n              ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("i");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n               \n              ");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n            ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n          ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element9 = dom.childAt(fragment, [1]);
              var element10 = dom.childAt(element9, [1]);
              var element11 = dom.childAt(element10, [1]);
              var morphs = new Array(4);
              morphs[0] = dom.createAttrMorph(element9, 'class');
              morphs[1] = dom.createElementMorph(element10);
              morphs[2] = dom.createAttrMorph(element11, 'class');
              morphs[3] = dom.createMorphAt(element10, 3, 3);
              return morphs;
            },
            statements: [["attribute", "class", ["concat", [["subexpr", "if", [["get", "p.active", ["loc", [null, [21, 26], [21, 34]]], 0, 0, 0, 0], "active selected"], [], ["loc", [null, [21, 21], [21, 54]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["element", "action", ["switchProject", ["get", "p.id", ["loc", [null, [22, 49], [22, 53]]], 0, 0, 0, 0]], [], ["loc", [null, [22, 24], [22, 55]]], 0, 0], ["attribute", "class", ["concat", ["icon ", ["get", "p.icon", ["loc", [null, [23, 31], [23, 37]]], 0, 0, 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["content", "p.displayName", ["loc", [null, [25, 14], [25, 31]]], 0, 0, 0, 0]],
            locals: ["p"],
            templates: []
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.9.1",
            "loc": {
              "source": null,
              "start": {
                "line": 12,
                "column": 6
              },
              "end": {
                "line": 29,
                "column": 6
              }
            },
            "moduleName": "ui/components/page-header-environment/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("li");
            dom.setAttribute(el1, "role", "presentation");
            dom.setAttribute(el1, "class", "dropdown-header");
            var el2 = dom.createTextNode("\n");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("        ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(2);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
            morphs[1] = dom.createMorphAt(fragment, 3, 3, contextualElement);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "if", [["get", "accessEnabled", ["loc", [null, [14, 16], [14, 29]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [14, 10], [18, 17]]]], ["block", "each", [["get", "projectChoices", ["loc", [null, [20, 16], [20, 30]]], 0, 0, 0, 0]], [], 2, null, ["loc", [null, [20, 8], [28, 17]]]]],
          locals: [],
          templates: [child0, child1, child2]
        };
      })();
      var child1 = (function () {
        return {
          meta: {
            "revision": "Ember@2.9.1",
            "loc": {
              "source": null,
              "start": {
                "line": 30,
                "column": 6
              },
              "end": {
                "line": 32,
                "column": 6
              }
            },
            "moduleName": "ui/components/page-header-environment/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("li");
            dom.setAttribute(el1, "role", "presentation");
            dom.setAttribute(el1, "class", "divider");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() {
            return [];
          },
          statements: [],
          locals: [],
          templates: []
        };
      })();
      var child2 = (function () {
        return {
          meta: {
            "revision": "Ember@2.9.1",
            "loc": {
              "source": null,
              "start": {
                "line": 33,
                "column": 6
              },
              "end": {
                "line": 43,
                "column": 6
              }
            },
            "moduleName": "ui/components/page-header-environment/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("li");
            dom.setAttribute(el1, "role", "presentation");
            dom.setAttribute(el1, "class", "dropdown-header");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("li");
            var el2 = dom.createTextNode("\n          ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("a");
            dom.setAttribute(el2, "href", "#");
            dom.setAttribute(el2, "class", "clip");
            var el3 = dom.createTextNode("\n            ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("i");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n             \n            ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n          ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("li");
            dom.setAttribute(el1, "role", "presentation");
            dom.setAttribute(el1, "class", "divider");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element6 = dom.childAt(fragment, [3]);
            var element7 = dom.childAt(element6, [1]);
            var element8 = dom.childAt(element7, [1]);
            var morphs = new Array(5);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
            morphs[1] = dom.createAttrMorph(element6, 'class');
            morphs[2] = dom.createElementMorph(element7);
            morphs[3] = dom.createAttrMorph(element8, 'class');
            morphs[4] = dom.createMorphAt(element7, 3, 3);
            return morphs;
          },
          statements: [["inline", "t", ["nav.environment.selected"], [], ["loc", [null, [34, 56], [34, 88]]], 0, 0], ["attribute", "class", ["concat", [["subexpr", "if", [["get", "project.active", ["loc", [null, [35, 24], [35, 38]]], 0, 0, 0, 0], "active selected"], [], ["loc", [null, [35, 19], [35, 58]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["element", "action", ["switchProject", ["get", "project.id", ["loc", [null, [36, 47], [36, 57]]], 0, 0, 0, 0]], [], ["loc", [null, [36, 22], [36, 59]]], 0, 0], ["attribute", "class", ["concat", ["icon ", ["get", "project.icon", ["loc", [null, [37, 29], [37, 41]]], 0, 0, 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["content", "project.displayName", ["loc", [null, [39, 12], [39, 35]]], 0, 0, 0, 0]],
          locals: [],
          templates: []
        };
      })();
      var child3 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "revision": "Ember@2.9.1",
              "loc": {
                "source": null,
                "start": {
                  "line": 45,
                  "column": 12
                },
                "end": {
                  "line": 45,
                  "column": 163
                }
              },
              "moduleName": "ui/components/page-header-environment/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["inline", "t", ["nav.environment.edit"], ["name", ["subexpr", "@mut", [["get", "projects.current.displayName", ["loc", [null, [45, 133], [45, 161]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [45, 101], [45, 163]]], 0, 0]],
            locals: [],
            templates: []
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.9.1",
            "loc": {
              "source": null,
              "start": {
                "line": 44,
                "column": 6
              },
              "end": {
                "line": 46,
                "column": 6
              }
            },
            "moduleName": "ui/components/page-header-environment/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("li");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
            return morphs;
          },
          statements: [["block", "link-to", ["settings.projects.detail", ["get", "projects.current.id", ["loc", [null, [45, 50], [45, 69]]], 0, 0, 0, 0], ["subexpr", "query-params", [], ["editing", "true"], ["loc", [null, [45, 70], [45, 99]]], 0, 0]], [], 0, null, ["loc", [null, [45, 12], [45, 175]]]]],
          locals: [],
          templates: [child0]
        };
      })();
      var child4 = (function () {
        return {
          meta: {
            "revision": "Ember@2.9.1",
            "loc": {
              "source": null,
              "start": {
                "line": 47,
                "column": 10
              },
              "end": {
                "line": 47,
                "column": 72
              }
            },
            "moduleName": "ui/components/page-header-environment/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["inline", "t", ["nav.environment.manage"], [], ["loc", [null, [47, 42], [47, 72]]], 0, 0]],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.9.1",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 50,
              "column": 0
            }
          },
          "moduleName": "ui/components/page-header-environment/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createElement("li");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("a");
          dom.setAttribute(el2, "href", "#");
          dom.setAttribute(el2, "id", "environment-dropdown");
          dom.setAttribute(el2, "role", "button");
          dom.setAttribute(el2, "class", "btn btn-link dropdown-toggle text-left");
          dom.setAttribute(el2, "aria-haspopup", "true");
          dom.setAttribute(el2, "aria-expanded", "false");
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("label");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          dom.setAttribute(el3, "class", "clip");
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("i");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode(" ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n      ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("i");
          dom.setAttribute(el3, "class", "icon icon-chevron-down project-chevron");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          dom.setAttribute(el3, "class", "sr-only");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("ul");
          dom.setAttribute(el2, "class", "dropdown-menu dropdown-menu-right project-menu");
          dom.setAttribute(el2, "role", "menu");
          dom.setAttribute(el2, "data-dropdown-id", "enviroment");
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("li");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element12 = dom.childAt(fragment, [0]);
          var element13 = dom.childAt(element12, [1]);
          var element14 = dom.childAt(element13, [3]);
          var element15 = dom.childAt(element14, [1]);
          var element16 = dom.childAt(element12, [3]);
          var morphs = new Array(11);
          morphs[0] = dom.createAttrMorph(element12, 'class');
          morphs[1] = dom.createAttrMorph(element13, 'aria-label');
          morphs[2] = dom.createMorphAt(dom.childAt(element13, [1]), 0, 0);
          morphs[3] = dom.createAttrMorph(element15, 'class');
          morphs[4] = dom.createMorphAt(element14, 3, 3);
          morphs[5] = dom.createMorphAt(dom.childAt(element13, [7]), 0, 0);
          morphs[6] = dom.createMorphAt(element16, 1, 1);
          morphs[7] = dom.createMorphAt(element16, 2, 2);
          morphs[8] = dom.createMorphAt(element16, 3, 3);
          morphs[9] = dom.createMorphAt(element16, 4, 4);
          morphs[10] = dom.createMorphAt(dom.childAt(element16, [6]), 0, 0);
          return morphs;
        },
        statements: [["attribute", "class", ["concat", ["btn dropdown project-btn r-p0 ", ["subexpr", "if", [["get", "hasKubernetes", ["loc", [null, [2, 48], [2, 61]]], 0, 0, 0, 0], "alt"], [], ["loc", [null, [2, 43], [2, 69]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "aria-label", ["concat", [["subexpr", "t", ["nav.environment.label"], [], ["loc", [null, [3, 158], [3, 187]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["inline", "t", ["nav.environment.label"], [], ["loc", [null, [4, 13], [4, 42]]], 0, 0], ["attribute", "class", ["concat", [["get", "project.icon", ["loc", [null, [6, 20], [6, 32]]], 0, 0, 0, 0], " project-icon icon-fw"], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["content", "project.displayName", ["loc", [null, [6, 67], [6, 90]]], 0, 0, 0, 0], ["inline", "t", ["nav.srToggleDropdown"], [], ["loc", [null, [9, 28], [9, 56]]], 0, 0], ["block", "if", [["get", "projectChoices.length", ["loc", [null, [12, 12], [12, 33]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [12, 6], [29, 13]]]], ["block", "if", [["get", "projectChoices.length", ["loc", [null, [30, 12], [30, 33]]], 0, 0, 0, 0]], [], 1, null, ["loc", [null, [30, 6], [32, 13]]]], ["block", "if", [["get", "projectIsMissing", ["loc", [null, [33, 12], [33, 28]]], 0, 0, 0, 0]], [], 2, null, ["loc", [null, [33, 6], [43, 13]]]], ["block", "if", [["subexpr", "and", [["get", "isOwner", ["loc", [null, [44, 17], [44, 24]]], 0, 0, 0, 0], ["get", "projects.current", ["loc", [null, [44, 25], [44, 41]]], 0, 0, 0, 0]], [], ["loc", [null, [44, 12], [44, 42]]], 0, 0]], [], 3, null, ["loc", [null, [44, 6], [46, 13]]]], ["block", "link-to", ["settings.projects"], [], 4, null, ["loc", [null, [47, 10], [47, 84]]]]],
        locals: [],
        templates: [child0, child1, child2, child3, child4]
      };
    })();
    var child1 = (function () {
      var child0 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "revision": "Ember@2.9.1",
              "loc": {
                "source": null,
                "start": {
                  "line": 64,
                  "column": 8
                },
                "end": {
                  "line": 72,
                  "column": 8
                }
              },
              "moduleName": "ui/components/page-header-environment/template.hbs"
            },
            isEmpty: false,
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("          ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("li");
              var el2 = dom.createTextNode("\n            ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("a");
              dom.setAttribute(el2, "href", "#");
              dom.setAttribute(el2, "class", "clip");
              var el3 = dom.createTextNode("\n              ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("i");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n               \n              ");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n            ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n          ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element0 = dom.childAt(fragment, [1]);
              var element1 = dom.childAt(element0, [1]);
              var element2 = dom.childAt(element1, [1]);
              var morphs = new Array(4);
              morphs[0] = dom.createAttrMorph(element0, 'class');
              morphs[1] = dom.createElementMorph(element1);
              morphs[2] = dom.createAttrMorph(element2, 'class');
              morphs[3] = dom.createMorphAt(element1, 3, 3);
              return morphs;
            },
            statements: [["attribute", "class", ["concat", [["subexpr", "if", [["subexpr", "eq", [["get", "ns", ["loc", [null, [65, 30], [65, 32]]], 0, 0, 0, 0], ["get", "k8s.namespace", ["loc", [null, [65, 33], [65, 46]]], 0, 0, 0, 0]], [], ["loc", [null, [65, 26], [65, 47]]], 0, 0], "active selected"], [], ["loc", [null, [65, 21], [65, 67]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["element", "action", ["switchNamespace", ["get", "ns.id", ["loc", [null, [66, 51], [66, 56]]], 0, 0, 0, 0]], [], ["loc", [null, [66, 24], [66, 58]]], 0, 0], ["attribute", "class", ["concat", [["get", "ns.icon", ["loc", [null, [67, 26], [67, 33]]], 0, 0, 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["content", "ns.displayName", ["loc", [null, [69, 14], [69, 32]]], 0, 0, 0, 0]],
            locals: ["ns"],
            templates: []
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.9.1",
            "loc": {
              "source": null,
              "start": {
                "line": 62,
                "column": 6
              },
              "end": {
                "line": 74,
                "column": 6
              }
            },
            "moduleName": "ui/components/page-header-environment/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("li");
            dom.setAttribute(el1, "role", "presentation");
            dom.setAttribute(el1, "class", "dropdown-header");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("li");
            dom.setAttribute(el1, "role", "presentation");
            dom.setAttribute(el1, "class", "divider");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(2);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
            morphs[1] = dom.createMorphAt(fragment, 3, 3, contextualElement);
            return morphs;
          },
          statements: [["inline", "t", ["pageHeaderEnvironment.namespace"], [], ["loc", [null, [63, 56], [63, 95]]], 0, 0], ["block", "each", [["get", "k8s.namespaces", ["loc", [null, [64, 16], [64, 30]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [64, 8], [72, 17]]]]],
          locals: [],
          templates: [child0]
        };
      })();
      var child1 = (function () {
        return {
          meta: {
            "revision": "Ember@2.9.1",
            "loc": {
              "source": null,
              "start": {
                "line": 75,
                "column": 10
              },
              "end": {
                "line": 75,
                "column": 71
              }
            },
            "moduleName": "ui/components/page-header-environment/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["inline", "t", ["nav.namespace.manage"], [], ["loc", [null, [75, 43], [75, 71]]], 0, 0]],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.9.1",
          "loc": {
            "source": null,
            "start": {
              "line": 51,
              "column": 0
            },
            "end": {
              "line": 78,
              "column": 0
            }
          },
          "moduleName": "ui/components/page-header-environment/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createElement("li");
          dom.setAttribute(el1, "class", "dropdown btn project-btn kub-btn r-p0");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("a");
          dom.setAttribute(el2, "role", "button");
          dom.setAttribute(el2, "class", "btn btn-link dropdown-toggle text-left");
          dom.setAttribute(el2, "href", "#");
          dom.setAttribute(el2, "aria-haspopup", "true");
          dom.setAttribute(el2, "aria-expanded", "false");
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("label");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          dom.setAttribute(el3, "class", "clip");
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("i");
          dom.setAttribute(el4, "class", "icon icon-folder-open icon-fw project-icon");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode(" ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n      ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("i");
          dom.setAttribute(el3, "class", "icon icon-chevron-down project-chevron");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          dom.setAttribute(el3, "class", "sr-only");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("ul");
          dom.setAttribute(el2, "class", "dropdown-menu dropdown-menu-right project-menu");
          dom.setAttribute(el2, "role", "menu");
          dom.setAttribute(el2, "data-dropdown-id", "namespace");
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("li");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element3 = dom.childAt(fragment, [0]);
          var element4 = dom.childAt(element3, [1]);
          var element5 = dom.childAt(element3, [3]);
          var morphs = new Array(6);
          morphs[0] = dom.createAttrMorph(element4, 'aria-label');
          morphs[1] = dom.createMorphAt(dom.childAt(element4, [1]), 0, 0);
          morphs[2] = dom.createMorphAt(dom.childAt(element4, [3]), 3, 3);
          morphs[3] = dom.createMorphAt(dom.childAt(element4, [7]), 0, 0);
          morphs[4] = dom.createMorphAt(element5, 1, 1);
          morphs[5] = dom.createMorphAt(dom.childAt(element5, [3]), 0, 0);
          return morphs;
        },
        statements: [["attribute", "aria-label", ["concat", [["subexpr", "t", ["nav.namespace.label"], [], ["loc", [null, [53, 132], [53, 159]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["inline", "t", ["nav.namespace.label"], [], ["loc", [null, [54, 13], [54, 40]]], 0, 0], ["content", "k8s.namespace.displayName", ["loc", [null, [56, 72], [56, 101]]], 0, 0, 0, 0], ["content", "nav.srToggleDropdown", ["loc", [null, [59, 28], [59, 52]]], 0, 0, 0, 0], ["block", "if", [["get", "k8s.namespaces.length", ["loc", [null, [62, 12], [62, 33]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [62, 6], [74, 13]]]], ["block", "link-to", ["k8s-tab.namespaces"], [], 1, null, ["loc", [null, [75, 10], [75, 83]]]]],
        locals: [],
        templates: [child0, child1]
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.9.1",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 79,
            "column": 0
          }
        },
        "moduleName": "ui/components/page-header-environment/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(2);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        morphs[1] = dom.createMorphAt(fragment, 1, 1, contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["block", "if", [["get", "project", ["loc", [null, [1, 7], [1, 14]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [1, 0], [50, 9]]]], ["block", "if", [["subexpr", "and", [["get", "hasKubernetes", ["loc", [null, [51, 12], [51, 25]]], 0, 0, 0, 0], ["get", "k8s.namespace", ["loc", [null, [51, 26], [51, 39]]], 0, 0, 0, 0]], [], ["loc", [null, [51, 7], [51, 40]]], 0, 0]], [], 1, null, ["loc", [null, [51, 0], [78, 9]]]]],
      locals: [],
      templates: [child0, child1]
    };
  })());
});