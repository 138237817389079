define('ui/admin-tab/settings/route', ['exports', 'ember', 'ui/utils/constants'], function (exports, _ember, _uiUtilsConstants) {
  exports['default'] = _ember['default'].Route.extend({
    settings: _ember['default'].inject.service(),

    beforeModel: function beforeModel() {
      return this.get('settings').load([_uiUtilsConstants['default'].SETTING.API_HOST, _uiUtilsConstants['default'].SETTING.CATALOG_URL, _uiUtilsConstants['default'].SETTING.TELEMETRY]);
    },

    model: function model() {
      var settings = this.get('settings');

      return _ember['default'].Object.create({
        host: settings.get(_uiUtilsConstants['default'].SETTING.API_HOST),
        catalog: settings.get(_uiUtilsConstants['default'].SETTING.CATALOG_URL),
        telemetry: settings.get(_uiUtilsConstants['default'].SETTING.TELEMETRY)
      });
    },

    resetController: function resetController(controller, isExiting /*, transition*/) {
      if (isExiting) {
        controller.set('error', null);
      }
    }
  });
});