define("ui/components/modal-feedback/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "revision": "Ember@2.9.1",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 10,
            "column": 0
          }
        },
        "moduleName": "ui/components/modal-feedback/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("h1");
        dom.setAttribute(el1, "class", "header");
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("p");
        var el2 = dom.createTextNode("(Imagine a form here...)");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "footer-actions");
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("button");
        dom.setAttribute(el2, "class", "btn btn-primary");
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("button");
        dom.setAttribute(el2, "class", "btn btn-default");
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("button");
        dom.setAttribute(el2, "class", "btn btn-default");
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [4]);
        var element1 = dom.childAt(element0, [1]);
        var element2 = dom.childAt(element0, [3]);
        var element3 = dom.childAt(element0, [5]);
        var morphs = new Array(7);
        morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]), 0, 0);
        morphs[1] = dom.createElementMorph(element1);
        morphs[2] = dom.createMorphAt(element1, 0, 0);
        morphs[3] = dom.createElementMorph(element2);
        morphs[4] = dom.createMorphAt(element2, 0, 0);
        morphs[5] = dom.createElementMorph(element3);
        morphs[6] = dom.createMorphAt(element3, 0, 0);
        return morphs;
      },
      statements: [["inline", "t", ["modalFeedback.header"], ["appName", ["subexpr", "@mut", [["get", "settings.appName", ["loc", [null, [1, 54], [1, 70]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [1, 19], [1, 72]]], 0, 0], ["element", "action", ["send"], [], ["loc", [null, [6, 8], [6, 25]]], 0, 0], ["inline", "t", ["modalFeedback.send"], [], ["loc", [null, [6, 50], [6, 76]]], 0, 0], ["element", "action", ["cancel"], [], ["loc", [null, [7, 8], [7, 27]]], 0, 0], ["inline", "t", ["modalFeedback.later"], [], ["loc", [null, [7, 52], [7, 79]]], 0, 0], ["element", "action", ["never"], [], ["loc", [null, [8, 8], [8, 26]]], 0, 0], ["inline", "t", ["modalFeedback.never"], [], ["loc", [null, [8, 51], [8, 78]]], 0, 0]],
      locals: [],
      templates: []
    };
  })());
});