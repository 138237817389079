define('ui/models/service', ['exports', 'ember-api-store/models/resource', 'ember', 'ui/utils/constants', 'ui/utils/util', 'ember-api-store/utils/denormalize'], function (exports, _emberApiStoreModelsResource, _ember, _uiUtilsConstants, _uiUtilsUtil, _emberApiStoreUtilsDenormalize) {
  exports.activeIcon = activeIcon;

  var Service = _emberApiStoreModelsResource['default'].extend({
    type: 'service',
    intl: _ember['default'].inject.service(),
    growl: _ember['default'].inject.service(),
    modalService: _ember['default'].inject.service('modal'),

    instances: (0, _emberApiStoreUtilsDenormalize.denormalizeIdArray)('instanceIds'),
    instanceCount: _ember['default'].computed.alias('instances.length'),
    stack: (0, _emberApiStoreUtilsDenormalize.denormalizeId)('stackId'),

    actions: {
      edit: function edit() {
        var type = this.get('type').toLowerCase();
        if (type === 'dnsservice') {
          this.get('modalService').toggleModal('edit-aliasservice', this);
        } else if (type === 'externalservice') {
          this.get('modalService').toggleModal('edit-externalservice', this);
        } else {
          this.get('modalService').toggleModal('edit-service', this);
        }
      },

      activate: function activate() {
        return this.doAction('activate');
      },

      deactivate: function deactivate() {
        return this.doAction('deactivate');
      },

      restart: function restart() {
        return this.doAction('restart', { rollingRestartStrategy: {} });
      },

      cancelUpgrade: function cancelUpgrade() {
        return this.doAction('cancelupgrade');
      },

      cancelRollback: function cancelRollback() {
        return this.doAction('cancelrollback');
      },

      finishUpgrade: function finishUpgrade() {
        return this.doAction('finishupgrade');
      },

      rollback: function rollback() {
        return this.doAction('rollback');
      },

      promptStop: function promptStop() {
        this.get('modalService').toggleModal('modal-confirm-deactivate', {
          originalModel: this,
          action: 'deactivate'
        });
      },

      scaleUp: function scaleUp() {
        this.incrementProperty('scale');
        this.saveScale();
      },

      scaleDown: function scaleDown() {
        if (this.get('scale') >= 1) {
          this.decrementProperty('scale');
          this.saveScale();
        }
      },

      upgrade: function upgrade() {
        var upgradeImage = arguments.length <= 0 || arguments[0] === undefined ? 'false' : arguments[0];

        var route = 'service.new';
        if ((this.get('launchConfig.kind') || '').toLowerCase() === 'virtualmachine') {
          route = 'service.new-virtualmachine';
        } else if (this.get('type').toLowerCase() === 'loadbalancerservice') {
          route = 'service.new-balancer';
        }

        this.get('application').transitionToRoute(route, { queryParams: {
            serviceId: this.get('id'),
            upgrade: true,
            upgradeImage: upgradeImage,
            stackId: this.get('stackId')
          } });
      },

      clone: function clone() {
        var route;
        switch (this.get('type').toLowerCase()) {
          case 'service':
            if ((this.get('launchConfig.kind') || '').toLowerCase() === 'virtualmachine') {
              route = 'service.new-virtualmachine';
            } else {
              route = 'service.new';
            }
            break;
          case 'dnsservice':
            route = 'service.new-alias';break;
          case 'loadbalancerservice':
            route = 'service.new-balancer';break;
          case 'externalservice':
            route = 'service.new-external';break;
          default:
            return void this.send('error', 'Unknown service type: ' + this.get('type'));
        }

        this.get('application').transitionToRoute(route, { queryParams: {
            serviceId: this.get('id'),
            stackId: this.get('stackId')
          } });
      }
    },

    scaleTimer: null,
    saveScale: function saveScale() {
      if (this.get('scaleTimer')) {
        _ember['default'].run.cancel(this.get('scaleTimer'));
      }

      var timer = _ember['default'].run.later(this, function () {
        var _this = this;

        this.save()['catch'](function (err) {
          _this.get('growl').fromError('Error updating scale', err);
        });
      }, 500);

      this.set('scaleTimer', timer);
    },

    availableActions: (function () {
      var a = this.get('actionLinks');

      var canUpgrade = !!a.upgrade && this.get('canUpgrade');
      var isK8s = this.get('isK8s');
      var isSwarm = this.get('isSwarm');
      var canHaveContainers = this.get('canHaveContainers');
      var isBalancer = this.get('isBalancer');
      var isDriver = ['networkdriverservice', 'storagedriverservice'].includes(this.get('type').toLowerCase());

      var choices = [{ label: 'action.start', icon: 'icon icon-play', action: 'activate', enabled: !!a.activate }, { label: 'action.finishUpgrade', icon: 'icon icon-success', action: 'finishUpgrade', enabled: !!a.finishupgrade }, { label: 'action.rollback', icon: 'icon icon-history', action: 'rollback', enabled: !!a.rollback }, { label: isBalancer ? 'action.upgradeOrEdit' : 'action.upgrade', icon: 'icon icon-arrow-circle-up', action: 'upgrade', enabled: canUpgrade }, { label: 'action.cancelUpgrade', icon: 'icon icon-life-ring', action: 'cancelUpgrade', enabled: !!a.cancelupgrade }, { label: 'action.cancelRollback', icon: 'icon icon-life-ring', action: 'cancelRollback', enabled: !!a.cancelrollback }, { divider: true }, { label: 'action.restart', icon: 'icon icon-refresh', action: 'restart', enabled: !!a.restart && canHaveContainers }, { label: 'action.stop', icon: 'icon icon-stop', action: 'promptStop', enabled: !!a.deactivate, altAction: 'deactivate' }, { label: 'action.remove', icon: 'icon icon-trash', action: 'promptDelete', enabled: !!a.remove, altAction: 'delete' }, { label: 'action.purge', icon: '', action: 'purge', enabled: !!a.purge }, { divider: true }, { label: 'action.viewInApi', icon: 'icon icon-external-link', action: 'goToApi', enabled: true }, { label: 'action.clone', icon: 'icon icon-copy', action: 'clone', enabled: !isK8s && !isSwarm && !isDriver }, { label: 'action.edit', icon: 'icon icon-edit', action: 'edit', enabled: !!a.update && !isK8s && !isSwarm && !isBalancer }];

      return choices;
    }).property('actionLinks.{activate,deactivate,restart,update,remove,purge,finishupgrade,cancelupgrade,rollback,cancelrollback}', 'type', 'isK8s', 'isSwarm', 'canHaveContainers', 'canUpgrade', 'isBalancer'),

    serviceLinks: null, // Used for clone
    reservedKeys: ['serviceLinks'],

    init: function init() {
      this._super();
    },

    displayStack: (function () {
      var stack = this.get('stack');
      if (stack) {
        return stack.get('displayName');
      } else {
        return '...';
      }
    }).property('stack.displayName'),

    consumedServicesWithNames: (function () {
      var store = this.get('store');
      var links = this.get('linkedServices') || {};
      var out = Object.keys(links).map(function (key) {
        var name = key;
        var pos = name.indexOf('/');
        if (pos >= 0) {
          name = name.substr(pos + 1);
        }

        return _ember['default'].Object.create({
          name: name,
          service: store.getById('service', links[key])
        });
      });

      return out.sortBy('name');
    }).property('linkedServices'),

    // Only for old Load Balancer to get ports map
    consumedServicesWithNamesAndPorts: (function () {
      var store = this.get('store');
      return store.all('serviceconsumemap').filterBy('serviceId', this.get('id')).map(function (map) {
        return _ember['default'].Object.create({
          name: map.get('name'),
          service: store.getById('service', map.get('consumedServiceId')),
          ports: map.get('ports') || []
        });
      }).filter(function (obj) {
        return obj && obj.get('service.id');
      });
    }).property('id', 'state').volatile(),

    combinedState: (function () {
      var service = this.get('state');
      var health = this.get('healthState');

      if (['active', 'updating-active'].indexOf(service) === -1) {
        // If the service isn't active, return its state
        return service;
      }

      if (health === 'healthy') {
        return service;
      } else {
        return health;
      }
    }).property('state', 'healthState'),

    isGlobalScale: (function () {
      return (this.get('launchConfig.labels') || {})[_uiUtilsConstants['default'].LABEL.SCHED_GLOBAL] + '' === 'true';
    }).property('launchConfig.labels'),

    canScale: (function () {
      if (['service', 'networkdriverservice', 'storagedriverservice', 'loadbalancerservice'].includes(this.get('type').toLowerCase())) {
        return !this.get('isGlobalScale');
      } else {
        return false;
      }
    }).property('type'),

    canHaveContainers: (function () {
      return ['service', 'networkdriverservice', 'storagedriverservice', 'loadbalancerservice', 'kubernetesservice', 'composeservice'].includes(this.get('type').toLowerCase());
    }).property('type'),

    isReal: (function () {
      return ['service', 'networkdriverservice', 'storagedriverservice', 'loadbalancerservice'].includes(this.get('type').toLowerCase());
    }).property('type'),

    hasPorts: _ember['default'].computed.alias('isReal'),
    hasImage: _ember['default'].computed.alias('isReal'),
    hasLabels: _ember['default'].computed.alias('isReal'),
    canUpgrade: _ember['default'].computed.alias('isReal'),

    isBalancer: (function () {
      return ['loadbalancerservice'].indexOf(this.get('type').toLowerCase()) >= 0;
    }).property('type'),

    canBalanceTo: (function () {
      if (this.get('type').toLowerCase() === 'externalservice' && this.get('hostname') !== null) {
        return false;
      }

      return true;
    }).property('type', 'hostname'),

    isK8s: (function () {
      return ['kubernetesservice'].indexOf(this.get('type').toLowerCase()) >= 0;
    }).property('type'),

    isSwarm: (function () {
      return ['composeservice'].indexOf(this.get('type').toLowerCase()) >= 0;
    }).property('type'),

    displayType: (function () {
      var known = ['loadbalancerservice', 'dnsservice', 'externalservice', 'kubernetesservice', 'composeservice', 'networkdriverservice', 'storagedriverservice', 'service'];

      var type = this.get('type').toLowerCase();
      if (!known.includes(type)) {
        type = 'service';
      }

      return this.get('intl').t('servicePage.type.' + type);
    }).property('type', 'intl._locale'),

    hasSidekicks: (function () {
      return this.get('secondaryLaunchConfigs.length') > 0;
    }).property('secondaryLaunchConfigs.length'),

    displayDetail: (function () {
      var translation = this.get('intl').findTranslationByKey('generic.image');
      translation = this.get('intl').formatMessage(translation);
      return ('<label>' + translation + ': </label><span>' + (this.get('launchConfig.imageUuid') || '').replace(/^docker:/, '') + '</span>').htmlSafe();
    }).property('launchConfig.imageUuid', 'intl._locale'),

    activeIcon: (function () {
      return activeIcon(this);
    }).property('type'),

    endpointsMap: (function () {
      var out = {};
      (this.get('publicEndpoints') || []).forEach(function (endpoint) {
        if (!endpoint.port) {
          // Skip nulls
          return;
        }

        if (out[endpoint.port]) {
          out[endpoint.port].push(endpoint.ipAddress);
        } else {
          out[endpoint.port] = [endpoint.ipAddress];
        }
      });

      return out;
    }).property('publicEndpoints.@each.{ipAddress,port}'),

    endpointsByPort: (function () {
      var out = [];
      var map = this.get('endpointsMap');
      Object.keys(map).forEach(function (key) {
        out.push({
          port: parseInt(key, 10),
          ipAddresses: map[key]
        });
      });

      return out;
    }).property('endpointsMap'),

    displayPorts: (function () {
      var pub = '';

      this.get('endpointsByPort').forEach(function (obj) {
        var url = _uiUtilsUtil['default'].constructUrl(false, obj.ipAddresses[0], obj.port);
        pub += '<span>' + '<a href="' + url + '" target="_blank">' + obj.port + '</a>,' + '</span> ';
      });

      // Remove last comma
      pub = pub.replace(/,([^,]*)$/, '$1');

      if (pub) {
        var out = this.get('intl').findTranslationByKey('generic.ports');
        out = this.get('intl').formatMessage(out);
        return ('<label>' + out + ': </label>' + pub).htmlSafe();
      } else {
        return '';
      }
    }).property('endpointsByPort.@each.{port,ipAddresses}', 'intl._locale'),

    memoryReservationBlurb: _ember['default'].computed('launchConfig.memoryReservation', function () {
      if (this.get('launchConfig.memoryReservation')) {
        return _uiUtilsUtil['default'].formatSi(this.get('launchConfig.memoryReservation'), 1024, 'iB', 'B');
      }
    })
  });

  function activeIcon(service) {
    var out = 'icon icon-services';
    switch (service.get('type').toLowerCase()) {
      case 'loadbalancerservice':
        out = 'icon icon-fork';break;
      case 'dnsservice':
        out = 'icon icon-compass';break;
      case 'externalservice':
        out = 'icon icon-cloud';break;
      case 'kubernetesservice':
        out = 'icon icon-kubernetes';break;
      case 'composeservice':
        out = 'icon icon-docker';break;
    }

    return out;
  }

  Service.reopenClass({
    stateMap: {
      'active': { icon: activeIcon, color: 'text-success' },
      'canceled-rollback': { icon: 'icon icon-life-ring', color: 'text-info' },
      'canceled-upgrade': { icon: 'icon icon-life-ring', color: 'text-info' },
      'canceling-rollback': { icon: 'icon icon-life-ring', color: 'text-info' },
      'canceling-upgrade': { icon: 'icon icon-life-ring', color: 'text-info' },
      'finishing-upgrade': { icon: 'icon icon-arrow-circle-up', color: 'text-info' },
      'rolling-back': { icon: 'icon icon-history', color: 'text-info' },
      'upgraded': { icon: 'icon icon-arrow-circle-up', color: 'text-info' },
      'upgrading': { icon: 'icon icon-arrow-circle-up', color: 'text-info' }
    }
  });

  exports['default'] = Service;
});