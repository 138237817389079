define('ui/authenticated/controller', ['exports', 'ember', 'ui/utils/constants'], function (exports, _ember, _uiUtilsConstants) {
  exports['default'] = _ember['default'].Controller.extend({
    application: _ember['default'].inject.controller(),
    settings: _ember['default'].inject.service(),
    prefs: _ember['default'].inject.service(),
    projects: _ember['default'].inject.service(),
    currentPath: _ember['default'].computed.alias('application.currentPath'),
    error: null,

    isPopup: _ember['default'].computed.alias('application.isPopup'),

    bootstrap: (function () {
      var _this = this;

      _ember['default'].run.schedule('afterRender', this, function () {
        var bg = _this.get('prefs.' + _uiUtilsConstants['default'].PREFS.BODY_BACKGROUND);
        if (bg) {
          $('BODY').css('background', bg);
        }
      });
    }).on('init'),

    hasCattleSystem: (function () {
      var out = false;
      (this.get('model.stacks') || []).forEach(function (stack) {
        var info = stack.get('externalIdInfo');
        if (info && _uiUtilsConstants['default'].EXTERNAL_ID.SYSTEM_KINDS.indexOf(info.kind) >= 0) {
          out = true;
        }
      });

      return out;
    }).property('model.stacks.@each.externalId'),

    hasHosts: (function () {
      return this.get('model.hosts.length') > 0;
    }).property('model.hosts.length'),

    isReady: (function () {
      return this.get('projects.isReady') && this.get('hasHosts');
    }).property('projects.isReady', 'hasHosts'),

    forceUpgrade: (function () {
      return this.get('currentPath').indexOf('authenticated.settings.projects') !== 0 && this.get('currentPath').indexOf('authenticated.admin-tab.') !== 0;
    }).property('currentPath')
  });
});