define('ui/models/stack', ['exports', 'ember', 'ember-api-store/models/resource', 'ui/utils/parse-externalid', 'ui/utils/constants', 'ui/utils/util', 'ember-api-store/utils/denormalize'], function (exports, _ember, _emberApiStoreModelsResource, _uiUtilsParseExternalid, _uiUtilsConstants, _uiUtilsUtil, _emberApiStoreUtilsDenormalize) {
  exports.activeIcon = activeIcon;
  exports.normalizeTag = normalizeTag;
  exports.tagsToArray = tagsToArray;
  exports.tagChoices = tagChoices;

  function activeIcon(stack) {
    if (stack.get('system')) {
      return 'icon icon-gear';
    } else {
      return 'icon icon-layers';
    }
  }

  function normalizeTag(name) {
    return (name || '').trim().toLowerCase();
  }

  function tagsToArray(str) {
    return (str || '').split(/\s*,\s*/).map(function (tag) {
      return normalizeTag(tag);
    }).filter(function (tag) {
      return tag.length > 0;
    });
  }

  function tagChoices(all) {
    var choices = [];
    (all || []).forEach(function (stack) {
      choices.addObjects(stack.get('tags'));
    });

    return choices;
  }

  var Stack = _emberApiStoreModelsResource['default'].extend({
    type: 'stack',
    k8s: _ember['default'].inject.service(),
    modalService: _ember['default'].inject.service('modal'),
    projectsService: _ember['default'].inject.service('projects'),

    services: (0, _emberApiStoreUtilsDenormalize.denormalizeIdArray)('serviceIds'),

    actions: {
      activateServices: function activateServices() {
        return this.doAction('activateservices');
      },

      deactivateServices: function deactivateServices() {
        return this.doAction('deactivateservices');
      },

      cancelUpgrade: function cancelUpgrade() {
        return this.doAction('cancelupgrade');
      },

      cancelRollback: function cancelRollback() {
        return this.doAction('cancelrollback');
      },

      finishUpgrade: function finishUpgrade() {
        return this.doAction('finishupgrade');
      },

      rollback: function rollback() {
        return this.doAction('rollback');
      },

      promptStop: function promptStop() {
        this.get('modalService').toggleModal('modal-confirm-deactivate', {
          originalModel: this,
          action: 'deactivateServices'
        });
      },

      addService: function addService() {
        this.get('router').transitionTo('service.new', {
          queryParams: {
            stackId: this.get('id')
          }
        });
      },

      addBalancer: function addBalancer() {
        this.get('router').transitionTo('service.new-balancer', {
          queryParams: {
            stackId: this.get('id')
          }
        });
      },

      edit: function edit() {
        this.get('modalService').toggleModal('edit-stack', this);
      },

      exportConfig: function exportConfig() {
        var url = this.get('endpointSvc').addAuthParams(this.linkFor('composeConfig'));
        (0, _uiUtilsUtil.download)(url);
      },

      viewCode: function viewCode() {
        this.get('application').transitionToRoute('stack.code', this.get('id'));
      },

      viewGraph: function viewGraph() {
        this.get('application').transitionToRoute('stack.graph', this.get('id'));
      },

      'delete': function _delete() {
        var _this = this;

        return this._super().then(function () {
          if (_this.get('application.currentRouteName') === 'stack.index') {
            _this.get('router').transitionTo('stacks');
          }
        });
      }
    },

    availableActions: (function () {
      var a = this.get('actionLinks');

      if (this.get('externalIdInfo.kind') === _uiUtilsConstants['default'].EXTERNAL_ID.KIND_KUBERNETES) {
        return [];
      }

      var out = [{ label: 'action.finishUpgrade', icon: 'icon icon-success', action: 'finishUpgrade', enabled: !!a.finishupgrade }, { label: 'action.rollback', icon: 'icon icon-history', action: 'rollback', enabled: !!a.rollback }, { label: 'action.cancelUpgrade', icon: 'icon icon-life-ring', action: 'cancelUpgrade', enabled: !!a.cancelupgrade }, { label: 'action.cancelRollback', icon: 'icon icon-life-ring', action: 'cancelRollback', enabled: !!a.cancelrollback }, { label: 'action.startServices', icon: 'icon icon-play', action: 'activateServices', enabled: this.get('canActivate') }, { label: 'action.stopServices', icon: 'icon icon-stop', action: 'promptStop', enabled: this.get('canDeactivate'), altAction: 'deactivateServices' }, { divider: true }, { label: 'action.viewGraph', icon: 'icon icon-share', action: 'viewGraph', enabled: true }, { label: 'action.viewConfig', icon: 'icon icon-files', action: 'viewCode', enabled: !!a.exportconfig }, { label: 'action.exportConfig', icon: 'icon icon-download', action: 'exportConfig', enabled: !!a.exportconfig }, { divider: true }, { label: 'action.remove', icon: 'icon icon-trash', action: 'promptDelete', enabled: !!a.remove, altAction: 'delete' }, { label: 'action.viewInApi', icon: 'icon icon-external-link', action: 'goToApi', enabled: true }, { divider: true }, { label: 'action.edit', icon: 'icon icon-edit', action: 'edit', enabled: !!a.update }];

      return out;
    }).property('actionLinks.{remove,purge,exportconfig,finishupgrade,cancelupgrade,rollback,cancelrollback,update}', 'canActivate', 'canDeactivate', 'externalIdInfo.kind'),

    canViewConfig: (function () {
      return !!this.get('actionLinks.exportconfig');
    }).property('actionLinks.exportconfig'),

    combinedState: (function () {
      var stack = this.get('state');
      var health = this.get('healthState');
      if (['active', 'updating-active'].indexOf(stack) === -1) {
        // If the stack isn't active, return its state
        return stack;
      }

      if (health === 'healthy') {
        return stack;
      } else {
        return health;
      }
    }).property('state', 'healthState'),

    canActivate: (function () {
      if (!this.hasAction('activateservices')) {
        return false;
      }

      var count = this.get('services.length') || 0;
      if (count === 0) {
        return false;
      }

      return this.get('services').filterBy('actionLinks.activate').get('length') > 0;
    }).property('services.@each.state', 'actionLinks.activateservices'),

    canDeactivate: (function () {
      if (!this.hasAction('deactivateservices')) {
        return false;
      }

      var count = this.get('services.length') || 0;
      if (count === 0) {
        return false;
      }

      return this.get('services').filterBy('actionLinks.deactivate').get('length') > 0;
    }).property('services.@each.state', 'actionLinks.deactivateservices'),

    externalIdInfo: (function () {
      return (0, _uiUtilsParseExternalid.parseExternalId)(this.get('externalId'));
    }).property('externalId'),

    grouping: (function () {
      var kind = this.get('externalIdInfo.kind');

      if (kind === _uiUtilsConstants['default'].EXTERNAL_ID.KIND_KUBERNETES || kind === _uiUtilsConstants['default'].EXTERNAL_ID.KIND_LEGACY_KUBERNETES) {
        return _uiUtilsConstants['default'].EXTERNAL_ID.KIND_KUBERNETES;
      } else if (this.get('system')) {
        return _uiUtilsConstants['default'].EXTERNAL_ID.KIND_INFRA;
      } else {
        return _uiUtilsConstants['default'].EXTERNAL_ID.KIND_USER;
      }
    }).property('externalIdInfo.kind', 'group', 'system'),

    tags: _ember['default'].computed('group', {
      get: function get() {
        return tagsToArray(this.get('group'));
      },
      set: function set(key, value) {
        this.set('group', (value || []).map(function (x) {
          return normalizeTag(x);
        }).join(', '));
        return value;
      }
    }),

    hasTags: function hasTags(want) {
      if (!want || !want.length) {
        return true;
      }

      var have = this.get('tags');
      for (var i = 0; i < want.length; i++) {
        if (!have.contains(want[i])) {
          return false;
        }
      }

      return true;
    }
  });

  Stack.reopenClass({
    stateMap: {
      'active': { icon: activeIcon, color: 'text-success' },
      'canceled-rollback': { icon: 'icon icon-life-ring', color: 'text-info' },
      'canceled-upgrade': { icon: 'icon icon-life-ring', color: 'text-info' },
      'canceling-rollback': { icon: 'icon icon-life-ring', color: 'text-info' },
      'canceling-upgrade': { icon: 'icon icon-life-ring', color: 'text-info' },
      'finishing-upgrade': { icon: 'icon icon-arrow-circle-up', color: 'text-info' },
      'rolling-back': { icon: 'icon icon-history', color: 'text-info' },
      'upgraded': { icon: 'icon icon-arrow-circle-up', color: 'text-info' },
      'upgrading': { icon: 'icon icon-arrow-circle-up', color: 'text-info' }
    }
  });

  exports['default'] = Stack;
});