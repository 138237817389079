define("ui/k8s-tab/namespace/services/service/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "revision": "Ember@2.9.1",
          "loc": {
            "source": null,
            "start": {
              "line": 2,
              "column": 6
            },
            "end": {
              "line": 2,
              "column": 101
            }
          },
          "moduleName": "ui/k8s-tab/namespace/services/service/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["inline", "t", ["k8sTab.namespace.services.service.index.header"], [], ["loc", [null, [2, 47], [2, 101]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child1 = (function () {
      return {
        meta: {
          "revision": "Ember@2.9.1",
          "loc": {
            "source": null,
            "start": {
              "line": 17,
              "column": 8
            },
            "end": {
              "line": 19,
              "column": 8
            }
          },
          "moduleName": "ui/k8s-tab/namespace/services/service/template.hbs"
        },
        isEmpty: false,
        arity: 2,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          morphs[1] = dom.createMorphAt(fragment, 2, 2, contextualElement);
          return morphs;
        },
        statements: [["inline", "if", [["subexpr", "eq", [["get", "index", ["loc", [null, [18, 19], [18, 24]]], 0, 0, 0, 0], 0], [], ["loc", [null, [18, 15], [18, 27]]], 0, 0], "", ","], [], ["loc", [null, [18, 10], [18, 36]]], 0, 0], ["content", "ip", ["loc", [null, [18, 36], [18, 42]]], 0, 0, 0, 0]],
        locals: ["ip", "index"],
        templates: []
      };
    })();
    var child2 = (function () {
      return {
        meta: {
          "revision": "Ember@2.9.1",
          "loc": {
            "source": null,
            "start": {
              "line": 19,
              "column": 8
            },
            "end": {
              "line": 21,
              "column": 8
            }
          },
          "moduleName": "ui/k8s-tab/namespace/services/service/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "t", ["generic.none"], [], ["loc", [null, [20, 10], [20, 30]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child3 = (function () {
      return {
        meta: {
          "revision": "Ember@2.9.1",
          "loc": {
            "source": null,
            "start": {
              "line": 25,
              "column": 8
            },
            "end": {
              "line": 27,
              "column": 8
            }
          },
          "moduleName": "ui/k8s-tab/namespace/services/service/template.hbs"
        },
        isEmpty: false,
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("span");
          dom.setAttribute(el1, "class", "badge badge-default");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("=");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element5 = dom.childAt(fragment, [1]);
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(element5, 0, 0);
          morphs[1] = dom.createMorphAt(element5, 2, 2);
          return morphs;
        },
        statements: [["content", "sel.label", ["loc", [null, [26, 44], [26, 57]]], 0, 0, 0, 0], ["content", "sel.value", ["loc", [null, [26, 58], [26, 71]]], 0, 0, 0, 0]],
        locals: ["sel"],
        templates: []
      };
    })();
    var child4 = (function () {
      return {
        meta: {
          "revision": "Ember@2.9.1",
          "loc": {
            "source": null,
            "start": {
              "line": 27,
              "column": 8
            },
            "end": {
              "line": 29,
              "column": 8
            }
          },
          "moduleName": "ui/k8s-tab/namespace/services/service/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "t", ["generic.none"], [], ["loc", [null, [28, 10], [28, 30]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child5 = (function () {
      return {
        meta: {
          "revision": "Ember@2.9.1",
          "loc": {
            "source": null,
            "start": {
              "line": 35,
              "column": 0
            },
            "end": {
              "line": 53,
              "column": 0
            }
          },
          "moduleName": "ui/k8s-tab/namespace/services/service/template.hbs"
        },
        isEmpty: false,
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("ul");
          dom.setAttribute(el1, "class", "nav nav-tabs nav-tabs-well");
          dom.setAttribute(el1, "role", "tablist");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("li");
          dom.setAttribute(el2, "role", "tab");
          dom.setAttribute(el2, "class", "tab");
          dom.setAttribute(el2, "data-section", "pods");
          var el3 = dom.createElement("a");
          dom.setAttribute(el3, "href", "#");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("li");
          dom.setAttribute(el2, "role", "tab");
          dom.setAttribute(el2, "class", "tab");
          dom.setAttribute(el2, "data-section", "labels");
          var el3 = dom.createElement("a");
          dom.setAttribute(el3, "href", "#");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("li");
          dom.setAttribute(el2, "role", "tab");
          dom.setAttribute(el2, "class", "tab");
          dom.setAttribute(el2, "data-section", "ports");
          var el3 = dom.createElement("a");
          dom.setAttribute(el3, "href", "#");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "horizontal-form well nav-well");
          dom.setAttribute(el1, "role", "tabpanel");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "section container-fluid");
          dom.setAttribute(el2, "data-section", "pods");
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "section container-fluid");
          dom.setAttribute(el2, "data-section", "labels");
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "section container-fluid");
          dom.setAttribute(el2, "data-section", "ports");
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1]);
          var element1 = dom.childAt(element0, [1]);
          var element2 = dom.childAt(element0, [3]);
          var element3 = dom.childAt(element0, [5]);
          var element4 = dom.childAt(fragment, [3]);
          var morphs = new Array(9);
          morphs[0] = dom.createElementMorph(element1);
          morphs[1] = dom.createMorphAt(dom.childAt(element1, [0]), 0, 0);
          morphs[2] = dom.createElementMorph(element2);
          morphs[3] = dom.createMorphAt(dom.childAt(element2, [0]), 0, 0);
          morphs[4] = dom.createElementMorph(element3);
          morphs[5] = dom.createMorphAt(dom.childAt(element3, [0]), 0, 0);
          morphs[6] = dom.createMorphAt(dom.childAt(element4, [1]), 1, 1);
          morphs[7] = dom.createMorphAt(dom.childAt(element4, [3]), 1, 1);
          morphs[8] = dom.createMorphAt(dom.childAt(element4, [5]), 1, 1);
          return morphs;
        },
        statements: [["element", "action", ["selectTab", "pods"], ["target", "component"], ["loc", [null, [37, 51], [37, 99]]], 0, 0], ["inline", "t", ["k8sTab.navTabs.pods"], [], ["loc", [null, [37, 112], [37, 139]]], 0, 0], ["element", "action", ["selectTab", "labels"], ["target", "component"], ["loc", [null, [38, 53], [38, 103]]], 0, 0], ["inline", "t", ["k8sTab.navTabs.labels"], [], ["loc", [null, [38, 116], [38, 145]]], 0, 0], ["element", "action", ["selectTab", "ports"], ["target", "component"], ["loc", [null, [39, 52], [39, 101]]], 0, 0], ["inline", "t", ["k8sTab.navTabs.ports"], [], ["loc", [null, [39, 114], [39, 142]]], 0, 0], ["inline", "k8s/pod-section", [], ["model", ["subexpr", "@mut", [["get", "model.selectedPods", ["loc", [null, [44, 30], [44, 48]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [44, 6], [44, 50]]], 0, 0], ["inline", "labels-section", [], ["model", ["subexpr", "@mut", [["get", "model", ["loc", [null, [47, 29], [47, 34]]], 0, 0, 0, 0]], [], [], 0, 0], "labelSource", ["subexpr", "@mut", [["get", "model.metadata.labels", ["loc", [null, [47, 47], [47, 68]]], 0, 0, 0, 0]], [], [], 0, 0], "showKind", false, "sortBy", "key"], ["loc", [null, [47, 6], [47, 98]]], 0, 0], ["inline", "k8s/service-ports", [], ["model", ["subexpr", "@mut", [["get", "model.spec.ports", ["loc", [null, [50, 32], [50, 48]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [50, 6], [50, 50]]], 0, 0]],
        locals: ["component"],
        templates: []
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.9.1",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 54,
            "column": 0
          }
        },
        "moduleName": "ui/k8s-tab/namespace/services/service/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("section");
        dom.setAttribute(el1, "class", "header clearfix");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h1");
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode(" ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "pull-right");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("section");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "container-multi-stat");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "container-flex bordered");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4, "class", "col-flex");
        var el5 = dom.createElement("label");
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode(" ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4, "class", "col-flex");
        var el5 = dom.createElement("label");
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode(" ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4, "class", "col-flex");
        var el5 = dom.createElement("label");
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode(" ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4, "class", "col-flex");
        var el5 = dom.createElement("label");
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode(" ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4, "class", "col-flex");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("label");
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4, "class", "col-flex");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("label");
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element6 = dom.childAt(fragment, [0]);
        var element7 = dom.childAt(element6, [1]);
        var element8 = dom.childAt(fragment, [2, 1, 1]);
        var element9 = dom.childAt(element8, [1]);
        var element10 = dom.childAt(element8, [3]);
        var element11 = dom.childAt(element8, [5]);
        var element12 = dom.childAt(element8, [7]);
        var element13 = dom.childAt(element8, [9]);
        var element14 = dom.childAt(element8, [11]);
        var morphs = new Array(16);
        morphs[0] = dom.createMorphAt(element7, 0, 0);
        morphs[1] = dom.createMorphAt(element7, 2, 2);
        morphs[2] = dom.createMorphAt(dom.childAt(element6, [3]), 1, 1);
        morphs[3] = dom.createMorphAt(dom.childAt(element9, [0]), 0, 0);
        morphs[4] = dom.createMorphAt(element9, 2, 2);
        morphs[5] = dom.createMorphAt(dom.childAt(element10, [0]), 0, 0);
        morphs[6] = dom.createMorphAt(element10, 2, 2);
        morphs[7] = dom.createMorphAt(dom.childAt(element11, [0]), 0, 0);
        morphs[8] = dom.createMorphAt(element11, 2, 2);
        morphs[9] = dom.createMorphAt(dom.childAt(element12, [0]), 0, 0);
        morphs[10] = dom.createMorphAt(element12, 2, 2);
        morphs[11] = dom.createMorphAt(dom.childAt(element13, [1]), 0, 0);
        morphs[12] = dom.createMorphAt(element13, 3, 3);
        morphs[13] = dom.createMorphAt(dom.childAt(element14, [1]), 0, 0);
        morphs[14] = dom.createMorphAt(element14, 3, 3);
        morphs[15] = dom.createMorphAt(fragment, 4, 4, contextualElement);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["block", "link-to", ["k8s-tab.namespace.services"], [], 0, null, ["loc", [null, [2, 6], [2, 113]]]], ["content", "model.displayName", ["loc", [null, [2, 114], [2, 135]]], 0, 0, 0, 0], ["inline", "action-menu", [], ["model", ["subexpr", "@mut", [["get", "model", ["loc", [null, [4, 24], [4, 29]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [4, 4], [4, 31]]], 0, 0], ["inline", "t", ["k8sTab.multistat.header.type"], [], ["loc", [null, [11, 35], [11, 71]]], 0, 0], ["content", "model.spec.type", ["loc", [null, [11, 80], [11, 99]]], 0, 0, 0, 0], ["inline", "t", ["k8sTab.multistat.header.cluster"], [], ["loc", [null, [12, 35], [12, 74]]], 0, 0], ["content", "model.spec.clusterIP", ["loc", [null, [12, 83], [12, 107]]], 0, 0, 0, 0], ["inline", "t", ["k8sTab.multistat.header.balancer"], [], ["loc", [null, [13, 35], [13, 75]]], 0, 0], ["inline", "default-str", [["get", "model.spec.loadBalancerIP", ["loc", [null, [13, 98], [13, 123]]], 0, 0, 0, 0]], ["default", "None"], ["loc", [null, [13, 84], [13, 140]]], 0, 0], ["inline", "t", ["k8sTab.multistat.header.session"], [], ["loc", [null, [14, 35], [14, 74]]], 0, 0], ["content", "model.spec.sessionAffinity", ["loc", [null, [14, 83], [14, 113]]], 0, 0, 0, 0], ["inline", "t", ["k8sTab.multistat.header.external"], [], ["loc", [null, [16, 15], [16, 55]]], 0, 0], ["block", "each", [["get", "model.spec.externalIPs", ["loc", [null, [17, 16], [17, 38]]], 0, 0, 0, 0]], [], 1, 2, ["loc", [null, [17, 8], [21, 17]]]], ["inline", "t", ["k8sTab.multistat.header.selectors"], [], ["loc", [null, [24, 15], [24, 56]]], 0, 0], ["block", "each", [["get", "model.selectorsAsArray", ["loc", [null, [25, 16], [25, 38]]], 0, 0, 0, 0]], [], 3, 4, ["loc", [null, [25, 8], [29, 17]]]], ["block", "select-tab", [], ["initialTab", "pods"], 5, null, ["loc", [null, [35, 0], [53, 15]]]]],
      locals: [],
      templates: [child0, child1, child2, child3, child4, child5]
    };
  })());
});