define('ui/k8s-tab/namespace/stacks/route', ['exports', 'ember', 'ui/utils/filtered-sorted-array-proxy', 'ui/utils/constants'], function (exports, _ember, _uiUtilsFilteredSortedArrayProxy, _uiUtilsConstants) {
  exports['default'] = _ember['default'].Route.extend({
    model: function model() {
      var ns = this.modelFor('k8s-tab.namespace');
      return this.get('store').findAll('kubernetesstack').then(function (stacks) {
        return _uiUtilsFilteredSortedArrayProxy['default'].create({
          sourceContent: stacks,
          dependentKeys: ['sourceContent.@each.namespace', 'sourceContent.@each.state'],
          filterFn: function filterFn(stack) {
            return stack.get('namespace') === ns.get('id') && !_uiUtilsConstants['default'].REMOVEDISH_STATES.includes(stack.get('state'));
          }
        });
      });
    }
  });
});