define('ui/models/template', ['exports', 'ember', 'ember-api-store/models/resource', 'ui/utils/constants'], function (exports, _ember, _emberApiStoreModelsResource, _uiUtilsConstants) {
  exports['default'] = _emberApiStoreModelsResource['default'].extend({
    projects: _ember['default'].inject.service(),
    settings: _ember['default'].inject.service(),
    intl: _ember['default'].inject.service(),

    cleanProjectUrl: _ember['default'].computed('links.project', function () {
      var projectUrl = this.get('links.project');
      var pattern = new RegExp('^([a-z]+://|//)', 'i');

      if (projectUrl) {
        if (!pattern.test(projectUrl)) {
          projectUrl = 'http://' + projectUrl;
        }
      }

      return _ember['default'].String.htmlSafe(projectUrl);
    }),

    defaultName: _ember['default'].computed('id', 'templateBase', function () {
      var name = this.get('id');
      var base = this.get('templateBase');

      name = name.replace(/^[^:\/]+[:\/]/, ''); // Strip the "catalog-name:"
      if (base) {
        var idx = name.indexOf(base);
        if (idx === 0) {
          name = name.substr(base.length + 1); // Strip the "template-base*"
        }
      }

      // Strip anything else invalid
      name = name.replace(/[^a-z0-9-]+/ig, '');

      if (name === 'k8s') {
        name = 'kubernetes';
      }

      return name;
    }),

    machineHasIcon: _ember['default'].computed('templateBase', function () {
      if (this.get('templateBase') === 'machine') {
        if (this.get('links.icon')) {
          return this.get('links.icon');
        }
      }
      return false;
    }),

    supportsOrchestration: function supportsOrchestration(orch) {
      orch = orch.replace(/.*\*/, '');
      if (orch === 'k8s') {
        orch = 'kubernetes';
      }
      var list = ((this.get('labels') || {})[_uiUtilsConstants['default'].LABEL.ORCHESTRATION_SUPPORTED] || '').split(/\s*,\s*/).filter(function (x) {
        return x.length > 0;
      });
      return list.length === 0 || list.includes(orch);
    },

    supported: (function () {
      var orch = this.get('projects.current.orchestration') || 'cattle';
      if ((this.get('category') || '').toLowerCase() === 'orchestration') {
        return orch === 'cattle';
      } else {
        return this.supportsOrchestration(orch);
      }
    }).property('labels', 'projects.current.orchestration'),

    certifiedType: (function () {
      var str = null;
      var labels = this.get('labels');
      if (labels && labels[_uiUtilsConstants['default'].LABEL.CERTIFIED]) {
        str = labels[_uiUtilsConstants['default'].LABEL.CERTIFIED];
      }

      if (str === _uiUtilsConstants['default'].LABEL.CERTIFIED_RANCHER && this.get('catalogId') === _uiUtilsConstants['default'].CATALOG.LIBRARY_KEY) {
        return 'rancher';
      } else if (str === _uiUtilsConstants['default'].LABEL.CERTIFIED_PARTNER) {
        return 'partner';
      } else {
        return 'thirdparty';
      }
    }).property('catalogId'),

    certifiedClass: (function () {
      return 'badge-' + this.get('certifiedType');
    }).property('certifiedType'),

    certified: (function () {
      var out = null;
      var labels = this.get('labels');
      if (labels && labels[_uiUtilsConstants['default'].LABEL.CERTIFIED]) {
        out = labels[_uiUtilsConstants['default'].LABEL.CERTIFIED];
      }

      var looksLikeCertified = false;
      if (out) {
        var display = this.get('intl').t('catalogPage.index.certified.rancher.rancher');
        looksLikeCertified = normalize(out) === normalize(display);
      }

      if (this.get('catalogId') !== _uiUtilsConstants['default'].CATALOG.LIBRARY_KEY && (out === _uiUtilsConstants['default'].LABEL.CERTIFIED_RANCHER || looksLikeCertified)) {
        // Rancher-certified things can only be in the library catalog.
        out = null;
      }

      // For the standard labels, use translations
      if ([_uiUtilsConstants['default'].LABEL.CERTIFIED_RANCHER, _uiUtilsConstants['default'].LABEL.CERTIFIED_PARTNER].includes(out)) {
        var pl = 'pl';
        if (this.get('settings.isRancher')) {
          pl = 'rancher';
        }
        return this.get('intl').t('catalogPage.index.certified.' + pl + '.' + out);
      }

      // For custom strings, use what they said.
      return out;
    }).property('certifiedType', 'catalogId', 'labels')
  });

  function normalize(str) {
    return (str || '').replace(/[^a-z]/gi, '').toLowerCase();
  }
});