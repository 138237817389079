define('ui/settings/projects/detail/route', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    queryParams: {
      editing: {
        refreshModel: true
      }
    },

    model: function model(params /* , transition*/) {
      var userStore = this.get('userStore');
      return userStore.findAll('project').then(function (all) {
        return userStore.find('project', params.project_id).then(function (project) {
          return _ember['default'].RSVP.hash({
            importProject: project.importLink('projectMembers')
          }).then(function () /*hash*/{
            var out = _ember['default'].Object.create({
              all: all
            });

            if (params.editing) {
              out.setProperties({
                originalProject: project,
                project: project.clone()
              });
            } else {
              out.setProperties({
                originalProject: null,
                project: project
              });
            }

            return out;
          });
        });
      });
    }
  });
});