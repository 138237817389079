define('ui/models/projecttemplate', ['exports', 'ember', 'ember-api-store/models/resource', 'ui/mixins/cattle-polled-resource'], function (exports, _ember, _emberApiStoreModelsResource, _uiMixinsCattlePolledResource) {

  var ProjectTemplate = _emberApiStoreModelsResource['default'].extend(_uiMixinsCattlePolledResource['default'], {
    access: _ember['default'].inject.service(),

    actions: {
      edit: function edit() {
        this.get('router').transitionTo('settings.projects.edit-template', this.get('id'));
      }
    },

    displayStacks: (function () {
      return (this.get('stacks') || []).map(function (s) {
        return s.get('name');
      }).join(', ');
    }).property('stacks.@each.name'),

    canEdit: (function () {
      return !this.get('isPublic') || this.get('access.admin');
    }).property('access.admin', 'isPublic'),

    availableActions: (function () {
      var choices = [{ label: 'action.edit', icon: 'icon icon-edit', action: 'edit', enabled: this.get('canEdit') },
      //      { label: 'action.clone',            icon: 'icon icon-copy',         action: 'clone',        enabled: true},
      { divider: true }, { label: 'action.remove', icon: 'icon icon-trash', action: 'promptDelete', enabled: this.get('canEdit'), altAction: 'delete' }, { label: 'action.viewInApi', icon: 'icon icon-external-link', action: 'goToApi', enabled: true }];

      return choices;
    }).property('canEdit'),

    icon: 'icon icon-file',

    summary: (function () {
      var map = {
        'Orchestration': ''
      };

      this.get('stacks').forEach(function (stack) {
        var category = stack.get('category') || 'Unknown';
        if (!map[category]) {
          map[category] = '';
        }

        var tpl = stack.get('catalogTemplate');
        var name = undefined;
        if (tpl) {
          name = tpl.get('name');
        } else {
          name = stack.get('name');
        }

        map[category] += (map[category] ? ', ' : '') + name;
      });

      if (!map['Orchestration']) {
        map['Orchestration'] = 'Cattle';
      }

      // Sort the keys by map
      Object.keys(map).sort().forEach(function (key) {
        if (key === 'Orchestration') {
          return;
        }

        var tmp = map[key];
        delete map[key];
        map[key] = tmp;
      });

      return map;
    }).property('stacks.[]'),

    orchestrationIcon: (function () {
      var orch = this.get('stacks').findBy('catalogTemplate.category', 'Orchestration');
      if (orch) {
        return orch.get('icon');
      } else {
        return this.get('app.baseAssets') + 'assets/images/logos/provider-orchestration.svg';
      }
    }).property('stacks.[]')
  });

  // Projects don't get pushed by /subscribe WS, so refresh more often
  ProjectTemplate.reopenClass({
    pollTransitioningDelay: 1000,
    pollTransitioningInterval: 5000
  });

  exports['default'] = ProjectTemplate;
});