define('ui/components/k8s/replicaset-row/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    k8s: _ember['default'].inject.service(),

    model: null,
    expanded: false,

    tagName: '',

    actions: {
      toggleExpand: function toggleExpand() {
        this.toggleProperty('expanded');
      }
    }

  });
});