define('ui/service/new/controller', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    queryParams: ['stackId', 'serviceId', 'containerId', 'upgrade'],
    stackId: null,
    serviceId: null,
    containerId: null,
    upgrade: null,

    actions: {
      done: function done() {
        return this.transitionToRoute('stack', this.get('model.service.stackId'));
      },

      cancel: function cancel() {
        this.send('goToPrevious');
      }
    }
  });
});