define('ui/models/k8s-pod', ['exports', 'ember', 'ui/models/k8s-resource', 'ui/services/k8s'], function (exports, _ember, _uiModelsK8sResource, _uiServicesK8s) {

  var Pod = _uiModelsK8sResource['default'].extend({
    k8s: _ember['default'].inject.service(),

    displayContainerStatus: (function () {
      var ready = 0,
          total = 0;
      (this.get('status.containerStatuses') || []).forEach(function (container) {
        total++;
        if (container.ready) {
          ready++;
        }
      });

      if (total === 0) {
        return 'None';
      } else if (ready === total) {
        return total + ' Ready';
      } else {
        return ready + ' of ' + total + ' Ready';
      }
    }).property('status.containerStatuses.@each.ready'),

    displayContainers: (function () {
      var byDockerId = this.get('k8s.containersByDockerId');

      return (this.get('status.containerStatuses') || []).map(function (container) {
        return _ember['default'].Object.create({
          name: container.name,
          displayState: (0, _uiServicesK8s.containerStateInator)(container.state),
          ready: container.ready,
          restartCount: container.restartCount,
          image: container.image,
          container: byDockerId[(container.containerID || '').replace("docker://", "")]
        });
      });
    }).property('status.containerStatuses.@each.{state,ready,restartCount,image,name,containerID', 'k8s.containersByDockerId.[]')
  });

  exports['default'] = Pod;
});