define('ui/models/k8s-namespace', ['exports', 'ember', 'ui/models/k8s-resource', 'ui/utils/constants'], function (exports, _ember, _uiModelsK8sResource, _uiUtilsConstants) {

  var Namespace = _uiModelsK8sResource['default'].extend({
    k8s: _ember['default'].inject.service(),
    'tab-session': _ember['default'].inject.service(),

    icon: (function () {
      if (this.get('active')) {
        return 'icon icon-folder-open';
      } else {
        return 'icon icon-folder';
      }
    }).property('active'),

    active: (function () {
      return this.get('id') === this.get('tab-session.' + _uiUtilsConstants['default'].TABSESSION.NAMESPACE);
    }).property('tab-session.' + _uiUtilsConstants['default'].TABSESSION.PROJECT, 'id'),

    actions: {
      switchTo: function switchTo() {
        // @TODO bad
        window.lc('authenticated').send('switchNamespace', this.get('id'));
      }
    },

    availableActions: (function () {
      var choices = this._super();
      choices.unshift({ divider: true });
      choices.unshift({ label: 'action.switchNamespace', icon: 'icon icon-folder-open', action: 'switchTo', enabled: this.get('canSwitchTo') });
      return choices;
    }).property('canSwitchTo'),

    canSwitchTo: (function () {
      return this.get('id') !== this.get('k8s.namespace.id');
    }).property('id', 'k8s.namespace.id')
  });

  exports['default'] = Namespace;
});