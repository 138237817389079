define('ui/models/catalogtemplate', ['exports', 'ember', 'ember-api-store/models/resource', 'ui/utils/parse-externalid', 'ui/utils/constants'], function (exports, _ember, _emberApiStoreModelsResource, _uiUtilsParseExternalid, _uiUtilsConstants) {
  exports['default'] = _emberApiStoreModelsResource['default'].extend({
    catalog: _ember['default'].inject.service(),

    type: 'catalogTemplate',

    externalId: (function () {
      var id = this.get('templateVersionId') || this.get('templateId');
      if (id) {
        return _uiUtilsConstants['default'].EXTERNAL_ID.KIND_CATALOG + _uiUtilsConstants['default'].EXTERNAL_ID.KIND_SEPARATOR + id;
      }
    }).property('templateVersionId', 'templateId'),

    externalIdInfo: (function () {
      return (0, _uiUtilsParseExternalid.parseExternalId)(this.get('externalId'));
    }).property('externalId'),

    // These only works if the templates have already been loaded elsewhere...
    catalogTemplate: (function () {
      return this.get('catalog').getTemplateFromCache(this.get('externalIdInfo.templateId'));
    }).property('externalIdInfo.templateId'),

    icon: (function () {
      var tpl = this.get('catalogTemplate');
      if (tpl) {
        return tpl.linkFor('icon');
      }
    }).property('catalogTemplate'),

    category: (function () {
      var tpl = this.get('catalogTemplate');
      if (tpl) {
        return tpl.get('category');
      }
    }).property('catalogTemplate')
  });
});