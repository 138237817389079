define("ui/components/modal-welcome/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "revision": "Ember@2.9.1",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 13,
            "column": 6
          }
        },
        "moduleName": "ui/components/modal-welcome/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "r-p40 welcome-copy");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h1");
        dom.setAttribute(el2, "class", "header");
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "form-label checkbox");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("label");
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode(" ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode(" ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("a");
        dom.setAttribute(el3, "target", "_blank");
        dom.setAttribute(el3, "rel", "noopener nofollow");
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "footer-actions");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("button");
        dom.setAttribute(el3, "class", "btn btn-primary");
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0]);
        var element1 = dom.childAt(element0, [5]);
        var element2 = dom.childAt(element1, [1]);
        var element3 = dom.childAt(element1, [3]);
        var element4 = dom.childAt(element0, [7, 1]);
        var morphs = new Array(8);
        morphs[0] = dom.createMorphAt(dom.childAt(element0, [1]), 0, 0);
        morphs[1] = dom.createMorphAt(dom.childAt(element0, [3]), 0, 0);
        morphs[2] = dom.createMorphAt(element2, 0, 0);
        morphs[3] = dom.createMorphAt(element2, 2, 2);
        morphs[4] = dom.createAttrMorph(element3, 'href');
        morphs[5] = dom.createMorphAt(element3, 0, 0);
        morphs[6] = dom.createElementMorph(element4);
        morphs[7] = dom.createMorphAt(element4, 0, 0);
        return morphs;
      },
      statements: [["inline", "t", ["modalWelcome.header"], ["appName", ["subexpr", "@mut", [["get", "settings.appName", ["loc", [null, [2, 55], [2, 71]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [2, 21], [2, 73]]], 0, 0], ["inline", "format-html-message", ["telemetryOpt.subtext"], ["appName", ["subexpr", "@mut", [["get", "settings.appName", ["loc", [null, [4, 58], [4, 74]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [4, 5], [4, 76]]], 0, 0], ["inline", "input", [], ["type", "checkbox", "checked", ["subexpr", "@mut", [["get", "optIn", ["loc", [null, [7, 43], [7, 48]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [7, 11], [7, 50]]], 0, 0], ["inline", "t", ["telemetryOpt.label"], [], ["loc", [null, [7, 51], [7, 77]]], 0, 0], ["attribute", "href", ["concat", [["subexpr", "t", ["telemetryOpt.learnMore.link"], [], ["loc", [null, [7, 95], [7, 130]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["inline", "t", ["telemetryOpt.learnMore.label"], [], ["loc", [null, [7, 172], [7, 208]]], 0, 0], ["element", "action", ["cancel"], [], ["loc", [null, [11, 12], [11, 31]]], 0, 0], ["inline", "t", ["modalWelcome.closeModal"], [], ["loc", [null, [11, 56], [11, 87]]], 0, 0]],
      locals: [],
      templates: []
    };
  })());
});