define('ui/catalog-tab/index/controller', ['exports', 'ember', 'ui/utils/platform'], function (exports, _ember, _uiUtilsPlatform) {
  exports['default'] = _ember['default'].Controller.extend({
    application: _ember['default'].inject.controller(),
    catalog: _ember['default'].inject.service(),
    settings: _ember['default'].inject.service(),

    catalogController: _ember['default'].inject.controller('catalog-tab'),
    category: _ember['default'].computed.alias('catalogController.category'),
    categories: _ember['default'].computed.alias('model.categories'),
    catalogId: _ember['default'].computed.alias('catalogController.catalogId'),

    parentRoute: 'catalog-tab',
    launchRoute: 'catalog-tab.launch',

    search: '',

    updating: 'no',

    actions: {
      launch: function launch(id, onlyAlternate) {
        if (onlyAlternate && !(0, _uiUtilsPlatform.isAlternate)(event)) {
          return false;
        }

        this.transitionToRoute(this.get('launchRoute'), id);
      },

      update: function update() {
        var _this = this;

        this.set('updating', 'yes');
        this.get('catalog').refresh().then(function () {
          _this.set('updating', 'no');
          _this.send('refresh');
        })['catch'](function () {
          _this.set('updating', 'error');
        });
      }
    },

    init: function init() {},

    arrangedContent: _ember['default'].computed('model.catalog', 'search', function () {
      var search = this.get('search').toUpperCase();
      var result = [];

      if (!search) {
        return this.get('model.catalog');
      }

      this.get('model.catalog').forEach(function (item) {
        if (item.name.toUpperCase().indexOf(search) >= 0 || item.description.toUpperCase().indexOf(search) >= 0) {
          result.push(item);
        }
      });
      return result;
    })
  });
});