define('ui/components/new-receiver/component', ['exports', 'ember', 'ui/mixins/new-or-edit'], function (exports, _ember, _uiMixinsNewOrEdit) {
  exports['default'] = _ember['default'].Component.extend(_uiMixinsNewOrEdit['default'], {
    actions: {
      cancel: function cancel() {
        this.sendAction('cancel');
      }
    },

    doSave: function doSave(opt) {
      opt = opt || {};
      if (!this.get('primaryResource.id')) {
        opt.url = 'receivers';
      }

      return this._super(opt);
    },

    doneSaving: function doneSaving() {
      this.send('cancel');
    }
  });
});