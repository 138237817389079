define('ui/models/k8s-resource', ['exports', 'ember', 'ember-api-store/models/resource', 'ui/utils/constants', 'ember-api-store/utils/normalize'], function (exports, _ember, _emberApiStoreModelsResource, _uiUtilsConstants, _emberApiStoreUtilsNormalize) {

  var K8sResource = _emberApiStoreModelsResource['default'].extend({
    endpointSvc: _ember['default'].inject.service('endpoint'),
    k8s: _ember['default'].inject.service(),

    actions: {
      edit: function edit() {
        this.get('router').transitionTo('k8s-tab.apply', {
          queryParams: {
            name: this.metadata.name,
            kind: this.get('kind')
          }
        });
      },

      goToApi: function goToApi() {
        var url = this.linkFor('self').replace(/^\//, '');
        window.open(url, '_blank');
      }
    },

    linkFor: function linkFor(name) {
      var url = this.get('metadata.' + name + 'Link');
      if (url) {
        url = this.get('app.kubernetesEndpoint').replace(this.get('app.projectToken'), this.get('tab-session.' + _uiUtilsConstants['default'].SESSION.PROJECT)) + url;
      }
      return url;
    },

    type: (function () {
      return (0, _emberApiStoreUtilsNormalize.normalizeType)('' + _uiUtilsConstants['default'].K8S.TYPE_PREFIX + this.get('kind'));
    }).property('kind'),

    hasLabel: function hasLabel(key) {
      var want = arguments.length <= 1 || arguments[1] === undefined ? undefined : arguments[1];

      var labels = this.get('metadata.labels') || {};
      var have = labels[key];

      // The key doesn't exist
      if (have === undefined) {
        return false;
      }

      // Just checking if the key exists
      if (want === undefined) {
        return true;
      }

      // Really matches
      if (have === want) {
        return true;
      }

      // Sorta matches
      return have && want && have + "" === want + "";
    },

    hasAnnotation: function hasAnnotation(key) {
      var want = arguments.length <= 1 || arguments[1] === undefined ? undefined : arguments[1];

      var annotations = this.get('metadata.annotations') || {};
      var have = annotations[key];

      // The key doesn't exist
      if (have === undefined) {
        return false;
      }

      // Just checking if the key exists
      if (want === undefined) {
        return true;
      }

      // Really matches
      if (have === want) {
        return true;
      }

      // Sorta matches
      return have && want && have + "" === want + "";
    },

    'delete': function _delete() /*arguments*/{
      var _this = this;

      //var store = this.get('store');
      var type = this.get('type');
      var name = this.get('metadata.name');

      var promise;
      if (this.get('k8s.supportsStacks')) {
        promise = this.get('k8s').remove(type.replace(_uiUtilsConstants['default'].K8S.TYPE_PREFIX, ''), name);
      } else {
        promise = this.request({
          method: 'DELETE',
          url: this.linkFor('self')
        });
      }

      promise.then(function (newData) {
        //store._remove(type, this);
        return newData;
      })['catch'](function (err) {
        _this.get('growl').fromError('Error deleting', err);
      });
    },

    availableActions: (function () {
      var choices = [{ label: 'action.edit', icon: 'icon icon-edit', action: 'edit', enabled: true }, { label: 'action.viewInApi', icon: 'icon icon-external-link', action: 'goToApi', enabled: true }, { divider: true }, { label: 'action.remove', icon: 'icon icon-trash', action: 'promptDelete', enabled: true, altAction: 'delete' }];

      return choices;
    }).property(),

    displayName: (function () {
      return this.get('metadata.name') || '(' + this.get('id') + ')';
    }).property('metadata.name', 'id')
  });

  exports['default'] = K8sResource;
});