define('ui/admin-tab/processes/process/route', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    params: null,
    model: function model(params /*, transition*/) {
      this.set('params', params);
      return this.get('userStore').find('processinstance', params.process_id).then(function (processInstance) {
        return processInstance.followLink('processExecutions').then(function (processExecutions) {
          var sorted = processExecutions.get('content').reverse();
          processExecutions.set('content', sorted);
          return _ember['default'].Object.create({
            processInstance: processInstance,
            processExecutions: processExecutions
          });
        }, function () /*reject*/{
          //do some errors
        });
      });
    }
  });
});