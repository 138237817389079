define('ui/services/k8s', ['exports', 'ember', 'ember-api-store/utils/normalize', 'ember-api-store/utils/apply-headers', 'ember-api-store/models/error', 'ui/utils/constants', 'ui/utils/util'], function (exports, _ember, _emberApiStoreUtilsNormalize, _emberApiStoreUtilsApplyHeaders, _emberApiStoreModelsError, _uiUtilsConstants, _uiUtilsUtil) {
  exports.containerStateInator = containerStateInator;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var getOwner = _ember['default'].getOwner;

  function splitId(objOrStr, defaultNs) {
    var id = typeof objOrStr === 'object' ? objOrStr.get('id') : objOrStr || '';
    var idx = id.indexOf(_uiUtilsConstants['default'].K8S.ID_SEPARATOR);
    if (idx >= 0) {
      return {
        namespace: id.substr(0, idx),
        name: id.substr(idx + _uiUtilsConstants['default'].K8S.ID_SEPARATOR.length)
      };
    } else {
      return {
        namespace: defaultNs || null,
        name: id
      };
    }
  }

  function joinId(objOrStr, defaultNs) {
    var parts = splitId(objOrStr);
    return parts.namespace || defaultNs + _uiUtilsConstants['default'].K8S.ID_SEPARATOR + parts.name;
  }

  function containerStateInator(state) {
    var label = '???';
    var message = '';
    var date = null;
    var datePrefix = '';

    if (state.running) {
      label = 'Running';
      if (state.running.startedAt) {
        date = new Date(state.running.startedAt);
        datePrefix = 'Since ';
      }
    } else if (state.waiting) {
      label = 'Waiting';
      if (state.waiting.message) {
        message = state.waiting.message;
      }
    } else if (state.terminated) {
      label = 'Terminated (' + state.terminated.exitCode + ')';

      if (state.terminated.message) {
        message = state.terminated.message;
      }

      if (state.terminated.finishedAt) {
        date = new Date(state.terminated.finishedAt);
      }
    }

    return {
      state: label,
      message: message,
      date: date,
      datePrefix: datePrefix
    };
  }

  exports['default'] = _ember['default'].Service.extend({
    'tab-session': _ember['default'].inject.service(),
    cookies: _ember['default'].inject.service(),
    store: _ember['default'].inject.service('store'),

    loadingErrors: null,
    version: null,
    namespaces: null,
    services: null,
    rcs: null,
    pods: null,
    containers: null,
    deployments: null,
    replicasets: null,
    hosts: null,

    // The current namespace
    namespace: null,

    kubernetesEndpoint: (function () {
      return this.get('app.kubernetesEndpoint').replace(this.get('app.projectToken'), this.get('tab-session.' + _uiUtilsConstants['default'].TABSESSION.PROJECT));
    }).property('tab-session.' + _uiUtilsConstants['default'].TABSESSION.PROJECT, 'app.kubernetesEndpoint'),

    kubectlEndpoint: (function () {
      return this.get('app.kubectlEndpoint').replace(this.get('app.projectToken'), this.get('tab-session.' + _uiUtilsConstants['default'].TABSESSION.PROJECT));
    }).property('tab-session.' + _uiUtilsConstants['default'].TABSESSION.PROJECT, 'app.kubectlEndpoint'),

    kubernetesDashboard: (function () {
      return this.get('app.kubernetesDashboard').replace(this.get('app.projectToken'), this.get('tab-session.' + _uiUtilsConstants['default'].TABSESSION.PROJECT));
    }).property('tab-session.' + _uiUtilsConstants['default'].TABSESSION.PROJECT, 'app.kubernetesDashboard'),

    clusterIp: (function () {
      return this.get('hosts.firstObject.displayIp');
    }).property('hosts.@each.displayIp'),

    promiseQueue: null,
    init: function init() {
      var _this = this;

      this._super();
      this.get('store.metaKeys').addObject('metadata');
      this.set('promiseQueue', {});

      this.set('hosts', []);
      this.get('store').findAll('host').then(function (hosts) {
        _this.set('hosts', hosts);
      });
    },

    // request({ options});
    // or request(method, path[, body][, options map])
    request: function request(methodOrOpt, path, data) {
      var moreOpt = arguments.length <= 3 || arguments[3] === undefined ? {} : arguments[3];

      var self = this;
      var store = this.get('store');
      var opt;

      if (typeof methodOrOpt === 'object') {
        opt = methodOrOpt;
      } else {
        opt = moreOpt || {};
        opt.method = methodOrOpt;
        opt.url = path;
        opt.data = data;
      }

      var promise = new _ember['default'].RSVP.Promise(function (resolve, reject) {
        store.rawRequest(opt).then(success, fail);

        function success(obj) {
          if ((obj.headers.get('content-type') || '').toLowerCase().indexOf('/json') !== -1) {
            var response = self._typeify(obj.body);
            resolve(response);
          } else {
            resolve(obj.body);
          }
        }

        function fail(obj) {
          var response, body;

          if ((obj.headers.get('content-type') || '').toLowerCase().indexOf('/json') !== -1) {
            body = self._typeify(obj.body);
            body.status = body.code;
            body.code = body.reason;
            delete body.reason;
          } else {
            body = { status: obj.status, message: obj.body };
          }

          if (_emberApiStoreModelsError['default'].detectInstance(body)) {
            response = body;
          } else {
            response = _emberApiStoreModelsError['default'].create(body);
          }

          reject(response);
        }
      }, 'Request: ' + opt.url);

      return promise;
    },

    _typeify: function _typeify(obj) {
      var self = this;
      var store = this.get('store');
      var output, type;

      // Collection
      if (obj.kind && obj.kind.slice(-4) === 'List' && obj.items) {
        var itemKind = obj.kind.slice(0, -4);
        type = (0, _emberApiStoreUtilsNormalize.normalizeType)('' + _uiUtilsConstants['default'].K8S.TYPE_PREFIX + itemKind);
        obj.items = obj.items.map(function (item) {
          item.kind = itemKind;
          item.apiVersion = obj.apiVersion;
          return typeifyResource(item, type);
        });

        output = store.createCollection(obj, { key: 'items' });
      } else {
        // Record
        type = (0, _emberApiStoreUtilsNormalize.normalizeType)('' + _uiUtilsConstants['default'].K8S.TYPE_PREFIX + obj.kind);
        output = typeifyResource(obj, type);
      }

      return output;

      function typeifyResource(obj, type) {
        if (obj && obj.metadata) {
          if (obj.metadata.namespace && obj.metadata.name) {
            obj.id = '' + obj.metadata.namespace + _uiUtilsConstants['default'].K8S.ID_SEPARATOR + obj.metadata.name;
          } else if (obj.metadata.name) {
            obj.id = obj.metadata.name;
          }
        }

        var output = store.createRecord(obj, { type: type });
        if (output && output.metadata && output.metadata.uid) {
          var cacheEntry = self.getByUid(type, output.metadata.uid);
          if (cacheEntry) {
            cacheEntry.replaceWith(output);
            return cacheEntry;
          } else {
            store._add(type, output);
            return output;
          }
        } else {
          return output;
        }
      }
    },

    isCacheable: function isCacheable(opt) {
      if (opt.filter) {
        return false;
      }

      // Un-namespaced things are cacheable
      var str = _uiUtilsConstants['default'].K8S.BASE_VERSION + '/namespaces/';
      var pos = opt.url.indexOf(str);
      if (pos >= 0) {
        var rest = opt.url.substr(pos + str.length);
        return rest.indexOf('/') === -1;
      } else {
        return true;
      }
    },

    _find: function _find(type, id, opt) {
      var self = this;
      var store = this.get('store');
      type = (0, _emberApiStoreUtilsNormalize.normalizeType)(type);
      opt = opt || {};

      if (!type) {
        return _ember['default'].RSVP.reject(new _emberApiStoreModelsError['default']('type not specified'));
      }

      // If this is a request for all of the items of [type], then we'll remember that and not ask again for a subsequent request
      var isCacheable = this.isCacheable(opt);
      var isForAll = !id && isCacheable;

      // See if we already have this resource, unless forceReload is on.
      if (opt.forceReload !== true) {
        if (isForAll && store.get('_state.foundAll')[type]) {
          return _ember['default'].RSVP.resolve(store.all(type), 'Cached find all ' + type);
        } else if (isCacheable && id) {
          var existing;
          if (opt.useUid) {
            existing = self.getByUid(type, id);
          } else {
            existing = store.getById(type, id);
          }

          if (existing) {
            return _ember['default'].RSVP.resolve(existing, 'Cached find ' + type + ':' + id);
          }
        }
      }

      if (opt.url) {
        if (opt.url.substr(0, 1) !== '/') {
          var version = undefined;
          if (_uiUtilsConstants['default'].K8S.EXTENSION_TYPES.indexOf(type) >= 0) {
            version = _uiUtilsConstants['default'].K8S.EXTENSION_VERSION;
          } else {
            version = _uiUtilsConstants['default'].K8S.BASE_VERSION;
          }

          opt.url = self.get('kubernetesEndpoint') + '/' + version + '/' + opt.url;
        }

        return findWithUrl(opt.url);
      } else {
        return _ember['default'].RSVP.reject(new _emberApiStoreModelsError['default']('k8s find requires opt.url'));
      }

      function findWithUrl(url) {
        var queue = self.get('promiseQueue');
        var cls = getOwner(self).lookup('model:' + type);

        if (opt.filter) {
          var keys = Object.keys(opt.filter);
          keys.forEach(function (key) {
            var vals = opt.filter[key];
            if (!_ember['default'].isArray(vals)) {
              vals = [vals];
            }

            vals.forEach(function (val) {
              url += (url.indexOf('?') >= 0 ? '&' : '?') + encodeURIComponent(key) + '=' + encodeURIComponent(val);
            });
          });
        }

        // Headers
        var newHeaders = {};
        if (cls && cls.constructor.headers) {
          (0, _emberApiStoreUtilsApplyHeaders.applyHeaders)(cls.constructor.headers, newHeaders, true);
        }
        (0, _emberApiStoreUtilsApplyHeaders.applyHeaders)(opt.headers, newHeaders, true);

        var later;

        // check to see if the request is in the promiseQueue (promises)
        if (queue[url]) {
          // get the filterd promise object
          later = _ember['default'].RSVP.defer();
          queue[url].push(later);
          later = later.promise;
        } else {
          // request is not in the promiseQueue
          later = self.request({
            url: url,
            headers: newHeaders
          }).then(function (result) {
            if (isForAll) {
              store.get('_state.foundAll')[type] = true;
            }

            resolvePromisesInQueue(url, result, 'resolve');
            return result;
          }, function (reason) {
            resolvePromisesInQueue(url, reason, 'reject');
            return _ember['default'].RSVP.reject(reason);
          });

          // set the promises array to empty indicating we've had 1 promise already
          queue[url] = [];
        }

        return later;
      }

      function resolvePromisesInQueue(url, result, action) {
        var queue = self.get('promiseQueue');
        var localPromises = queue[url];

        if (localPromises && localPromises.length) {
          while (localPromises.length) {
            var itemToResolve = localPromises.pop();
            itemToResolve[action](result);
          }
        }

        delete queue[url];
      }
    },

    getByUid: function getByUid(type, uid) {
      type = (0, _emberApiStoreUtilsNormalize.normalizeType)(type);
      var group = this.get('store')._group(type);
      return group.filterBy('metadata.uid', uid)[0];
    },

    containersByDockerId: (function () {
      var out = {};
      this.get('containers').forEach(function (container) {
        out[container.get('externalId')] = container;
      });

      return out;
    }).property('containers.@each.externalId'),

    isReady: function isReady() {
      var _this2 = this;

      return this.get('store').find('stack').then(function (stacks) {
        var stack = _this2.filterSystemStack(stacks);
        if (stack) {
          return _this2.request({
            url: _this2.get('kubernetesEndpoint') + '/version'
          }).then(function (res) {
            _this2.set('version', res);
            return _this2.selectNamespace().then(function () {
              if (_this2.get('tab-session.' + _uiUtilsConstants['default'].TABSESSION.NAMESPACE)) {
                return true;
              } else {
                return false;
              }
            });
          })['catch'](function () {
            return false;
          });
        }

        return false;
      })['catch'](function () {
        return _ember['default'].RSVP.resolve(false);
      });
    },

    supportsStacks: (function () {
      if (this.get('cookies.icanhasstacks')) {
        return true;
      }

      var v = this.get('version');
      if (v) {
        var major = parseInt(v.get('major'), 10);
        var minor = parseInt(v.get('minor'), 10);
        return major > 1 || major === 1 && minor > 2;
      } else {
        return false;
      }
    }).property('version.{minor,major}'),

    filterSystemStack: function filterSystemStack(stacks) {
      return (stacks || []).find(function (stack) {
        var info = stack.get('externalIdInfo');
        return (info.kind === _uiUtilsConstants['default'].EXTERNAL_ID.KIND_CATALOG || info.kind === _uiUtilsConstants['default'].EXTERNAL_ID.KIND_SYSTEM_CATALOG) && info.base === _uiUtilsConstants['default'].EXTERNAL_ID.KIND_INFRA && info.name === _uiUtilsConstants['default'].EXTERNAL_ID.KIND_KUBERNETES;
      });
    },

    _getCollection: function _getCollection(type, resourceName) {
      return this._find('' + _uiUtilsConstants['default'].K8S.TYPE_PREFIX + type, null, {
        url: resourceName
      });
    },

    _allCollection: function _allCollection(type, resourceName) {
      var store = this.get('store');
      type = (0, _emberApiStoreUtilsNormalize.normalizeType)('' + _uiUtilsConstants['default'].K8S.TYPE_PREFIX + type);

      if (store.haveAll(type)) {
        return _ember['default'].RSVP.resolve(store.all(type), 'All ' + type + ' already cached');
      } else {
        return this._find(type, null, {
          url: resourceName
        }).then(function () {
          return store.all(type);
        });
      }
    },

    _getNamespacedResource: function _getNamespacedResource(type, resourceName, id) {
      var nsId = this.get('namespace.id');
      var withNs = joinId(id, nsId);
      var withoutNs = splitId(withNs).name;

      return this._find('' + _uiUtilsConstants['default'].K8S.TYPE_PREFIX + type, withNs, {
        url: 'namespaces/' + nsId + '/' + resourceName + '/' + withoutNs
      });
    },

    allNamespaces: function allNamespaces() {
      return this._allCollection('namespace', 'namespaces');
    },

    getNamespaces: function getNamespaces() {
      return this._getCollection('namespace', 'namespaces');
    },
    getNamespace: function getNamespace(name, forceReload) {
      return this._find(_uiUtilsConstants['default'].K8S.TYPE_PREFIX + 'namespace', name, {
        url: 'namespaces/' + name,
        forceReload: forceReload
      });
    },

    selectNamespace: function selectNamespace(desiredName) {
      var self = this;
      return this.allNamespaces().then(function (all) {
        // Asked for a specific one
        var obj = objForName(desiredName);
        if (obj) {
          return select(obj);
        }

        // One in the session
        obj = objForName(self.get('tab-session.' + _uiUtilsConstants['default'].TABSESSION.NAMESPACE));
        if (obj) {
          return select(obj);
        }

        // One called default
        obj = all.filterBy('id', 'default')[0];
        if (obj) {
          return select(obj);
        }

        // The first one
        obj = all.objectAt(0);
        if (obj) {
          return select(obj);
        }

        // I give up
        return select(null);

        function objForName(name) {
          if (name) {
            return all.filterBy('id', name).objectAt(0);
          } else {
            return null;
          }
        }

        function select(obj) {
          self.set('tab-session.' + _uiUtilsConstants['default'].TABSESSION.NAMESPACE, obj ? obj.get('id') : null);
          self.set('namespace', obj);
          return obj;
        }
      });
    },

    allServices: function allServices() {
      return this._allCollection('service', 'services');
    },
    getServices: function getServices() {
      return this._getCollection('service', 'services');
    },
    getService: function getService(name) {
      return this._getNamespacedResource('service', 'services', name);
    },

    allDeployments: function allDeployments() {
      return this._allCollection('deployment', 'deployments');
    },
    getDeployments: function getDeployments() {
      return this._getCollection('deployment', 'deployments');
    },
    getDeployment: function getDeployment(name) {
      return this._getNamespacedResource('deployment', 'deployments', name);
    },

    allReplicaSets: function allReplicaSets() {
      return this._allCollection('replicaset', 'replicasets');
    },
    getReplicaSets: function getReplicaSets() {
      return this._getCollection('replicaset', 'replicasets');
    },
    getReplicaSet: function getReplicaSet(name) {
      return this._getNamespacedResource('replicaset', 'replicasets', name);
    },

    allRCs: function allRCs() {
      return this._allCollection('replicationcontroller', 'replicationcontrollers');
    },
    getRCs: function getRCs() {
      return this._getCollection('replicationcontroller', 'replicationcontrollers');
    },
    getRC: function getRC(name) {
      return this._getNamespacedResource('replicationcontroller', 'replicationcontrollers', name);
    },

    allPods: function allPods() {
      return this._allCollection('pod', 'pods');
    },
    getPods: function getPods() {
      return this._getCollection('pod', 'pod');
    },
    getPod: function getPod(name) {
      return this._getNamespacedResource('pod', 'pod', name);
    },

    /*
    _getNamespacedType(type, resourceName) {
      return this.find(`${C.K8S.TYPE_PREFIX}${type}`, null, {
        url: `namespaces/${this.get('namespace.id')}/${resourceName}`
      });
    },
     getNSServices()    { return this._getNamespacedType('service','services'); },
    getNSService(name) { return this._getNamespacedResource('service','services', name); },
     getNSRCs()    { return this._getNamespacedType('replicationcontroller','replicationcontrollers'); },
    getNSRC(name) { return this._getNamespacedResource('replicationcontroller','replicationcontrollers', name); },
    */

    parseKubectlError: function parseKubectlError(err) {
      return _emberApiStoreModelsError['default'].create({
        status: err.status,
        code: err.body.exitCode,
        message: err.body.stdErr.split(/\n/)
      });
    },

    create: function create(body) {
      var _this3 = this;

      return this.request({
        url: _uiUtilsUtil['default'].addQueryParam(this.get('kubectlEndpoint') + '/create', _uiUtilsConstants['default'].K8S.DEFAULT_NS, this.get('tab-session.' + _uiUtilsConstants['default'].TABSESSION.NAMESPACE)),
        method: 'POST',
        contentType: 'application/yaml',
        data: body
      })['catch'](function (err) {
        return _ember['default'].RSVP.reject(_this3.parseKubectlError(err));
      });
    },

    remove: function remove(type, name) {
      var _Util$addQueryParams,
          _this4 = this;

      return this.request({
        method: 'POST',
        url: _uiUtilsUtil['default'].addQueryParams(this.get('kubectlEndpoint') + '/delete', (_Util$addQueryParams = {}, _defineProperty(_Util$addQueryParams, _uiUtilsConstants['default'].K8S.DEFAULT_NS, this.get('tab-session.' + _uiUtilsConstants['default'].TABSESSION.NAMESPACE)), _defineProperty(_Util$addQueryParams, 'arg', [type, name]), _Util$addQueryParams))
      })['catch'](function (err) {
        return _ember['default'].RSVP.reject(_this4.parseKubectlError(err));
      });
    },

    getYaml: function getYaml(type, id, defaultNs) {
      var _this5 = this;

      var parts = splitId(id, defaultNs);
      return this.request({
        url: this.get('kubectlEndpoint') + '/' + encodeURIComponent(type) + '/' + encodeURIComponent(parts.name) + '?namespace=' + encodeURIComponent(parts.namespace)
      }).then(function (body) {
        return body.stdOut.trim();
      })['catch'](function (err) {
        return _ember['default'].RSVP.reject(_this5.parseKubectlError(err));
      });
    },

    edit: function edit(body) {
      var _this6 = this;

      return this.request({
        url: _uiUtilsUtil['default'].addQueryParam(this.get('kubectlEndpoint') + '/apply', _uiUtilsConstants['default'].K8S.DEFAULT_NS, this.get('tab-session.' + _uiUtilsConstants['default'].TABSESSION.NAMESPACE)),
        method: 'POST',
        contentType: 'application/yaml',
        data: body
      })['catch'](function (err) {
        return _ember['default'].RSVP.reject(_this6.parseKubectlError(err));
      });
    },

    catalog: function catalog(body) {
      var _this7 = this;

      return this.request({
        url: _uiUtilsUtil['default'].addQueryParam(this.get('kubectlEndpoint') + '/catalog', _uiUtilsConstants['default'].K8S.DEFAULT_NS, this.get('tab-session.' + _uiUtilsConstants['default'].TABSESSION.NAMESPACE)),
        method: 'POST',
        contentType: 'application/json',
        data: body
      })['catch'](function (err) {
        return _ember['default'].RSVP.reject(_this7.parseKubectlError(err));
      });
    }
  });
});