define('ui/components/form-key-value/component', ['exports', 'ember'], function (exports, _ember) {

  function applyLinesIntoArray(lines, ary) {
    lines.forEach(function (line) {
      line = line.trim();
      if (!line) {
        return;
      }

      var idx = line.indexOf('=');
      if (idx === -1) {
        idx = line.indexOf(': ');
      }

      var key = '';
      var val = '';
      if (idx > 0) {
        key = line.substr(0, idx).trim();
        val = line.substr(idx + 1).trim();
      } else {
        key = line.trim();
        val = '';
      }

      var existing = ary.filterBy('key', key)[0];
      if (existing) {
        _ember['default'].set(existing, 'value', val);
      } else {
        ary.pushObject(_ember['default'].Object.create({ key: key, value: val }));
      }
    });
  }

  function removeEmptyEntries(ary) {
    var allowEmptyValue = arguments.length <= 1 || arguments[1] === undefined ? false : arguments[1];

    // Clean up empty user entries
    var toRemove = [];
    ary.forEach(function (item) {
      if (item.get('key') && (item.get('value') || allowEmptyValue)) {
        // ok
      } else {
          toRemove.push(item);
        }
    });

    ary.removeObjects(toRemove);
  }

  exports['default'] = _ember['default'].Component.extend({
    // Inputs
    initialStr: null,
    initialMap: null,
    addActionLabel: 'formKeyValue.addAction',
    keyLabel: 'formKeyValue.key.label',
    valueLabel: 'formKeyValue.value.label',
    keyPlaceholder: 'formKeyValue.key.placeholder',
    valuePlaceholder: 'formKeyValue.value.placeholder',
    allowEmptyValue: false,
    addInitialEmptyRow: false,
    allowMultilineValue: true,
    editing: true,
    ary: null,

    actions: {
      add: function add() {
        var _this = this;

        this.get('ary').pushObject(_ember['default'].Object.create({ key: '', value: '' }));
        _ember['default'].run.next(function () {
          if (_this.isDestroyed || _this.isDestroying) {
            return;
          }

          _this.$('INPUT.key').last()[0].focus();
        });
      },

      remove: function remove(obj) {
        this.get('ary').removeObject(obj);
      },

      pastedLabels: function pastedLabels(str, target) {
        var ary = this.get('ary');
        str = str.trim();
        if (str.indexOf('=') === -1) {
          // Just pasting a key
          $(target).val(str);
          return;
        }

        var lines = str.split(/\r?\n/);
        applyLinesIntoArray(lines, ary);
        removeEmptyEntries(ary, this.get('allowEmptyValue'));
      }
    },

    init: function init() {
      this._super.apply(this, arguments);

      var ary = [];
      var map = this.get('initialMap');
      if (map) {
        Object.keys(map).forEach(function (key) {
          ary.push(_ember['default'].Object.create({ key: key, value: map[key] }));
        });
      } else if (this.get('initialStr')) {
        var lines = this.get('initialStr').split(',');
        applyLinesIntoArray(lines, ary);
        removeEmptyEntries(ary, this.get('allowEmptyValue'));
      }

      this.set('ary', ary);
      if (!ary.length && this.get('addInitialEmptyRow')) {
        this.send('add');
      }
    },

    aryObserver: (function () {
      _ember['default'].run.debounce(this, 'fireChanged', 100);
    }).observes('ary.@each.{key,value}'),

    fireChanged: function fireChanged() {
      var _this2 = this;

      if (this.isDestroyed || this.isDestroying) {
        return;
      }

      var out = {};

      this.get('ary').forEach(function (row) {
        var k = row.get('key').trim();
        var v = row.get('value').trim();

        if (k && (v || _this2.get('allowEmptyValue'))) {
          out[row.get('key').trim()] = row.get('value').trim();
        }
      });

      this.sendAction('changed', out);
    }
  });
});