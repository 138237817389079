define('ui/k8s-tab/route', ['exports', 'ember', 'ui/utils/constants'], function (exports, _ember, _uiUtilsConstants) {
  exports['default'] = _ember['default'].Route.extend({
    projects: _ember['default'].inject.service(),
    k8s: _ember['default'].inject.service(),
    'tab-session': _ember['default'].inject.service(),

    beforeModel: function beforeModel() {
      this._super.apply(this, arguments);
      return this.get('projects').updateOrchestrationState();
    },

    model: function model() {
      var _this = this;

      var auth = this.modelFor('authenticated');
      var k8s = this.get('k8s');
      return _ember['default'].RSVP.hashSettled({
        namespaces: k8s.allNamespaces(),
        services: k8s.allServices(),
        rcs: k8s.allRCs(),
        pods: k8s.allPods(),
        deployments: k8s.allDeployments(),
        replicasets: k8s.allReplicaSets(),
        containers: this.get('store').findAll('container')
      }).then(function (hash) {
        var errors = [];
        var toSet = {};
        Object.keys(hash).forEach(function (key) {
          if (hash[key].state === 'rejected') {
            var err = hash[key].reason;
            err.key = key;
            errors.push(err);
          } else {
            toSet[key] = hash[key].value;
          }
        });

        if (errors.length) {
          _this.set('tab-session.' + _uiUtilsConstants['default'].TABSESSION.NAMESPACE, null);
          k8s.set('namespace', null);
          k8s.set('loadingErrors', errors);
          _this.transitionTo('k8s-tab.error');
        } else {
          return k8s.selectNamespace().then(function () {
            k8s.set('loadingErrors', null);
            k8s.setProperties(toSet);
            return auth;
          });
        }
      });
    },

    deactivate: function deactivate() {
      $('BODY').removeClass('k8s');
    }
  });
});