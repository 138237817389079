define("ui/components/page-header/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      var child0 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "revision": "Ember@2.9.1",
              "loc": {
                "source": null,
                "start": {
                  "line": 23,
                  "column": 62
                },
                "end": {
                  "line": 23,
                  "column": 114
                }
              },
              "moduleName": "ui/components/page-header/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["inline", "t", [["get", "item.localizedLabel", ["loc", [null, [23, 93], [23, 112]]], 0, 0, 0, 0]], [], ["loc", [null, [23, 89], [23, 114]]], 0, 0]],
            locals: [],
            templates: []
          };
        })();
        var child1 = (function () {
          return {
            meta: {
              "revision": "Ember@2.9.1",
              "loc": {
                "source": null,
                "start": {
                  "line": 23,
                  "column": 114
                },
                "end": {
                  "line": 23,
                  "column": 136
                }
              },
              "moduleName": "ui/components/page-header/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["content", "item.label", ["loc", [null, [23, 122], [23, 136]]], 0, 0, 0, 0]],
            locals: [],
            templates: []
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.9.1",
            "loc": {
              "source": null,
              "start": {
                "line": 21,
                "column": 10
              },
              "end": {
                "line": 25,
                "column": 10
              }
            },
            "moduleName": "ui/components/page-header/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("li");
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("a");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element9 = dom.childAt(fragment, [1, 1]);
            var morphs = new Array(3);
            morphs[0] = dom.createAttrMorph(element9, 'href');
            morphs[1] = dom.createAttrMorph(element9, 'target');
            morphs[2] = dom.createMorphAt(element9, 0, 0);
            return morphs;
          },
          statements: [["attribute", "href", ["concat", [["get", "item.url", ["loc", [null, [23, 25], [23, 33]]], 0, 0, 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "target", ["concat", [["get", "item.target", ["loc", [null, [23, 47], [23, 58]]], 0, 0, 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["block", "if", [["get", "item.localizedLabel", ["loc", [null, [23, 68], [23, 87]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [23, 62], [23, 143]]]]],
          locals: [],
          templates: [child0, child1]
        };
      })();
      var child1 = (function () {
        var child0 = (function () {
          var child0 = (function () {
            var child0 = (function () {
              return {
                meta: {
                  "revision": "Ember@2.9.1",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 39,
                      "column": 16
                    },
                    "end": {
                      "line": 39,
                      "column": 68
                    }
                  },
                  "moduleName": "ui/components/page-header/template.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [["inline", "t", [["get", "item.localizedLabel", ["loc", [null, [39, 47], [39, 66]]], 0, 0, 0, 0]], [], ["loc", [null, [39, 43], [39, 68]]], 0, 0]],
                locals: [],
                templates: []
              };
            })();
            var child1 = (function () {
              return {
                meta: {
                  "revision": "Ember@2.9.1",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 39,
                      "column": 68
                    },
                    "end": {
                      "line": 39,
                      "column": 90
                    }
                  },
                  "moduleName": "ui/components/page-header/template.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [["content", "item.label", ["loc", [null, [39, 76], [39, 90]]], 0, 0, 0, 0]],
                locals: [],
                templates: []
              };
            })();
            return {
              meta: {
                "revision": "Ember@2.9.1",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 28,
                    "column": 14
                  },
                  "end": {
                    "line": 40,
                    "column": 14
                  }
                },
                "moduleName": "ui/components/page-header/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode(" ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("span");
                dom.setAttribute(el1, "class", "icon icon-chevron-down hidden-sm hidden-xs");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                return morphs;
              },
              statements: [["block", "if", [["get", "item.localizedLabel", ["loc", [null, [39, 22], [39, 41]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [39, 16], [39, 97]]]]],
              locals: [],
              templates: [child0, child1]
            };
          })();
          var child1 = (function () {
            var child0 = (function () {
              return {
                meta: {
                  "revision": "Ember@2.9.1",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 43,
                      "column": 18
                    },
                    "end": {
                      "line": 45,
                      "column": 18
                    }
                  },
                  "moduleName": "ui/components/page-header/template.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                    ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("li");
                  dom.setAttribute(el1, "class", "divider");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes() {
                  return [];
                },
                statements: [],
                locals: [],
                templates: []
              };
            })();
            var child1 = (function () {
              var child0 = (function () {
                var child0 = (function () {
                  return {
                    meta: {
                      "revision": "Ember@2.9.1",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 48,
                          "column": 54
                        },
                        "end": {
                          "line": 48,
                          "column": 112
                        }
                      },
                      "moduleName": "ui/components/page-header/template.hbs"
                    },
                    isEmpty: false,
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createComment("");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var morphs = new Array(1);
                      morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                      dom.insertBoundary(fragment, 0);
                      dom.insertBoundary(fragment, null);
                      return morphs;
                    },
                    statements: [["inline", "t", [["get", "subitem.localizedLabel", ["loc", [null, [48, 88], [48, 110]]], 0, 0, 0, 0]], [], ["loc", [null, [48, 84], [48, 112]]], 0, 0]],
                    locals: [],
                    templates: []
                  };
                })();
                var child1 = (function () {
                  return {
                    meta: {
                      "revision": "Ember@2.9.1",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 48,
                          "column": 112
                        },
                        "end": {
                          "line": 48,
                          "column": 137
                        }
                      },
                      "moduleName": "ui/components/page-header/template.hbs"
                    },
                    isEmpty: false,
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createComment("");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var morphs = new Array(1);
                      morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                      dom.insertBoundary(fragment, 0);
                      dom.insertBoundary(fragment, null);
                      return morphs;
                    },
                    statements: [["content", "subitem.label", ["loc", [null, [48, 120], [48, 137]]], 0, 0, 0, 0]],
                    locals: [],
                    templates: []
                  };
                })();
                return {
                  meta: {
                    "revision": "Ember@2.9.1",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 45,
                        "column": 18
                      },
                      "end": {
                        "line": 51,
                        "column": 18
                      }
                    },
                    "moduleName": "ui/components/page-header/template.hbs"
                  },
                  isEmpty: false,
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                    ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("li");
                    var el2 = dom.createTextNode("\n                      ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createElement("a");
                    var el3 = dom.createTextNode("\n                        ");
                    dom.appendChild(el2, el3);
                    var el3 = dom.createElement("i");
                    dom.appendChild(el2, el3);
                    var el3 = dom.createTextNode(" ");
                    dom.appendChild(el2, el3);
                    var el3 = dom.createComment("");
                    dom.appendChild(el2, el3);
                    var el3 = dom.createTextNode("\n                      ");
                    dom.appendChild(el2, el3);
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("\n                    ");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var element5 = dom.childAt(fragment, [1, 1]);
                    var element6 = dom.childAt(element5, [1]);
                    var morphs = new Array(4);
                    morphs[0] = dom.createAttrMorph(element5, 'href');
                    morphs[1] = dom.createAttrMorph(element5, 'target');
                    morphs[2] = dom.createAttrMorph(element6, 'class');
                    morphs[3] = dom.createMorphAt(element5, 3, 3);
                    return morphs;
                  },
                  statements: [["attribute", "href", ["concat", [["get", "subitem.url", ["loc", [null, [47, 33], [47, 44]]], 0, 0, 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "target", ["concat", [["get", "subitem.target", ["loc", [null, [47, 58], [47, 72]]], 0, 0, 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "class", ["concat", [["get", "subitem.icon", ["loc", [null, [48, 36], [48, 48]]], 0, 0, 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["block", "if", [["get", "subitem.localizedLabel", ["loc", [null, [48, 60], [48, 82]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [48, 54], [48, 144]]]]],
                  locals: [],
                  templates: [child0, child1]
                };
              })();
              var child1 = (function () {
                var child0 = (function () {
                  var child0 = (function () {
                    var child0 = (function () {
                      return {
                        meta: {
                          "revision": "Ember@2.9.1",
                          "loc": {
                            "source": null,
                            "start": {
                              "line": 60,
                              "column": 54
                            },
                            "end": {
                              "line": 60,
                              "column": 112
                            }
                          },
                          "moduleName": "ui/components/page-header/template.hbs"
                        },
                        isEmpty: false,
                        arity: 0,
                        cachedFragment: null,
                        hasRendered: false,
                        buildFragment: function buildFragment(dom) {
                          var el0 = dom.createDocumentFragment();
                          var el1 = dom.createComment("");
                          dom.appendChild(el0, el1);
                          return el0;
                        },
                        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                          var morphs = new Array(1);
                          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                          dom.insertBoundary(fragment, 0);
                          dom.insertBoundary(fragment, null);
                          return morphs;
                        },
                        statements: [["inline", "t", [["get", "subitem.localizedLabel", ["loc", [null, [60, 88], [60, 110]]], 0, 0, 0, 0]], [], ["loc", [null, [60, 84], [60, 112]]], 0, 0]],
                        locals: [],
                        templates: []
                      };
                    })();
                    var child1 = (function () {
                      return {
                        meta: {
                          "revision": "Ember@2.9.1",
                          "loc": {
                            "source": null,
                            "start": {
                              "line": 60,
                              "column": 112
                            },
                            "end": {
                              "line": 60,
                              "column": 137
                            }
                          },
                          "moduleName": "ui/components/page-header/template.hbs"
                        },
                        isEmpty: false,
                        arity: 0,
                        cachedFragment: null,
                        hasRendered: false,
                        buildFragment: function buildFragment(dom) {
                          var el0 = dom.createDocumentFragment();
                          var el1 = dom.createComment("");
                          dom.appendChild(el0, el1);
                          return el0;
                        },
                        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                          var morphs = new Array(1);
                          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                          dom.insertBoundary(fragment, 0);
                          dom.insertBoundary(fragment, null);
                          return morphs;
                        },
                        statements: [["content", "subitem.label", ["loc", [null, [60, 120], [60, 137]]], 0, 0, 0, 0]],
                        locals: [],
                        templates: []
                      };
                    })();
                    return {
                      meta: {
                        "revision": "Ember@2.9.1",
                        "loc": {
                          "source": null,
                          "start": {
                            "line": 53,
                            "column": 22
                          },
                          "end": {
                            "line": 61,
                            "column": 22
                          }
                        },
                        "moduleName": "ui/components/page-header/template.hbs"
                      },
                      isEmpty: false,
                      arity: 0,
                      cachedFragment: null,
                      hasRendered: false,
                      buildFragment: function buildFragment(dom) {
                        var el0 = dom.createDocumentFragment();
                        var el1 = dom.createTextNode("                        ");
                        dom.appendChild(el0, el1);
                        var el1 = dom.createElement("i");
                        dom.appendChild(el0, el1);
                        var el1 = dom.createTextNode(" ");
                        dom.appendChild(el0, el1);
                        var el1 = dom.createComment("");
                        dom.appendChild(el0, el1);
                        var el1 = dom.createTextNode("\n");
                        dom.appendChild(el0, el1);
                        return el0;
                      },
                      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                        var element4 = dom.childAt(fragment, [1]);
                        var morphs = new Array(2);
                        morphs[0] = dom.createAttrMorph(element4, 'class');
                        morphs[1] = dom.createMorphAt(fragment, 3, 3, contextualElement);
                        return morphs;
                      },
                      statements: [["attribute", "class", ["concat", [["get", "subitem.icon", ["loc", [null, [60, 36], [60, 48]]], 0, 0, 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["block", "if", [["get", "subitem.localizedLabel", ["loc", [null, [60, 60], [60, 82]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [60, 54], [60, 144]]]]],
                      locals: [],
                      templates: [child0, child1]
                    };
                  })();
                  return {
                    meta: {
                      "revision": "Ember@2.9.1",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 51,
                          "column": 18
                        },
                        "end": {
                          "line": 63,
                          "column": 18
                        }
                      },
                      "moduleName": "ui/components/page-header/template.hbs"
                    },
                    isEmpty: false,
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createTextNode("                    ");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createElement("li");
                      var el2 = dom.createTextNode("\n");
                      dom.appendChild(el1, el2);
                      var el2 = dom.createComment("");
                      dom.appendChild(el1, el2);
                      var el2 = dom.createTextNode("                    ");
                      dom.appendChild(el1, el2);
                      dom.appendChild(el0, el1);
                      var el1 = dom.createTextNode("\n");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var morphs = new Array(1);
                      morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
                      return morphs;
                    },
                    statements: [["block", "link-to-as-attrs", [["get", "subitem.route", ["loc", [null, [54, 24], [54, 37]]], 0, 0, 0, 0]], ["models", ["subexpr", "@mut", [["get", "subitem.ctx", ["loc", [null, [55, 31], [55, 42]]], 0, 0, 0, 0]], [], [], 0, 0], "queryParams", ["subexpr", "@mut", [["get", "subitem.queryParams", ["loc", [null, [56, 36], [56, 55]]], 0, 0, 0, 0]], [], [], 0, 0], "activeParent", true, "moreCurrentWhen", ["subexpr", "@mut", [["get", "subitem.moreCurrentWhen", ["loc", [null, [58, 40], [58, 63]]], 0, 0, 0, 0]], [], [], 0, 0]], 0, null, ["loc", [null, [53, 22], [61, 43]]]]],
                    locals: [],
                    templates: [child0]
                  };
                })();
                var child1 = (function () {
                  var child0 = (function () {
                    return {
                      meta: {
                        "revision": "Ember@2.9.1",
                        "loc": {
                          "source": null,
                          "start": {
                            "line": 66,
                            "column": 51
                          },
                          "end": {
                            "line": 66,
                            "column": 109
                          }
                        },
                        "moduleName": "ui/components/page-header/template.hbs"
                      },
                      isEmpty: false,
                      arity: 0,
                      cachedFragment: null,
                      hasRendered: false,
                      buildFragment: function buildFragment(dom) {
                        var el0 = dom.createDocumentFragment();
                        var el1 = dom.createComment("");
                        dom.appendChild(el0, el1);
                        return el0;
                      },
                      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                        var morphs = new Array(1);
                        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                        dom.insertBoundary(fragment, 0);
                        dom.insertBoundary(fragment, null);
                        return morphs;
                      },
                      statements: [["inline", "t", [["get", "subitem.localizedLabel", ["loc", [null, [66, 85], [66, 107]]], 0, 0, 0, 0]], [], ["loc", [null, [66, 81], [66, 109]]], 0, 0]],
                      locals: [],
                      templates: []
                    };
                  })();
                  var child1 = (function () {
                    return {
                      meta: {
                        "revision": "Ember@2.9.1",
                        "loc": {
                          "source": null,
                          "start": {
                            "line": 66,
                            "column": 109
                          },
                          "end": {
                            "line": 66,
                            "column": 134
                          }
                        },
                        "moduleName": "ui/components/page-header/template.hbs"
                      },
                      isEmpty: false,
                      arity: 0,
                      cachedFragment: null,
                      hasRendered: false,
                      buildFragment: function buildFragment(dom) {
                        var el0 = dom.createDocumentFragment();
                        var el1 = dom.createComment("");
                        dom.appendChild(el0, el1);
                        return el0;
                      },
                      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                        var morphs = new Array(1);
                        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                        dom.insertBoundary(fragment, 0);
                        dom.insertBoundary(fragment, null);
                        return morphs;
                      },
                      statements: [["content", "subitem.label", ["loc", [null, [66, 117], [66, 134]]], 0, 0, 0, 0]],
                      locals: [],
                      templates: []
                    };
                  })();
                  return {
                    meta: {
                      "revision": "Ember@2.9.1",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 63,
                          "column": 18
                        },
                        "end": {
                          "line": 68,
                          "column": 18
                        }
                      },
                      "moduleName": "ui/components/page-header/template.hbs"
                    },
                    isEmpty: false,
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createTextNode("                    ");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createElement("li");
                      dom.setAttribute(el1, "class", "dropdown-header");
                      var el2 = dom.createTextNode("\n                     ");
                      dom.appendChild(el1, el2);
                      var el2 = dom.createElement("i");
                      dom.appendChild(el1, el2);
                      var el2 = dom.createTextNode(" ");
                      dom.appendChild(el1, el2);
                      var el2 = dom.createComment("");
                      dom.appendChild(el1, el2);
                      var el2 = dom.createTextNode("\n                    ");
                      dom.appendChild(el1, el2);
                      dom.appendChild(el0, el1);
                      var el1 = dom.createTextNode("\n                  ");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var element2 = dom.childAt(fragment, [1]);
                      var element3 = dom.childAt(element2, [1]);
                      var morphs = new Array(2);
                      morphs[0] = dom.createAttrMorph(element3, 'class');
                      morphs[1] = dom.createMorphAt(element2, 3, 3);
                      return morphs;
                    },
                    statements: [["attribute", "class", ["concat", [["get", "subitem.icon", ["loc", [null, [66, 33], [66, 45]]], 0, 0, 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["block", "if", [["get", "subitem.localizedLabel", ["loc", [null, [66, 57], [66, 79]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [66, 51], [66, 141]]]]],
                    locals: [],
                    templates: [child0, child1]
                  };
                })();
                return {
                  meta: {
                    "revision": "Ember@2.9.1",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 51,
                        "column": 18
                      },
                      "end": {
                        "line": 68,
                        "column": 18
                      }
                    },
                    "moduleName": "ui/components/page-header/template.hbs"
                  },
                  isEmpty: false,
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                    dom.insertBoundary(fragment, 0);
                    dom.insertBoundary(fragment, null);
                    return morphs;
                  },
                  statements: [["block", "if", [["get", "subitem.route", ["loc", [null, [51, 28], [51, 41]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [51, 18], [68, 18]]]]],
                  locals: [],
                  templates: [child0, child1]
                };
              })();
              return {
                meta: {
                  "revision": "Ember@2.9.1",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 45,
                      "column": 18
                    },
                    "end": {
                      "line": 68,
                      "column": 18
                    }
                  },
                  "moduleName": "ui/components/page-header/template.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [["block", "if", [["get", "subitem.url", ["loc", [null, [45, 28], [45, 39]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [45, 18], [68, 18]]]]],
                locals: [],
                templates: [child0, child1]
              };
            })();
            return {
              meta: {
                "revision": "Ember@2.9.1",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 42,
                    "column": 16
                  },
                  "end": {
                    "line": 69,
                    "column": 16
                  }
                },
                "moduleName": "ui/components/page-header/template.hbs"
              },
              isEmpty: false,
              arity: 1,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [["block", "if", [["get", "subitem.divider", ["loc", [null, [43, 24], [43, 39]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [43, 18], [68, 25]]]]],
              locals: ["subitem"],
              templates: [child0, child1]
            };
          })();
          var child2 = (function () {
            var child0 = (function () {
              var child0 = (function () {
                return {
                  meta: {
                    "revision": "Ember@2.9.1",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 73,
                        "column": 18
                      },
                      "end": {
                        "line": 73,
                        "column": 93
                      }
                    },
                    "moduleName": "ui/components/page-header/template.hbs"
                  },
                  isEmpty: false,
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("!");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes() {
                    return [];
                  },
                  statements: [],
                  locals: [],
                  templates: []
                };
              })();
              return {
                meta: {
                  "revision": "Ember@2.9.1",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 72,
                      "column": 16
                    },
                    "end": {
                      "line": 74,
                      "column": 16
                    }
                  },
                  "moduleName": "ui/components/page-header/template.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                  ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                  return morphs;
                },
                statements: [["block", "link-to", [["get", "item.alertRoute", ["loc", [null, [73, 29], [73, 44]]], 0, 0, 0, 0]], ["data-toggle", "tooltip", "data-placement", "bottom"], 0, null, ["loc", [null, [73, 18], [73, 105]]]]],
                locals: [],
                templates: [child0]
              };
            })();
            return {
              meta: {
                "revision": "Ember@2.9.1",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 71,
                    "column": 14
                  },
                  "end": {
                    "line": 75,
                    "column": 14
                  }
                },
                "moduleName": "ui/components/page-header/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [["block", "tooltip-element", [], ["class", "tooltip-warning", "type", "tooltip-warning", "tooltipFor", "showAlert"], 0, null, ["loc", [null, [72, 16], [74, 36]]]]],
              locals: [],
              templates: [child0]
            };
          })();
          return {
            meta: {
              "revision": "Ember@2.9.1",
              "loc": {
                "source": null,
                "start": {
                  "line": 25,
                  "column": 10
                },
                "end": {
                  "line": 77,
                  "column": 10
                }
              },
              "moduleName": "ui/components/page-header/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("li");
              dom.setAttribute(el1, "class", "dropdown");
              var el2 = dom.createTextNode("\n");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("              ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("ul");
              dom.setAttribute(el2, "class", "dropdown-menu");
              var el3 = dom.createTextNode("\n");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("              ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("            ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element7 = dom.childAt(fragment, [1]);
              var element8 = dom.childAt(element7, [3]);
              var morphs = new Array(4);
              morphs[0] = dom.createMorphAt(element7, 1, 1);
              morphs[1] = dom.createAttrMorph(element8, 'data-dropdown-id');
              morphs[2] = dom.createMorphAt(element8, 1, 1);
              morphs[3] = dom.createMorphAt(element7, 5, 5);
              return morphs;
            },
            statements: [["block", "link-to-as-attrs", [["get", "item.route", ["loc", [null, [29, 16], [29, 26]]], 0, 0, 0, 0]], ["models", ["subexpr", "@mut", [["get", "item.ctx", ["loc", [null, [30, 23], [30, 31]]], 0, 0, 0, 0]], [], [], 0, 0], "queryParams", ["subexpr", "@mut", [["get", "item.queryParams", ["loc", [null, [31, 28], [31, 44]]], 0, 0, 0, 0]], [], [], 0, 0], "moreCurrentWhen", ["subexpr", "@mut", [["get", "item.moreCurrentWhen", ["loc", [null, [32, 32], [32, 52]]], 0, 0, 0, 0]], [], [], 0, 0], "activeParent", true, "class", "dropdown-toggle", "role", "button", "aria-haspopup", "true", "aria-expanded", "false"], 0, null, ["loc", [null, [28, 14], [40, 35]]]], ["attribute", "data-dropdown-id", ["get", "item.id", ["loc", [null, [41, 59], [41, 66]]], 0, 0, 0, 0], 0, 0, 0, 0], ["block", "each", [["get", "item.submenu", ["loc", [null, [42, 24], [42, 36]]], 0, 0, 0, 0]], [], 1, null, ["loc", [null, [42, 16], [69, 25]]]], ["block", "if", [["get", "item.showAlert", ["loc", [null, [71, 20], [71, 34]]], 0, 0, 0, 0]], [], 2, null, ["loc", [null, [71, 14], [75, 21]]]]],
            locals: [],
            templates: [child0, child1, child2]
          };
        })();
        var child1 = (function () {
          var child0 = (function () {
            var child0 = (function () {
              var child0 = (function () {
                return {
                  meta: {
                    "revision": "Ember@2.9.1",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 87,
                        "column": 16
                      },
                      "end": {
                        "line": 87,
                        "column": 68
                      }
                    },
                    "moduleName": "ui/components/page-header/template.hbs"
                  },
                  isEmpty: false,
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                    dom.insertBoundary(fragment, 0);
                    dom.insertBoundary(fragment, null);
                    return morphs;
                  },
                  statements: [["inline", "t", [["get", "item.localizedLabel", ["loc", [null, [87, 47], [87, 66]]], 0, 0, 0, 0]], [], ["loc", [null, [87, 43], [87, 68]]], 0, 0]],
                  locals: [],
                  templates: []
                };
              })();
              var child1 = (function () {
                return {
                  meta: {
                    "revision": "Ember@2.9.1",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 87,
                        "column": 68
                      },
                      "end": {
                        "line": 87,
                        "column": 90
                      }
                    },
                    "moduleName": "ui/components/page-header/template.hbs"
                  },
                  isEmpty: false,
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                    dom.insertBoundary(fragment, 0);
                    dom.insertBoundary(fragment, null);
                    return morphs;
                  },
                  statements: [["content", "item.label", ["loc", [null, [87, 76], [87, 90]]], 0, 0, 0, 0]],
                  locals: [],
                  templates: []
                };
              })();
              return {
                meta: {
                  "revision": "Ember@2.9.1",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 80,
                      "column": 14
                    },
                    "end": {
                      "line": 88,
                      "column": 14
                    }
                  },
                  "moduleName": "ui/components/page-header/template.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                  return morphs;
                },
                statements: [["block", "if", [["get", "item.localizedLabel", ["loc", [null, [87, 22], [87, 41]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [87, 16], [87, 97]]]]],
                locals: [],
                templates: [child0, child1]
              };
            })();
            return {
              meta: {
                "revision": "Ember@2.9.1",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 77,
                    "column": 10
                  },
                  "end": {
                    "line": 90,
                    "column": 10
                  }
                },
                "moduleName": "ui/components/page-header/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("            ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("li");
                var el2 = dom.createTextNode("\n");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("            ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
                return morphs;
              },
              statements: [["block", "link-to-as-attrs", [["get", "item.route", ["loc", [null, [81, 16], [81, 26]]], 0, 0, 0, 0]], ["models", ["subexpr", "@mut", [["get", "item.ctx", ["loc", [null, [82, 23], [82, 31]]], 0, 0, 0, 0]], [], [], 0, 0], "queryParams", ["subexpr", "@mut", [["get", "item.queryParams", ["loc", [null, [83, 28], [83, 44]]], 0, 0, 0, 0]], [], [], 0, 0], "activeParent", true, "moreCurrentWhen", ["subexpr", "@mut", [["get", "item.moreCurrentWhen", ["loc", [null, [85, 32], [85, 52]]], 0, 0, 0, 0]], [], [], 0, 0]], 0, null, ["loc", [null, [80, 14], [88, 35]]]]],
              locals: [],
              templates: [child0]
            };
          })();
          var child1 = (function () {
            var child0 = (function () {
              return {
                meta: {
                  "revision": "Ember@2.9.1",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 93,
                      "column": 14
                    },
                    "end": {
                      "line": 93,
                      "column": 66
                    }
                  },
                  "moduleName": "ui/components/page-header/template.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [["inline", "t", [["get", "item.localizedLabel", ["loc", [null, [93, 45], [93, 64]]], 0, 0, 0, 0]], [], ["loc", [null, [93, 41], [93, 66]]], 0, 0]],
                locals: [],
                templates: []
              };
            })();
            var child1 = (function () {
              return {
                meta: {
                  "revision": "Ember@2.9.1",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 93,
                      "column": 66
                    },
                    "end": {
                      "line": 93,
                      "column": 88
                    }
                  },
                  "moduleName": "ui/components/page-header/template.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [["content", "item.label", ["loc", [null, [93, 74], [93, 88]]], 0, 0, 0, 0]],
                locals: [],
                templates: []
              };
            })();
            return {
              meta: {
                "revision": "Ember@2.9.1",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 90,
                    "column": 10
                  },
                  "end": {
                    "line": 95,
                    "column": 10
                  }
                },
                "moduleName": "ui/components/page-header/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("            ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("li");
                dom.setAttribute(el1, "class", "dropdown-header");
                var el2 = dom.createTextNode("\n              ");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n            ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n          ");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
                return morphs;
              },
              statements: [["block", "if", [["get", "item.localizedLabel", ["loc", [null, [93, 20], [93, 39]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [93, 14], [93, 95]]]]],
              locals: [],
              templates: [child0, child1]
            };
          })();
          return {
            meta: {
              "revision": "Ember@2.9.1",
              "loc": {
                "source": null,
                "start": {
                  "line": 77,
                  "column": 10
                },
                "end": {
                  "line": 95,
                  "column": 10
                }
              },
              "moduleName": "ui/components/page-header/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["block", "if", [["get", "item.route", ["loc", [null, [77, 20], [77, 30]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [77, 10], [95, 10]]]]],
            locals: [],
            templates: [child0, child1]
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.9.1",
            "loc": {
              "source": null,
              "start": {
                "line": 25,
                "column": 10
              },
              "end": {
                "line": 95,
                "column": 10
              }
            },
            "moduleName": "ui/components/page-header/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "if", [["get", "item.submenu.length", ["loc", [null, [25, 20], [25, 39]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [25, 10], [95, 10]]]]],
          locals: [],
          templates: [child0, child1]
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.9.1",
          "loc": {
            "source": null,
            "start": {
              "line": 20,
              "column": 8
            },
            "end": {
              "line": 96,
              "column": 8
            }
          },
          "moduleName": "ui/components/page-header/template.hbs"
        },
        isEmpty: false,
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "if", [["get", "item.url", ["loc", [null, [21, 16], [21, 24]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [21, 10], [95, 17]]]]],
        locals: ["item"],
        templates: [child0, child1]
      };
    })();
    var child1 = (function () {
      return {
        meta: {
          "revision": "Ember@2.9.1",
          "loc": {
            "source": null,
            "start": {
              "line": 105,
              "column": 0
            },
            "end": {
              "line": 116,
              "column": 0
            }
          },
          "moduleName": "ui/components/page-header/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "lacsso banner bg-info m-0 p-0");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "lacsso banner-icon");
          dom.setAttribute(el2, "style", "width: 68px;");
          var el3 = dom.createElement("span");
          dom.setAttribute(el3, "class", "icon icon-2x icon-alert");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "lacsso banner-message");
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("p");
          dom.setAttribute(el3, "class", "lacsso mt-15 mb-15");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("a");
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1, 3, 1]);
          var element1 = dom.childAt(element0, [1]);
          var morphs = new Array(4);
          morphs[0] = dom.createMorphAt(element0, 0, 0);
          morphs[1] = dom.createAttrMorph(element1, 'href');
          morphs[2] = dom.createMorphAt(element1, 0, 0);
          morphs[3] = dom.createMorphAt(element0, 2, 2);
          return morphs;
        },
        statements: [["inline", "t", [["subexpr", "if", [["get", "projects.current.isWindows", ["loc", [null, [110, 17], [110, 43]]], 0, 0, 0, 0], "nav.noHostsWindows.prefix", "nav.noHosts.prefix"], [], ["loc", [null, [110, 13], [110, 93]]], 0, 0]], ["appName", ["subexpr", "@mut", [["get", "settings.appName", ["loc", [null, [110, 102], [110, 118]]], 0, 0, 0, 0]], [], [], 0, 0], "minVersion", ["subexpr", "@mut", [["get", "settings.minDockerVersion", ["loc", [null, [110, 130], [110, 155]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [110, 8], [110, 158]]], 0, 0], ["attribute", "href", ["concat", [["subexpr", "href-to", ["hosts.new"], [], ["loc", [null, [111, 17], [111, 40]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["inline", "t", [["subexpr", "if", [["get", "projects.current.isWindows", ["loc", [null, [111, 51], [111, 77]]], 0, 0, 0, 0], "nav.noHostsWindows.link", "nav.noHosts.link"], [], ["loc", [null, [111, 47], [111, 123]]], 0, 0]], ["appName", ["subexpr", "@mut", [["get", "settings.appName", ["loc", [null, [111, 132], [111, 148]]], 0, 0, 0, 0]], [], [], 0, 0], "minVersion", ["subexpr", "@mut", [["get", "settings.minDockerVersion", ["loc", [null, [111, 160], [111, 185]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [111, 42], [111, 188]]], 0, 0], ["inline", "t", [["subexpr", "if", [["get", "projects.current.isWindows", ["loc", [null, [112, 17], [112, 43]]], 0, 0, 0, 0], "nav.noHostsWindows.suffix", "nav.noHosts.suffix"], [], ["loc", [null, [112, 13], [112, 93]]], 0, 0]], ["appName", ["subexpr", "@mut", [["get", "settings.appName", ["loc", [null, [112, 102], [112, 118]]], 0, 0, 0, 0]], [], [], 0, 0], "minVersion", ["subexpr", "@mut", [["get", "settings.minDockerVersion", ["loc", [null, [112, 130], [112, 155]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [112, 8], [112, 158]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child2 = (function () {
      return {
        meta: {
          "revision": "Ember@2.9.1",
          "loc": {
            "source": null,
            "start": {
              "line": 117,
              "column": 0
            },
            "end": {
              "line": 126,
              "column": 0
            }
          },
          "moduleName": "ui/components/page-header/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "lacsso banner bg-warning m-0 p-0");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "lacsso banner-icon");
          dom.setAttribute(el2, "style", "width: 68px;");
          var el3 = dom.createElement("span");
          dom.setAttribute(el3, "class", "icon icon-2x icon-spinner icon-spin");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "lacsso banner-message");
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("p");
          dom.setAttribute(el3, "class", "lacsso mt-15 mb-15");
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n      ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 3, 1]), 1, 1);
          return morphs;
        },
        statements: [["inline", "format-html-message", ["projectUpgrade.banner"], [], ["loc", [null, [122, 8], [122, 55]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.9.1",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 127,
            "column": 0
          }
        },
        "moduleName": "ui/components/page-header/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("nav");
        dom.setAttribute(el1, "class", "navbar clearfix");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "container-fluid");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "navbar-header");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("a");
        dom.setAttribute(el4, "role", "button");
        dom.setAttribute(el4, "class", "navbar-toggle collapsed");
        dom.setAttribute(el4, "data-toggle", "collapse");
        dom.setAttribute(el4, "data-target", "#navbar");
        dom.setAttribute(el4, "aria-controls", "navbar");
        dom.setAttribute(el4, "aria-expanded", "false");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("span");
        dom.setAttribute(el5, "class", "sr-only");
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("i");
        dom.setAttribute(el5, "class", "icon icon-ellipsis");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("ul");
        dom.setAttribute(el4, "class", "nav");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        var el6 = dom.createElement("button");
        dom.setAttribute(el6, "class", "navbar-brand logo btn");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("\n          ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "id", "navbar");
        dom.setAttribute(el3, "class", "navbar-collapse collapse");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("ul");
        dom.setAttribute(el4, "class", "nav navbar-nav");
        var el5 = dom.createTextNode("\n");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("ul");
        dom.setAttribute(el4, "class", "nav navbar-nav navbar-right");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        dom.setAttribute(el5, "class", "dropdown");
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element10 = dom.childAt(fragment, [0, 1]);
        var element11 = dom.childAt(element10, [1]);
        var element12 = dom.childAt(element10, [3]);
        var morphs = new Array(6);
        morphs[0] = dom.createMorphAt(dom.childAt(element11, [1, 1]), 0, 0);
        morphs[1] = dom.createMorphAt(dom.childAt(element11, [3]), 3, 3);
        morphs[2] = dom.createMorphAt(dom.childAt(element12, [1]), 1, 1);
        morphs[3] = dom.createMorphAt(dom.childAt(element12, [3, 1]), 0, 0);
        morphs[4] = dom.createMorphAt(fragment, 2, 2, contextualElement);
        morphs[5] = dom.createMorphAt(fragment, 3, 3, contextualElement);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["inline", "t", ["nav.srToggleNav"], [], ["loc", [null, [5, 30], [5, 53]]], 0, 0], ["inline", "page-header-environment", [], ["currentPath", ["subexpr", "@mut", [["get", "currentPath", ["loc", [null, [11, 24], [11, 35]]], 0, 0, 0, 0]], [], [], 0, 0], "isOwner", ["subexpr", "@mut", [["get", "isOwner", ["loc", [null, [12, 20], [12, 27]]], 0, 0, 0, 0]], [], [], 0, 0], "switchProject", "switchProject", "switchNamespace", "switchNamespace"], ["loc", [null, [10, 13], [15, 13]]], 0, 0], ["block", "each", [["get", "navTree", ["loc", [null, [20, 16], [20, 23]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [20, 8], [96, 17]]]], ["content", "page-header-user", ["loc", [null, [100, 29], [100, 49]]], 0, 0, 0, 0], ["block", "unless", [["get", "hosts.length", ["loc", [null, [105, 10], [105, 22]]], 0, 0, 0, 0]], [], 1, null, ["loc", [null, [105, 0], [116, 11]]]], ["block", "if", [["get", "projects.current.isUpgrading", ["loc", [null, [117, 6], [117, 34]]], 0, 0, 0, 0]], [], 2, null, ["loc", [null, [117, 0], [126, 7]]]]],
      locals: [],
      templates: [child0, child1, child2]
    };
  })());
});