define("ui/k8s-tab/namespace/rcs/rc/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "revision": "Ember@2.9.1",
          "loc": {
            "source": null,
            "start": {
              "line": 2,
              "column": 6
            },
            "end": {
              "line": 2,
              "column": 80
            }
          },
          "moduleName": "ui/k8s-tab/namespace/rcs/rc/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["inline", "t", ["k8sTab.namespace.rcs.rc.header"], [], ["loc", [null, [2, 42], [2, 80]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child1 = (function () {
      return {
        meta: {
          "revision": "Ember@2.9.1",
          "loc": {
            "source": null,
            "start": {
              "line": 15,
              "column": 8
            },
            "end": {
              "line": 17,
              "column": 8
            }
          },
          "moduleName": "ui/k8s-tab/namespace/rcs/rc/template.hbs"
        },
        isEmpty: false,
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("span");
          dom.setAttribute(el1, "class", "badge badge-default");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("=");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element4 = dom.childAt(fragment, [1]);
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(element4, 0, 0);
          morphs[1] = dom.createMorphAt(element4, 2, 2);
          return morphs;
        },
        statements: [["content", "sel.label", ["loc", [null, [16, 44], [16, 57]]], 0, 0, 0, 0], ["content", "sel.value", ["loc", [null, [16, 58], [16, 71]]], 0, 0, 0, 0]],
        locals: ["sel"],
        templates: []
      };
    })();
    var child2 = (function () {
      return {
        meta: {
          "revision": "Ember@2.9.1",
          "loc": {
            "source": null,
            "start": {
              "line": 17,
              "column": 8
            },
            "end": {
              "line": 19,
              "column": 8
            }
          },
          "moduleName": "ui/k8s-tab/namespace/rcs/rc/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "t", ["generic.none"], [], ["loc", [null, [18, 10], [18, 30]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child3 = (function () {
      return {
        meta: {
          "revision": "Ember@2.9.1",
          "loc": {
            "source": null,
            "start": {
              "line": 25,
              "column": 0
            },
            "end": {
              "line": 40,
              "column": 0
            }
          },
          "moduleName": "ui/k8s-tab/namespace/rcs/rc/template.hbs"
        },
        isEmpty: false,
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("ul");
          dom.setAttribute(el1, "class", "nav nav-tabs nav-tabs-well shadowed");
          dom.setAttribute(el1, "role", "tablist");
          dom.setAttribute(el1, "style", "display: inline-block;");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("li");
          dom.setAttribute(el2, "role", "tab");
          dom.setAttribute(el2, "class", "tab");
          dom.setAttribute(el2, "data-section", "pods");
          var el3 = dom.createElement("a");
          dom.setAttribute(el3, "href", "#");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("li");
          dom.setAttribute(el2, "role", "tab");
          dom.setAttribute(el2, "class", "tab");
          dom.setAttribute(el2, "data-section", "labels");
          var el3 = dom.createElement("a");
          dom.setAttribute(el3, "href", "#");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "horizontal-form well");
          dom.setAttribute(el1, "role", "tabpanel");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "section container-fluid");
          dom.setAttribute(el2, "data-section", "pods");
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "section container-fluid");
          dom.setAttribute(el2, "data-section", "labels");
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1]);
          var element1 = dom.childAt(element0, [1]);
          var element2 = dom.childAt(element0, [3]);
          var element3 = dom.childAt(fragment, [3]);
          var morphs = new Array(6);
          morphs[0] = dom.createElementMorph(element1);
          morphs[1] = dom.createMorphAt(dom.childAt(element1, [0]), 0, 0);
          morphs[2] = dom.createElementMorph(element2);
          morphs[3] = dom.createMorphAt(dom.childAt(element2, [0]), 0, 0);
          morphs[4] = dom.createMorphAt(dom.childAt(element3, [1]), 1, 1);
          morphs[5] = dom.createMorphAt(dom.childAt(element3, [3]), 1, 1);
          return morphs;
        },
        statements: [["element", "action", ["selectTab", "pods"], ["target", ["get", "component", ["loc", [null, [27, 86], [27, 95]]], 0, 0, 0, 0]], ["loc", [null, [27, 51], [27, 97]]], 0, 0], ["inline", "t", ["k8sTab.navTabs.pods"], [], ["loc", [null, [27, 110], [27, 137]]], 0, 0], ["element", "action", ["selectTab", "labels"], ["target", ["get", "component", ["loc", [null, [28, 90], [28, 99]]], 0, 0, 0, 0]], ["loc", [null, [28, 53], [28, 101]]], 0, 0], ["inline", "t", ["k8sTab.navTabs.labels"], [], ["loc", [null, [28, 114], [28, 143]]], 0, 0], ["inline", "k8s/pod-section", [], ["model", ["subexpr", "@mut", [["get", "model.selectedPods", ["loc", [null, [33, 30], [33, 48]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [33, 6], [33, 50]]], 0, 0], ["inline", "labels-section", [], ["model", ["subexpr", "@mut", [["get", "model", ["loc", [null, [36, 29], [36, 34]]], 0, 0, 0, 0]], [], [], 0, 0], "labelSource", ["subexpr", "@mut", [["get", "model.metadata.labels", ["loc", [null, [36, 47], [36, 68]]], 0, 0, 0, 0]], [], [], 0, 0], "showKind", false, "sortBy", "key"], ["loc", [null, [36, 6], [36, 98]]], 0, 0]],
        locals: ["component"],
        templates: []
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.9.1",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 41,
            "column": 0
          }
        },
        "moduleName": "ui/k8s-tab/namespace/rcs/rc/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("section");
        dom.setAttribute(el1, "class", "header clearfix");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h1");
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode(" ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "pull-right");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("section");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "container-multi-stat");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "container-flex bordered");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4, "class", "col-flex");
        var el5 = dom.createElement("label");
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode(" ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4, "class", "col-flex");
        var el5 = dom.createElement("label");
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode(" ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4, "class", "col-flex");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("label");
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element5 = dom.childAt(fragment, [0]);
        var element6 = dom.childAt(element5, [1]);
        var element7 = dom.childAt(fragment, [2, 1, 1]);
        var element8 = dom.childAt(element7, [1]);
        var element9 = dom.childAt(element7, [3]);
        var element10 = dom.childAt(element7, [5]);
        var morphs = new Array(10);
        morphs[0] = dom.createMorphAt(element6, 0, 0);
        morphs[1] = dom.createMorphAt(element6, 2, 2);
        morphs[2] = dom.createMorphAt(dom.childAt(element5, [3]), 1, 1);
        morphs[3] = dom.createMorphAt(dom.childAt(element8, [0]), 0, 0);
        morphs[4] = dom.createMorphAt(element8, 2, 2);
        morphs[5] = dom.createMorphAt(dom.childAt(element9, [0]), 0, 0);
        morphs[6] = dom.createMorphAt(element9, 2, 2);
        morphs[7] = dom.createMorphAt(dom.childAt(element10, [1]), 0, 0);
        morphs[8] = dom.createMorphAt(element10, 3, 3);
        morphs[9] = dom.createMorphAt(fragment, 4, 4, contextualElement);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["block", "link-to", ["k8s-tab.namespace.rcs"], [], 0, null, ["loc", [null, [2, 6], [2, 92]]]], ["content", "model.displayName", ["loc", [null, [2, 93], [2, 114]]], 0, 0, 0, 0], ["inline", "action-menu", [], ["model", ["subexpr", "@mut", [["get", "model", ["loc", [null, [4, 24], [4, 29]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [4, 4], [4, 31]]], 0, 0], ["inline", "t", ["k8sTab.multistat.header.created"], [], ["loc", [null, [11, 35], [11, 74]]], 0, 0], ["inline", "date-from-now", [["get", "model.metadata.creationTimestamp", ["loc", [null, [11, 99], [11, 131]]], 0, 0, 0, 0]], [], ["loc", [null, [11, 83], [11, 133]]], 0, 0], ["inline", "t", ["k8sTab.multistat.header.replicas"], [], ["loc", [null, [12, 35], [12, 75]]], 0, 0], ["content", "model.displayReplicas", ["loc", [null, [12, 84], [12, 109]]], 0, 0, 0, 0], ["inline", "t", ["k8sTab.multistat.header.selectors"], [], ["loc", [null, [14, 15], [14, 56]]], 0, 0], ["block", "each", [["get", "model.selectorsAsArray", ["loc", [null, [15, 16], [15, 38]]], 0, 0, 0, 0]], [], 1, 2, ["loc", [null, [15, 8], [19, 17]]]], ["block", "select-tab", [], ["initialTab", "pods"], 3, null, ["loc", [null, [25, 0], [40, 15]]]]],
      locals: [],
      templates: [child0, child1, child2, child3]
    };
  })());
});