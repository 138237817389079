define('ui/components/view-edit-project/component', ['exports', 'ember', 'ui/mixins/sortable', 'ui/utils/constants', 'ui/mixins/new-or-edit', 'ui/utils/sort'], function (exports, _ember, _uiMixinsSortable, _uiUtilsConstants, _uiMixinsNewOrEdit, _uiUtilsSort) {
  exports['default'] = _ember['default'].Component.extend(_uiMixinsNewOrEdit['default'], _uiMixinsSortable['default'], {
    projects: _ember['default'].inject.service(),
    access: _ember['default'].inject.service(),
    growl: _ember['default'].inject.service(),
    accessEnabled: _ember['default'].computed.alias('access.enabled'),
    queryParams: ['editing'],

    project: null,
    originalProject: null,
    allProjects: null,
    editing: false,
    tab: 'access',

    primaryResource: _ember['default'].computed.alias('project'),
    sortableContent: _ember['default'].computed.alias('project.projectMembers'),
    sortBy: 'name',
    sorts: {
      name: ['name', 'externalId'],
      type: ['externalIdType', 'externalId'],
      role: ['role', 'externalId']
    },

    stacks: null,

    actions: {
      selectTemplate: function selectTemplate(id) {
        this.set('project.projectTemplateId', id);
      },

      changeProject: function changeProject(project) {
        this.get('router').transitionTo('settings.projects.detail', project.get('id'));
      },

      cancel: function cancel() {
        this.sendAction('cancel');
      },

      checkMember: function checkMember(member) {
        var existing = this.get('project.projectMembers').filterBy('externalIdType', member.get('externalIdType')).filterBy('externalId', member.get('externalId'));

        if (existing.get('length')) {
          this.send('error', 'Member is already in the list');
          return;
        }

        member.set('role', this.get('hasOwner') ? 'member' : 'owner');

        this.send('error', null);
        this.get('project.projectMembers').pushObject(member);
      },

      removeMember: function removeMember(item) {
        this.get('project.projectMembers').removeObject(item);
      }
    },

    didInsertElement: function didInsertElement() {
      if (this.get('showEdit')) {
        var elem = this.$('INPUT[type="text"]')[0];
        if (elem) {
          elem.focus();
        }
      }
    },

    projectBase: (function () {
      return this.get('app.projectEndpoint').replace(this.get('app.projectToken'), this.get('project.id'));
    }).property('project.id'),

    roleOptions: (function () {
      return (this.get('userStore').getById('schema', 'projectmember').get('resourceFields.role.options') || []).map(function (role) {
        return {
          label: 'model.projectMember.role.' + role,
          value: role
        };
      });
    }).property(),

    templateChoices: (function () {
      var active = this.get('project.projectTemplateId');

      var choices = this.get('projectTemplates').map(function (tpl) {
        return { id: tpl.id, name: tpl.name, image: tpl.get('orchestrationIcon') };
      });

      if (!choices.length) {
        choices.push({ id: null, name: 'None', image: this.get('app.baseAssets') + 'assets/images/logos/provider-orchestration.svg' });
      }

      choices.forEach(function (driver) {
        driver.active = active === driver.name;
      });

      return (0, _uiUtilsSort.sortInsensitiveBy)(choices, 'name');
    }).property('project.projectTemplateId', 'projectTemplates.@each.name'),

    selectedProjectTemplate: (function () {
      return this.get('projectTemplates').findBy('id', this.get('project.projectTemplateId'));
    }).property('project.projectTemplateId'),

    hasOwner: (function () {
      return this.get('project.projectMembers').filterBy('role', _uiUtilsConstants['default'].PROJECT.ROLE_OWNER).get('length') > 0;
    }).property('project.projectMembers.@each.role'),

    validate: function validate() {
      this._super();
      var errors = this.get('errors') || [];

      if (!this.get('hasOwner') && this.get('access.enabled')) {
        errors.push('You must have at least one owner');
      }

      if (errors.length) {
        this.set('errors', errors);
        return false;
      }

      return true;
    },

    willSave: function willSave() {
      var out = this._super();
      if (out && !this.get('project.id')) {
        // For create the members go in the request
        this.set('project.members', this.get('project.projectMembers'));
      }

      return out;
    },

    didSave: function didSave() {
      if (this.get('editing') && this.get('access.enabled')) {
        var members = this.get('project.projectMembers').map(function (member) {
          return {
            type: 'projectMember',
            externalId: member.externalId,
            externalIdType: member.externalIdType,
            role: member.role
          };
        });

        return this.get('project').doAction('setmembers', { members: members });
      }
    },

    doneSaving: function doneSaving() {
      var out = this._super();
      this.get('projects').refreshAll();
      this.sendAction('done');
      return out;
    }
  });
});