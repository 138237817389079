define('ui/k8s-tab/namespace/services/index/controller', ['exports', 'ember', 'ui/mixins/sortable', 'ui/mixins/filter-k8s-namespace'], function (exports, _ember, _uiMixinsSortable, _uiMixinsFilterK8sNamespace) {
  exports['default'] = _ember['default'].Controller.extend(_uiMixinsSortable['default'], _uiMixinsFilterK8sNamespace['default'], {
    filterableContent: _ember['default'].computed.alias('model.allServices'),
    sortableContent: _ember['default'].computed.alias('filtered'),
    sortBy: 'name',
    sorts: {
      state: ['stateSort', 'name', 'id'],
      name: ['name', 'id'],
      serviceType: ['serviceType', 'name', 'id'],
      selector: ['selector', 'name', 'id']
    }
  });
});