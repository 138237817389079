define("ui/components/machine/driver-custom/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@2.9.1",
            "loc": {
              "source": null,
              "start": {
                "line": 29,
                "column": 60
              },
              "end": {
                "line": 29,
                "column": 124
              }
            },
            "moduleName": "ui/components/machine/driver-custom/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["inline", "t", ["machine.driverCustom.step5Close.link"], [], ["loc", [null, [29, 80], [29, 124]]], 0, 0]],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.9.1",
          "loc": {
            "source": null,
            "start": {
              "line": 4,
              "column": 6
            },
            "end": {
              "line": 33,
              "column": 6
            }
          },
          "moduleName": "ui/components/machine/driver-custom/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "col-md-8 col-md-offset-2");
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("ul");
          dom.setAttribute(el2, "class", "list-circles list-circles-clear");
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("li");
          var el4 = dom.createTextNode("\n              ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("i");
          var el5 = dom.createTextNode("1");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n              ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n            ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("li");
          var el4 = dom.createTextNode("\n              ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("i");
          var el5 = dom.createTextNode("2");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n              ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n              ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4, "class", "copy-pre");
          var el5 = dom.createTextNode("\n                ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("pre");
          dom.setAttribute(el5, "id", "network-command");
          var el6 = dom.createComment("");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n                ");
          dom.appendChild(el4, el5);
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n              ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n            ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("li");
          var el4 = dom.createTextNode("\n              ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("i");
          var el5 = dom.createTextNode("3");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n              ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n              ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4, "class", "copy-pre");
          var el5 = dom.createTextNode("\n                ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("pre");
          dom.setAttribute(el5, "id", "registration-command");
          var el6 = dom.createComment("");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n                ");
          dom.appendChild(el4, el5);
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n              ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n            ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("li");
          var el4 = dom.createTextNode("\n              ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("i");
          var el5 = dom.createTextNode("3");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n              ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode(" ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode(" ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n            ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element7 = dom.childAt(fragment, [1, 1]);
          var element8 = dom.childAt(element7, [3]);
          var element9 = dom.childAt(element8, [5]);
          var element10 = dom.childAt(element7, [5]);
          var element11 = dom.childAt(element10, [5]);
          var element12 = dom.childAt(element7, [7]);
          var morphs = new Array(10);
          morphs[0] = dom.createMorphAt(dom.childAt(element7, [1]), 3, 3);
          morphs[1] = dom.createMorphAt(element8, 3, 3);
          morphs[2] = dom.createMorphAt(dom.childAt(element9, [1]), 0, 0);
          morphs[3] = dom.createMorphAt(element9, 3, 3);
          morphs[4] = dom.createMorphAt(element10, 3, 3);
          morphs[5] = dom.createMorphAt(dom.childAt(element11, [1]), 0, 0);
          morphs[6] = dom.createMorphAt(element11, 3, 3);
          morphs[7] = dom.createMorphAt(element12, 3, 3);
          morphs[8] = dom.createMorphAt(element12, 5, 5);
          morphs[9] = dom.createMorphAt(element12, 7, 7);
          return morphs;
        },
        statements: [["inline", "format-html-message", ["machine.driverCustom.windows.step1"], [], ["loc", [null, [9, 14], [9, 74]]], 0, 0], ["inline", "format-html-message", ["machine.driverCustom.windows.step2"], [], ["loc", [null, [13, 14], [13, 74]]], 0, 0], ["inline", "t", ["machine.driverCustom.windows.step2Copy"], [], ["loc", [null, [15, 42], [15, 88]]], 0, 0], ["inline", "copy-to-clipboard", [], ["clipboardText", ["subexpr", "t", ["machine.driverCustom.windows.step2Copy"], [], ["loc", [null, [16, 50], [16, 94]]], 0, 0]], ["loc", [null, [16, 16], [16, 96]]], 0, 0], ["inline", "t", ["machine.driverCustom.windows.step3"], ["appName", ["subexpr", "@mut", [["get", "settings.appName", ["loc", [null, [21, 63], [21, 79]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [21, 14], [21, 81]]], 0, 0], ["content", "registrationCommandWindows", ["loc", [null, [23, 47], [23, 77]]], 0, 0, 0, 0], ["inline", "copy-to-clipboard", [], ["clipboardText", ["subexpr", "@mut", [["get", "registrationCommandWindows", ["loc", [null, [24, 50], [24, 76]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [24, 16], [24, 78]]], 0, 0], ["inline", "t", ["machine.driverCustom.step5Close.part1"], [], ["loc", [null, [29, 14], [29, 59]]], 0, 0], ["block", "link-to", ["hosts"], [], 0, null, ["loc", [null, [29, 60], [29, 136]]]], ["inline", "t", ["machine.driverCustom.step5Close.part2"], [], ["loc", [null, [29, 137], [29, 182]]], 0, 0]],
        locals: [],
        templates: [child0]
      };
    })();
    var child1 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@2.9.1",
            "loc": {
              "source": null,
              "start": {
                "line": 64,
                "column": 14
              },
              "end": {
                "line": 69,
                "column": 14
              }
            },
            "moduleName": "ui/components/machine/driver-custom/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "copy-pre");
            var el2 = dom.createTextNode("\n                  ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("pre");
            dom.setAttribute(el2, "id", "registration-command");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                  ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element0 = dom.childAt(fragment, [1]);
            var morphs = new Array(2);
            morphs[0] = dom.createMorphAt(dom.childAt(element0, [1]), 0, 0);
            morphs[1] = dom.createMorphAt(element0, 3, 3);
            return morphs;
          },
          statements: [["content", "registrationCommand", ["loc", [null, [66, 49], [66, 72]]], 0, 0, 0, 0], ["inline", "copy-to-clipboard", [], ["clipboardText", ["subexpr", "@mut", [["get", "registrationCommand", ["loc", [null, [67, 52], [67, 71]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [67, 18], [67, 73]]], 0, 0]],
          locals: [],
          templates: []
        };
      })();
      var child1 = (function () {
        return {
          meta: {
            "revision": "Ember@2.9.1",
            "loc": {
              "source": null,
              "start": {
                "line": 69,
                "column": 14
              },
              "end": {
                "line": 71,
                "column": 14
              }
            },
            "moduleName": "ui/components/machine/driver-custom/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            var el2 = dom.createElement("i");
            dom.setAttribute(el2, "class", "icon icon-spinner icon-spin");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode(" ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 2, 2);
            return morphs;
          },
          statements: [["inline", "t", ["machine.driverCustom.generating"], [], ["loc", [null, [70, 65], [70, 104]]], 0, 0]],
          locals: [],
          templates: []
        };
      })();
      var child2 = (function () {
        return {
          meta: {
            "revision": "Ember@2.9.1",
            "loc": {
              "source": null,
              "start": {
                "line": 75,
                "column": 60
              },
              "end": {
                "line": 75,
                "column": 124
              }
            },
            "moduleName": "ui/components/machine/driver-custom/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["inline", "t", ["machine.driverCustom.step5Close.link"], [], ["loc", [null, [75, 80], [75, 124]]], 0, 0]],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.9.1",
          "loc": {
            "source": null,
            "start": {
              "line": 33,
              "column": 6
            },
            "end": {
              "line": 79,
              "column": 6
            }
          },
          "moduleName": "ui/components/machine/driver-custom/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "col-md-8 col-md-offset-2");
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("ul");
          dom.setAttribute(el2, "class", "list-circles list-circles-clear");
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("li");
          var el4 = dom.createTextNode("\n              ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("i");
          var el5 = dom.createTextNode("1");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n              ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n            ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("li");
          var el4 = dom.createTextNode("\n              ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("i");
          var el5 = dom.createTextNode("2");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n              ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n              ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("ul");
          var el5 = dom.createTextNode("\n                ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("li");
          var el6 = dom.createComment("");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n              ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n            ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("li");
          var el4 = dom.createTextNode("\n              ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("i");
          var el5 = dom.createTextNode("3");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n              ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n              ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4, "class", "r-mt10");
          var el5 = dom.createTextNode("\n                ");
          dom.appendChild(el4, el5);
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n              ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n            ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("li");
          var el4 = dom.createTextNode("\n              ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("i");
          var el5 = dom.createTextNode("4");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n              ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n              ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4, "class", "checkbox r-mt10");
          var el5 = dom.createTextNode("\n                ");
          dom.appendChild(el4, el5);
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n              ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n            ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("li");
          var el4 = dom.createTextNode("\n              ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("i");
          var el5 = dom.createTextNode("5");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n              ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode(" ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode(":\n");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("            ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("li");
          var el4 = dom.createTextNode("\n              ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("i");
          var el5 = dom.createTextNode("6");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n              ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode(" ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode(" ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n            ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element1 = dom.childAt(fragment, [1, 1]);
          var element2 = dom.childAt(element1, [3]);
          var element3 = dom.childAt(element1, [5]);
          var element4 = dom.childAt(element1, [7]);
          var element5 = dom.childAt(element1, [9]);
          var element6 = dom.childAt(element1, [11]);
          var morphs = new Array(13);
          morphs[0] = dom.createMorphAt(dom.childAt(element1, [1]), 3, 3);
          morphs[1] = dom.createMorphAt(element2, 3, 3);
          morphs[2] = dom.createMorphAt(dom.childAt(element2, [5, 1]), 0, 0);
          morphs[3] = dom.createMorphAt(element3, 3, 3);
          morphs[4] = dom.createMorphAt(dom.childAt(element3, [5]), 1, 1);
          morphs[5] = dom.createMorphAt(element4, 3, 3);
          morphs[6] = dom.createMorphAt(dom.childAt(element4, [5]), 1, 1);
          morphs[7] = dom.createMorphAt(element5, 3, 3);
          morphs[8] = dom.createMorphAt(element5, 5, 5);
          morphs[9] = dom.createMorphAt(element5, 7, 7);
          morphs[10] = dom.createMorphAt(element6, 3, 3);
          morphs[11] = dom.createMorphAt(element6, 5, 5);
          morphs[12] = dom.createMorphAt(element6, 7, 7);
          return morphs;
        },
        statements: [["inline", "format-html-message", ["machine.driverCustom.step1"], [], ["loc", [null, [38, 14], [38, 66]]], 0, 0], ["inline", "t", ["machine.driverCustom.step2"], [], ["loc", [null, [42, 14], [42, 48]]], 0, 0], ["inline", "format-html-message", ["machine.driverCustom.step2li"], [], ["loc", [null, [44, 20], [44, 74]]], 0, 0], ["inline", "t", ["machine.driverCustom.step3"], [], ["loc", [null, [49, 14], [49, 48]]], 0, 0], ["inline", "form-user-labels", [], ["setLabels", ["subexpr", "action", ["setLabels"], [], ["loc", [null, [51, 45], [51, 65]]], 0, 0]], ["loc", [null, [51, 16], [51, 67]]], 0, 0], ["inline", "format-html-message", ["machine.driverCustom.step4"], ["rancherImage", ["subexpr", "@mut", [["get", "settings.rancherImage", ["loc", [null, [56, 78], [56, 99]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [56, 14], [56, 101]]], 0, 0], ["inline", "input", [], ["type", "text", "value", ["subexpr", "@mut", [["get", "cattleAgentIp", ["loc", [null, [58, 42], [58, 55]]], 0, 0, 0, 0]], [], [], 0, 0], "classNames", "form-control", "placeholder", "e.g. 172.16.0.2"], ["loc", [null, [58, 16], [58, 113]]], 0, 0], ["inline", "t", ["machine.driverCustom.step5Copy"], [], ["loc", [null, [63, 14], [63, 52]]], 0, 0], ["content", "settings.appName", ["loc", [null, [63, 53], [63, 73]]], 0, 0, 0, 0], ["block", "if", [["get", "registrationCommand", ["loc", [null, [64, 20], [64, 39]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [64, 14], [71, 21]]]], ["inline", "t", ["machine.driverCustom.step5Close.part1"], [], ["loc", [null, [75, 14], [75, 59]]], 0, 0], ["block", "link-to", ["hosts"], [], 2, null, ["loc", [null, [75, 60], [75, 136]]]], ["inline", "t", ["machine.driverCustom.step5Close.part2"], [], ["loc", [null, [75, 137], [75, 182]]], 0, 0]],
        locals: [],
        templates: [child0, child1, child2]
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.9.1",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 87,
            "column": 0
          }
        },
        "moduleName": "ui/components/machine/driver-custom/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("section");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "container-fluid");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "row form-group");
        var el4 = dom.createTextNode("\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "footer-actions");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("button");
        dom.setAttribute(el3, "class", "btn btn-primary");
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element13 = dom.childAt(fragment, [0]);
        var element14 = dom.childAt(element13, [3, 1]);
        var morphs = new Array(3);
        morphs[0] = dom.createMorphAt(dom.childAt(element13, [1, 1]), 1, 1);
        morphs[1] = dom.createElementMorph(element14);
        morphs[2] = dom.createMorphAt(element14, 0, 0);
        return morphs;
      },
      statements: [["block", "if", [["get", "projects.current.isWindows", ["loc", [null, [4, 12], [4, 38]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [4, 6], [79, 13]]]], ["element", "action", ["cancel"], [], ["loc", [null, [84, 12], [84, 31]]], 0, 0], ["inline", "t", ["machine.driverCustom.close"], [], ["loc", [null, [84, 56], [84, 90]]], 0, 0]],
      locals: [],
      templates: [child0, child1]
    };
  })());
});