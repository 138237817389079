define('ui/k8s-tab/apply/controller', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    queryParams: ['name', 'kind', 'clone'],
    clone: false,
    name: null,
    kind: null,

    actions: {
      done: function done() {
        this.send('goToPrevious');
      },

      cancel: function cancel() {
        this.send('goToPrevious');
      }
    }
  });
});