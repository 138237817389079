define('ui/bulk-action-handler/service', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Service.extend({
    modalService: _ember['default'].inject.service('modal'),
    promptStop: function promptStop(nodes) {
      this.get('modalService').toggleModal('modal-container-stop', {
        model: nodes
      });
    },
    start: function start(nodes) {
      nodes.forEach(function (node) {
        node.send('start');
      });
    },
    restart: function restart(nodes) {
      nodes.forEach(function (node) {
        node.send('restart');
      });
    },
    promptDelete: function promptDelete(nodes) {
      this.get('modalService').toggleModal('confirm-delete', nodes);
    }
  });
});