define('ui/components/form-scheduling/component', ['exports', 'ember', 'ui/mixins/manage-labels'], function (exports, _ember, _uiMixinsManageLabels) {
  exports['default'] = _ember['default'].Component.extend(_uiMixinsManageLabels['default'], {
    // Inputs
    // Global scale scheduling
    isGlobal: false,

    // Is this for a service(=true) or container(=false)
    isService: false,

    // Request a specific host
    requestedHostId: null,

    // Is requesting a specific host allowed
    canRequestHost: true,

    // All the hosts, for generating dropdowns
    allHosts: null,

    // Initial labels and host to start with
    initialLabels: null,
    initialHostId: null,

    // labelArray -> the labels that should be set for the scheduling rules

    // Actions output
    // setLabels(labelArray)
    // setGlobal(boolean)
    // setRequestedHost(hostId)

    // Internal properties
    isRequestedHost: false,

    classNameBindings: ['editing:component-editing:component-static'],
    editing: true,

    actions: {
      addSchedulingRule: function addSchedulingRule() {
        this.send('addAffinityLabel');
      },

      removeSchedulingRule: function removeSchedulingRule(obj) {
        this.send('removeLabel', obj);
      }
    },

    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      this.initLabels(this.get('initialLabels'), 'affinity');

      if (this.get('isGlobal')) {
        this.setProperties({
          isRequestedHost: false,
          requestedHostId: null
        });
        _ember['default'].run.scheduleOnce('afterRender', function () {
          _this.sendAction('setGlobal', true);
          _this.sendAction('setRequestedHost', null);
        });
      } else if (this.get('initialHostId')) {
        this.setProperties({
          isRequestedHost: true,
          requestedHostId: this.get('initialHostId')
        });

        _ember['default'].run.scheduleOnce('afterRender', function () {
          _this.sendAction('setGlobal', false);
          _this.sendAction('setRequestedHost', _this.get('requestedHostId'));
        });
      }
    },

    updateLabels: function updateLabels(labels) {
      this.sendAction('setLabels', labels);
    },

    globalDidChange: (function () {
      if (this.get('isGlobal')) {
        this.set('isRequestedHost', false);
      }
    }).observes('isGlobal'),

    isRequestedHostDidChange: (function () {
      if (this.get('isRequestedHost')) {
        var hostId = this.get('requestedHostId') || this.get('hostChoices.firstObject.id');
        this.set('requestedHostId', hostId);
      } else {
        this.set('requestedHostId', null);
      }
    }).observes('isRequestedHost'),

    requestedHostIdDidChange: (function () {
      var hostId = this.get('requestedHostId');

      if (hostId) {
        this.set('isRequestedHost', true);
        this.sendAction('setGlobal', false);
      }

      this.sendAction('setRequestedHost', hostId);
    }).observes('requestedHostId'),

    selectedChoice: _ember['default'].computed('allHosts.@each.{id,name,state}', function () {
      return this.get('hostChoices').findBy('id', this.get('initialHostId'));
    }),

    hostChoices: (function () {
      var list = this.get('allHosts').map(function (host) {
        var hostLabel = host.get('displayName');
        if (host.get('state') !== 'active') {
          hostLabel += ' (' + host.get('state') + ')';
        }

        return {
          id: host.get('id'),
          name: hostLabel
        };
      });

      return list.sortBy('name', 'id');
    }).property('allHosts.@each.{id,name,state}')

  });
});